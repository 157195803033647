import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Accordion, Form, Button, Offcanvas, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AddAddress from './AddAddress';
import AddCustomerAddress from './AddCustomerAddress';
import AddProducts from './AddProducts';
import CalculateCargo from '../../Components/Calculate/CalculateCargo';
import DistanceContract from './DistanceContract';
import InvoiceForm from './InvoiceForm';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { FaInfoCircle } from 'react-icons/fa'; 


const ManuelShipments = () => {
  const userId = useSelector((state) => state.user?.userId) || '';
  const storeId = useSelector((state) => state.user?.storeId) || '';

  const [addresses, setAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [products, setProducts] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showCustomerOffcanvas, setShowCustomerOffcanvas] = useState(false);
  const [showProductOffcanvas, setShowProductOffcanvas] = useState(false);
  const [showDistanceContract, setShowDistanceContract] = useState(false);
  const [showInvoiceForm, setShowInvoiceForm] = useState(false);
  const [showCalculateCargo, setShowCalculateCargo] = useState(false);
  const [activeAccordionKey, setActiveAccordionKey] = useState(null);
  const [activeCustomerAccordionKey, setActiveCustomerAccordionKey] = useState(null);
  const [activeProductAccordionKey, setActiveProductAccordionKey] = useState(null);
  const [editingAddress, setEditingAddress] = useState(null);
  const [editingCustomerAddress, setEditingCustomerAddress] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const [selectedCustomerAddresses, setSelectedCustomerAddresses] = useState({});
  const [selectedProducts, setSelectedProducts] = useState({});
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [updateCustomerTrigger, setUpdateCustomerTrigger] = useState(false);
  const [payeeAddress, setPayeeAddress] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  const [formData, setFormData] = useState({
    sendEmail: false,
    sendSMS: false,
    insurance: false,
  });
  const [cargoCompany, setCargoCompany] = useState('');
  const [setDesiPrice] = useState(0);
  const [setTotalDesi] = useState(0);
  const [payAtDoorPrice, setPayAtDoorPrice] = useState('');
  const [pttPostalCheck] = useState('');

  const fetchAddresses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/address`, {
        withCredentials: true,
      });
      const userAddresses = Array.isArray(response.data) ? response.data : [];
      setAddresses(userAddresses);
    } catch (error) {
      console.error('Adresler yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchAddresses();
    }
  }, [userId, updateTrigger]);

  const handleAddAddress = async (newAddress) => {
    console.log("Yeni adres verisi:", newAddress);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/address`,
        { ...newAddress, userId, storeId },
        { withCredentials: true }
      );

      if (response.status === 201) {
        setAddresses((prevAddresses) => [...prevAddresses, response.data]);
        alert('Adres başarıyla eklendi!');
      }
    } catch (error) {
      console.error('Adres kaydedilemedi:', error);
      alert('Adres eklenirken bir hata oluştu, lütfen tekrar deneyin.');
    } finally {
      setShowOffcanvas(false);
    }
  };

  const handleDeleteAddress = async (id) => {
    if (window.confirm('Bu adresi silmek istediğinizden emin misiniz?')) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/address/${id}`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setAddresses((prevAddresses) => prevAddresses.filter((address) => address._id !== id));
          alert('Adres başarıyla silindi.');
        }
      } catch (error) {
        console.error('Adres silinirken hata oluştu:', error);
        alert('Adres silinirken bir hata oluştu, lütfen tekrar deneyin.');
      }
    }
  };

  const handleUpdateAddress = async (updatedAddress) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/address/${updatedAddress._id}`,
        { ...updatedAddress, userId, storeId },
        { withCredentials: true }
      );

      if (response.status === 200) {
        setAddresses((prevAddresses) =>
          prevAddresses.map((address) =>
            address._id === updatedAddress._id ? response.data : address
          )
        );
        alert('Adres başarıyla güncellendi!');
      }
    } catch (error) {
      console.error('Adres güncellenirken hata oluştu:', error);
    }
    setShowOffcanvas(false);
    setEditingAddress(null);
  };

  // Alıcı adresi alma
  const fetchCustomerAddresses = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/customerAddress`, {
        withCredentials: true,
      });
      setCustomerAddresses(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Alıcı adresleri yüklenirken hata oluştu:', error);
    }
  };

  useEffect(() => {
    fetchCustomerAddresses();
  }, [userId, updateCustomerTrigger]);

  // Alıcı adresi silme
  const handleDeleteCustomerAddress = async (id) => {
    if (window.confirm('Bu alıcı adresini silmek istediğinizden emin misiniz?')) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/customerAddress/${id}`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setCustomerAddresses((prevAddresses) => prevAddresses.filter((address) => address._id !== id));
          alert('Alıcı adresi başarıyla silindi.');
        }
      } catch (error) {
        console.error('Alıcı adresi silinirken hata oluştu:', error);
        alert('Alıcı adresi silinirken bir hata oluştu, lütfen tekrar deneyin.');
      }
    }
  };

 

  // Ürünleri alma
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/products`, {
          withCredentials: true,
        });
        setProducts(response.data); // Ürünleri state'e kaydet
      } catch (error) {
        console.error('Ürünler yüklenirken hata oluştu:', error);
        alert('Ürünler yüklenirken hata oluştu, lütfen tekrar deneyin.');
      }
    };

    fetchProducts(); // Bileşen yüklendiğinde ürünleri çek
  }, []); // Boş bağımlılık dizisi ile yalnızca bileşen yüklendiğinde çalışacak
const handleAddProduct = (newProduct) => {
  setProducts((prevProducts) => {
    const productIndex = prevProducts.findIndex((product) => product._id === newProduct._id);
    if (productIndex !== -1) {
      // Ürün mevcut, güncelle
      const updatedProducts = [...prevProducts];
      updatedProducts[productIndex] = newProduct;
      return updatedProducts;
    } else {
      // Ürün yeni, ekle
      return [...prevProducts, newProduct];
    }
  });
  setShowProductOffcanvas(false); // Offcanvas'ı kapat
  setEditingProduct(null); // Düzenleme modunu sıfırla
};



  const handleDeleteProduct = async (id) => {
    if (window.confirm('Bu ürünü silmek istediğinizden emin misiniz?')) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/products/${id}`, {
          withCredentials: true,
        });

        if (response.status === 200) {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product._id !== id) // ID'yi kontrol et
          );
          alert('Ürün başarıyla silindi.'); // Silme işlemi sonrası kullanıcıyı bilgilendir
        }
      } catch (error) {
        console.error('Ürün silinirken hata oluştu:', error);
        alert('Ürün silinirken bir hata oluştu, lütfen tekrar deneyin.');
      }
    }
  };

  // handleEditAddress fonksiyonu
  const handleEditAddress = (address) => {
    setEditingAddress(address); // Düzenlenecek adresi ayarlıyoruz
    setShowOffcanvas(true); // Offcanvas'ı açıyoruz
  };

  // Alıcı adres düzenleme işlemi
  const handleEditCustomerAddress = (address) => {
    setEditingCustomerAddress(address); // Düzenlenecek adresi duruma atayın
    setShowCustomerOffcanvas(true); // Offcanvas'ı aç
  };

  // Ürün düzenleme işlemi
const handleEditProduct = (product) => {
    setEditingProduct(product); // Düzenlenecek ürünü ayarlıyoruz
    setShowProductOffcanvas(true); // Offcanvas'ı açıyoruz
};


  // Desi ve fiyat hesaplama işlemi
  const handleCalculateCargo = (calculatedDesi, calculatedPrice) => {
    setTotalDesi(calculatedDesi);
    setDesiPrice(calculatedPrice);
  };

  // Kargo şirketi seçim işlemi
  // const handleCargoCompanyChange = (company) => {
  //   setCargoCompany(company);
  // };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    // Kullanıcının girdiği değeri ayarlıyoruz
    setPayAtDoorPrice(value);
  };
 const handleBlur = () => {
    // Girdi alanı kaybedildiğinde değeri formatlıyoruz
    if (payAtDoorPrice) {
      const formattedValue = parseFloat(payAtDoorPrice).toFixed(2);
      setPayAtDoorPrice(formattedValue);
    }
  };


  //invoice<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
 const handleShowInvoiceForm = (address) => {
    setPayeeAddress(address); // Gönderici adresini ayarlayın
    setShowInvoiceForm(true); // E-fatura formunu göster
  };

  // const handleCloseInvoiceForm = () => {
  //   setShowInvoiceForm(false); // E-fatura formunu kapat
  //   setPayeeAddress(null); // Gönderici adresini sıfırlayın
  // };

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
  // Hesapla Offcanvas'ı açma
  const handleCalculateCargoShow = () => {
    if (cargoCompany) {
      setShowCalculateCargo(true);
    } else {
      alert('Lütfen bir kargo firması seçin.');
    }
  };

  // Hesapla Offcanvas'ı kapatma
  const handleCalculateCargoClose = () => {
    setShowCalculateCargo(false);
  };

  const handleShowOffcanvas = () => {
    setEditingAddress(null); // Yeni adres eklemek için editingAddress’i sıfırlıyoruz
    setShowOffcanvas(true); // Offcanvas'ı açıyoruz
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    setEditingAddress(null);
  };

  const handleShowCustomerOffcanvas = () => {
    setEditingCustomerAddress(null); // Ekleme moduna geçmeden önce düzenleme modunu sıfırla
    setShowCustomerOffcanvas(true); // Offcanvas'ı aç
  };

  const handleCloseCustomerOffcanvas = () => setShowCustomerOffcanvas(false);

  const handleShowProductOffcanvas = () => setShowProductOffcanvas(true);
  const handleCloseProductOffcanvas = () => {
    setShowProductOffcanvas(false);
    setEditingProduct(null); // Ürün düzenlemesini sıfırla
  };

  const handleShowDistanceContract = () => setShowDistanceContract(true);
  const handleCloseDistanceContract = () => setShowDistanceContract(false);
  const handleCloseInvoiceForm = () => setShowInvoiceForm(false);

  // Form değerlerini güncelleme
  const handleFormChange = (event) => {
    const { name, checked } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

useEffect(() => {
  const selectedProductKeys = Object.keys(selectedProducts).filter((key) => selectedProducts[key]);
  const selectedProductsData = products.filter((product) => selectedProductKeys.includes(product._id));

  // Seçilen ürünleri loglayalım
  console.log("Seçilen ürünler:", selectedProductsData);

  // Fiyatları sayıya çevirerek toplamını hesaplayalım
  const total = selectedProductsData.reduce((acc, product) => {
    const price = parseFloat(product.price.replace(" TL", "")); // " TL" kısmını kaldırıp parseFloat ile sayıya çevirelim
    return acc + (price || 0);
  }, 0);
  
  console.log("Toplam tutar:", total); // Toplam tutarı loglayalım

  setTotalAmount(total);
}, [selectedProducts, products]);



const handleSubmit = async (e) => {
  e.preventDefault();

  let shipmentKind = ''; // shipmentKind değeri başlangıçta boş
  let shipmentType = 'STANDART'; // Varsayılan olarak 'STANDART' atandı
  let paymentMethodValue = '';

  // Ödeme şekline göre shipmentKind belirleme
  if (paymentMethod === 'kapida') {
    shipmentKind = 'PAY_AT_DOOR'; // Kapıda ödeme
    shipmentType = 'COLLECTION';  // Kapıda ödeme için shipmentType
  } else if (paymentMethod === 'recipient') {
    shipmentKind = 'BUYER_PAID'; // Alıcı öder
  } else if (paymentMethod === 'sender') {
    shipmentKind = 'SELLER_PAID'; // Gönderici öder
  } else if (paymentMethod === 'online') {
    shipmentKind = 'COLLECTION'; // Online ödeme
    shipmentType = 'COLLECTION';  // Online ödeme için shipmentType
  }

  // Kargo firması bazında if koşulları ile ödeme şekillerini kontrol edelim
  if (cargoCompany === 'ptt') {
    // PTT için ödeme şekilleri
    if (paymentMethod === 'kapida') {
      paymentMethodValue = 'PAY_AT_DOOR'; // Kapıda ödeme
    } else if (paymentMethod === 'sender') {
      paymentMethodValue = 'SELLER_PAID'; // Gönderici öder
    } else if (paymentMethod === 'recipient') {
      paymentMethodValue = 'BUYER_PAID'; // Alıcı öder
    }
  } else if (cargoCompany === 'ups') {
    // UPS için ödeme şekilleri
    if (paymentMethod === 'kapida') {
      paymentMethodValue = 'PAY_AT_DOOR'; // Kapıda ödeme
    } else if (paymentMethod === 'sender') {
      paymentMethodValue = 'SELLER_PAID'; // Gönderici öder
    } else if (paymentMethod === 'recipient') {
      paymentMethodValue = 'BUYER_PAID'; // Alıcı öder
    }
  } else if (cargoCompany === 'yurtici') {
    // Yurtiçi Kargo için ödeme şekilleri
    if (paymentMethod === 'kapida') {
      paymentMethodValue = 'PAY_AT_DOOR'; // Kapıda ödeme
    } else if (paymentMethod === 'sender') {
      paymentMethodValue = 'SELLER_PAID'; // Gönderici öder
    } else if (paymentMethod === 'recipient') {
      paymentMethodValue = 'BUYER_PAID'; // Alıcı öder
    }
  }

  // Seçilen gönderici adreslerini al
  const selectedAddressKeys = Object.keys(selectedAddresses).filter((key) => selectedAddresses[key]);
  const payeeAddress = addresses.find((address) => selectedAddressKeys.includes(address._id));

  // Seçilen alıcı adreslerini al
  const selectedCustomerKeys = Object.keys(selectedCustomerAddresses).filter((key) => selectedCustomerAddresses[key]);
  const payerAddress = customerAddresses.find((address) => selectedCustomerKeys.includes(address._id));

  // Seçilen ürünleri al
  const selectedProductKeys = Object.keys(selectedProducts).filter((key) => selectedProducts[key]);
  const selectedProductsData = products.filter((product) => selectedProductKeys.includes(product._id));

  const submissionData = {
    shipment_type: shipmentType, // Burada güncellenen shipmentType değerini kullanıyoruz
    shipment_kind: shipmentKind, // Yukarıda belirlenen shipment_kind değeri kullanılıyor
    products: selectedProductsData.map((product) => ({
      name: product.name,
      quantity: 1,
      total_amount_of_product: product.price,
      is_create_product: true,
    })),
    payee_id: userId,
    store_id: storeId,
    payee_meta: {
      firstname: payeeAddress?.firstname,
      lastname: payeeAddress?.lastname,
      email: payeeAddress?.email,
      phone: payeeAddress?.phone,
      address: payeeAddress?.fullAddress,
      postal_code: payeeAddress?.postalCode,
      corporateTitle: payeeAddress?.corporateTitle,
      taxNumber: payeeAddress?.taxNumber,
      taxOffice: payeeAddress?.taxOffice,
      city_name: payeeAddress?.cityName,
      district_name: payeeAddress?.districtName,
    },
    payer: {
      firstname: payerAddress?.firstName,
      lastname: payerAddress?.lastName,
      email: payerAddress?.email,
      phone: payerAddress?.phone,
      address: payerAddress?.fullAddress,
      city_name: payerAddress?.cityName,
      district_name: payerAddress?.districtName,
      postal_code: payerAddress?.postalCode,
      tax_number: payerAddress?.taxNumber,
      tax_office: payerAddress?.taxOffice,
    },
    eInvoice: formData.eInvoice,
    company: cargoCompany,
    send_info: {
      sendEmail: formData.sendEmail,
      sendSMS: formData.sendSMS,
      sendBoth: formData.sendEmail && formData.sendSMS,
    },
    ptt_postal_check: formData.insurance,
    pay_at_door_price: payAtDoorPrice,
    ptt_postal_check_number: pttPostalCheck,
    payment_method: paymentMethodValue, // Ödeme şekli
  };

  console.log('Gönderim verileri:', JSON.stringify(submissionData, null, 2));

  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/shipments`, submissionData, {
      withCredentials: true,
    });

    console.log('API yanıt durumu:', response.status);
    console.log('API yanıt verisi:', response.data);

    if (response.status === 201) {
      alert('Kargo başarıyla oluşturuldu!');
    }
  } catch (error) {
    console.error('Kargo oluşturulurken hata oluştu:', error.message);
    if (error.response) {
      console.error('Hata yanıt durumu:', error.response.status);
      console.error('Hata yanıt verisi:', error.response.data);
    }
    alert('Kargo oluşturulurken hata oluştu, lütfen tekrar deneyin.');
  }
};



  
  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <Link to="/panelim/bana-gelecek" className="btn btn-warning w-100">
            KARGO BANA GELECEK
          </Link>
        </Col>
      </Row>
    <Card className="mb-3 text-center">


      {/* <<<<<<<<<<<<<<<<<GÖNDERİCİ ADRESLERİ><<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

  <Card.Header>GÖNDERİCİ ADRESLERİ</Card.Header>
  <Card.Body>
    <Accordion activeKey={activeAccordionKey} onSelect={(key) => setActiveAccordionKey(key)}>
      {addresses.length > 0 ? (
        addresses.map((address, index) => (
          <Accordion.Item eventKey={index.toString()} key={address._id}>
            <Accordion.Header className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center" style={{ justifyContent: 'center', width: '100%' }}>
                <span className="me-2">{`${address.firstname} ${address.lastname}`}</span>
                <Form.Check
                  type="checkbox"
                  checked={!!selectedAddresses[address._id]}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    // Tüm adresleri uncheckle ve sadece seçili olanı checkle
                    setSelectedAddresses({ [address._id]: isChecked });
                  }}
                />
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <Row className="text-center">
                <Col md={6}>
                  <p><strong>İsim:</strong> {address.firstname} {address.lastname}</p>
                  <p><strong>Şehir/İlçe:</strong> {address.cityName} / {address.districtName}</p>
                  <p><strong>Telefon:</strong> {address.phone}</p>
                  <p><strong>E-posta:</strong> {address.email}</p>
                </Col>
                <Col md={6}>
                  <p><strong>Adres:</strong> {address.fullAddress}</p>
                  <p><strong>Posta Kodu:</strong> {address.postalCode}</p>
                  <p><strong>Kurumsal Ünvan:</strong> {address.corporateTitle}</p>
                  <p><strong>Vergi No/Daire:</strong> {address.taxNumber} / {address.taxOffice}</p>
                  <p><strong>E-Fatura:</strong> {address.isEInvoice ? 'E-fatura Mükellefiyim' : 'E-fatura Mükellefi Değilim'}</p>
                </Col>
              </Row>
              <div className="mt-3 d-flex justify-content-end gap-2">
                <Button
                  variant="link"
                  className="text-warning p-0"
                  onClick={() => handleEditAddress(address)}
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                >
                  DÜZENLE
                </Button>
                    |
                <Button
                  variant="link"
                  className="text-warning p-0"
                  onClick={() => handleDeleteAddress(address._id)}
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                >
                  SİL
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))
      ) : (
        <p>Henüz adres eklenmemiş.</p>
      )}
    </Accordion>

    <div className="d-flex justify-content-end mt-3">
      <Button
        variant="link"
        className="text-warning p-0"
        onClick={handleShowOffcanvas}
        style={{ textDecoration: 'none', fontWeight: 'bold' }}
      >
        + YENİ GÖNDERİCİ ADRESİ EKLE
      </Button>
    </div>
  </Card.Body>
</Card>


 {/* ///////////////////Aalıcı adresleri////////////////////// */}
     <Card className="mb-3">
  <Card.Header className="text-center">ALICI ADRESLERİ</Card.Header>
  <Card.Body>
    <Accordion activeKey={activeCustomerAccordionKey} onSelect={(key) => setActiveCustomerAccordionKey(key)}>
      {customerAddresses.length > 0 ? (
        customerAddresses.map((address, index) => (
          <Accordion.Item eventKey={index.toString()} key={address._id || index}>
            <Accordion.Header className="d-flex align-items-center justify-content-center">
              <div className="d-flex align-items-center" style={{ justifyContent: 'center', width: '100%' }}>
                <span className="me-2">{`${address.firstName} ${address.lastName}`}</span>
                <Form.Check
                  type="checkbox"
                  checked={!!selectedCustomerAddresses[address._id]}
                  onChange={(e) => {
                    const isChecked = e.target.checked;
                    setSelectedCustomerAddresses({ [address._id]: isChecked });
                  }}
                />
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <Row>
                <Col md={6} className="text-center">
                  <p><strong>İsim:</strong> {address.firstName} {address.lastName}</p>
                  <p><strong>Şehir/İlçe:</strong> {address.cityName}, {address.districtName}</p>
                  <p><strong>Telefon:</strong> {address.phone}</p>
                  <p><strong>E-posta:</strong> {address.email}</p>
                </Col>
                <Col md={6} className="text-center">
                  <p><strong>Adres:</strong> {address.fullAddress}</p>
                  <p><strong>Posta Kodu:</strong> {address.postalCode}</p>
                  {address.corporateTitle && <p><strong>Kurumsal Ünvan:</strong> {address.corporateTitle}</p>}
                  <p><strong>Vergi No/Daire:</strong> {address.taxNumber} / {address.taxOffice}</p>
                  <p><strong>E-Fatura:</strong> {address.isEInvoice ? 'E-fatura Mükellefiyim' : 'E-fatura Mükellefi Değilim'}</p>
                </Col>
              </Row>
              <div className="mt-3 d-flex justify-content-end gap-2">
                <Button
                  variant="link"
                  className="text-warning p-0"
                  onClick={() => handleEditCustomerAddress(address)}
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                >
                  DÜZENLE
                </Button>
                |
                <Button
                  variant="link"
                  className="text-warning p-0"
                  onClick={() => handleDeleteCustomerAddress(address._id)}
                  style={{ textDecoration: 'none', fontWeight: 'bold' }}
                >
                  SİL
                </Button>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        ))
      ) : (
        <p className="text-muted text-center">Henüz müşteri adresi eklenmemiş.</p>
      )}
    </Accordion>

    <div className="d-flex justify-content-end mt-3">
      <Button
        variant="link"
        className="text-warning p-0"
        onClick={handleShowCustomerOffcanvas}
        style={{ textDecoration: 'none', fontWeight: 'bold' }}
      >
        + YENİ ALICI ADRESİ EKLE
      </Button>
    </div>
  </Card.Body>
</Card>


{/* ////////////////////////////Ürünler///////////////////////////////////// */}
<Card className="mb-3">
  <Card.Header className="text-center">ÜRÜNLER</Card.Header>
  <Card.Body>
    <Accordion activeKey={activeProductAccordionKey} onSelect={(key) => setActiveProductAccordionKey(key)}>
    {products.length > 0 ? (
        products.map((product, index) => (
            <Accordion.Item eventKey={index.toString()} key={product._id || index}>
                <Accordion.Header className="d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center" style={{ width: '100%', justifyContent: 'center' }}>
                        <span className="me-2" style={{ lineHeight: '1.5' }}>{product.name}</span>
                        <Form.Check
                            type="checkbox"
                            checked={!!selectedProducts[product._id]}
                            onChange={(e) => {
                                const isChecked = e.target.checked;
                                setSelectedProducts((prev) => ({
                                    ...prev,
                                    [product._id]: isChecked,
                                }));
                            }}
                        />
                    </div>
                </Accordion.Header>
                <Accordion.Body>
                    <div className="text-center">
                        <div>Ürün Fiyatı: {product.price} ₺</div>
                        <div>Ürün Adı: {product.name}</div>
                    </div>
                    <div className="mt-2 d-flex justify-content-end">
                        <Button
                            variant="link"
                            className="text-warning p-0"
                            onClick={() => handleEditProduct(product)} // Düzenle butonu
                            style={{ textDecoration: "none", fontWeight: "bold" }}
                        >
                            DÜZENLE
                        </Button>
                        <span className="mx-2" style={{ fontWeight: "bold" }}>|</span>
                        <Button
                            variant="link"
                            className="text-warning p-0"
                            onClick={() => handleDeleteProduct(product._id)} // Sil butonu
                            style={{ textDecoration: "none", fontWeight: "bold" }}
                        >
                            SİL
                        </Button>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        ))
    ) : (
        <p className="text-muted text-center">Henüz ürün eklenmemiş.</p>
    )}
</Accordion>

    
    <div className="d-flex justify-content-end mt-3">
      <Button
        variant="link"
        className="text-warning"
        onClick={handleShowProductOffcanvas}
        style={{ textDecoration: "none", fontWeight: "bold", fontSize: "16px" }}
      >
        + YENİ ÜRÜN EKLE
      </Button>
    </div>
  </Card.Body>
</Card>


{/* /////////////////////////////////////////////////////// */}

      {/* Kargo Seçimi */}
      <Row className=" fluid mb-1">
        <Col>
          <div className="alert alert-info text-center" style={{  backgroundColor: '#e9f7ff' }}>
            <strong>Dilersen aşağıda yer alan ÖDEME AL Seçeneğini Seçerek, Alıcıdan Sipariş bedelini Kapıda veya Link ile Online Olarak Tahsil Edebilirsin.</strong>
            <br />
            Daha detaylı bilgi almak için <a href="#" className="alert-link">tıklayınız.</a>
          </div>
        </Col>
      </Row>

      <Row className='mb-3'>
        <Col>
        {/* Online veya Kapıda Ödeme Al Seçimi */} 
<Form.Group controlId="paymentMethod">
  <Form.Label>Online veya Kapıda Ödeme Al</Form.Label>
  <Form.Select
    onChange={(e) => {
      const selectedPayment = e.target.value;
      setPaymentMethod(selectedPayment);

      // Kapıda ödeme seçildiğinde Yurtiçi Kargo'yu sıfırla
      if (selectedPayment === 'kapida' && cargoCompany === 'yurtici') {
        setCargoCompany('');
      }
    }}
    value={paymentMethod}
  >
    <option value="" disabled>Bir ödeme seçin...</option>
    <option value="online">Online Ödeme Al</option>
    <option value="kapida">Kapıda Ödeme Al</option>
  </Form.Select>
</Form.Group>

  {paymentMethod === 'online' && (
        <Row className="mt-3 text-center">
           <Col lg='12' md='8'>
      <div className="d-flex justify-content-between align-items-center p-3 border rounded" style={{ backgroundColor: '#f9f9f9', border: '1px solid #ddd' }}>
        <div className="me-auto">
          <Form.Label className="mb-0" style={{ fontWeight: 'bold', color: '#333' }}>
            Müşterinizden online tahsil edilecek tutar (₺)
          </Form.Label>
        </div>
        {console.log("Toplam tutar (online ödeme):", totalAmount)}
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#FFCA2C' }}>
          {Number.isFinite(totalAmount) ? totalAmount.toFixed(2) : '0.00'} ₺
        </span>
      </div>
    </Col>
<Col lg='12'>
  <div className="mt-3 text-center" style={{ backgroundColor: '#343a40', color: 'white',padding: '1rem' }}>
    <p style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
      TESLİMDEN 30 GÜN SONRASINDA SIFIR %0 <span>HEMEN %5 KOMİSYON UYGULANIR</span>
      <OverlayTrigger
        trigger={['hover', 'focus']}
        placement="left"
        overlay={
          <Tooltip id="tooltip-custom-2" style={{ backgroundColor: 'green', color: 'black', zIndex: 10500 }}>
            Müşterinizden tahsil edilen tutar sipariş teslimini takiben 30 gün sonrasında hiç bir kesinti yapılmadan cari hesabınıza aktarılır. Dilerseniz sipariş teslimi sonrası da yüzde 5 kesinti ile tek tuşla anlık olarak hak ediş bedelini cari hesabınıza aktarabilirsiniz.
          </Tooltip>
        }
      >
        <span style={{ marginLeft: '20px', cursor: 'pointer', color: 'white',fontSize: '1.5rem' }}><FaInfoCircle/></span>
      </OverlayTrigger>
    </p>
  </div>
</Col>

 

        </Row>
      )}
</Col>

      </Row>

      <Row className="mb-3">
        {/* Kargo Firması Seçimi */}
        <Col md={6}>
          <Form.Group controlId="cargoCompany">
  <Form.Label>Kargo Firması</Form.Label>
  <Form.Select
    onChange={(e) => setCargoCompany(e.target.value)}
    value={cargoCompany}
  >
    <option value="" disabled>Kargo Firması Seçin...</option>
    <option value="ptt">PTT - 75,00 ₺</option>
    <option value="ups">UPS - 120,00 ₺</option>
    {/* Kapıda ödeme seçilmediğinde Yurtiçi Kargo seçeneğini göster */}
    {paymentMethod !== 'kapida' && <option value="yurtici">YURTİÇİ - 124,00 ₺</option>}
  </Form.Select>
  <Form.Text className="text-muted">Başlayan Fiyatlarla</Form.Text>
</Form.Group>
        </Col>

        {/* Kim Öder Seçimi */}
        <Col md={6}>
         {/* Kim Öder Seçimi */} 
<Form.Group controlId="paymentMethod">
  <Form.Label>Kim Öder</Form.Label>
  <Form.Select
    onChange={(e) => setPaymentMethod(e.target.value)}
    value={paymentMethod}
  >
    {/* İşlevsiz varsayılan seçenek */}
    <option value="" disabled>Seçiniz</option>
    <option value="sender">Gönderici Öder</option>
    {cargoCompany !== "yurtici" && <option value="recipient">Alıcı Öder</option>}
  </Form.Select>
</Form.Group>

        </Col>
      </Row>
{paymentMethod === 'kapida' && (
  <Row className="mt-3">
    <Col lg='12'>
      {/* Bilgilendirme Kartı */}
      <Card className="mb-3">
        <Card.Body className="text-center" style={{ backgroundColor: '#343a40', color: 'white', padding: '1rem' }}>
          <Card.Text>
            Kapıda Ödemeli Satışlarda, Kargo taşıma Bedeli göndericiden (Sizden) Tahsil Edilir. Lütfen satış fiyatınızı buna göre belirleyiniz.
          </Card.Text>
        </Card.Body>
      </Card>

      <div className="d-flex justify-content-between align-items-center p-3 border rounded" style={{ backgroundColor: '#f9f9f9', border: '1px solid #ddd' }}>
        <div className="me-auto">
          <Form.Label className="mb-0" style={{ fontWeight: 'bold', color: '#333' }}>
            Müşterinizden Kapıda Tahsil Edilecek Tutar
          </Form.Label>
        </div>
        <span style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#FFCA2C' }}>
          {Number.isFinite(totalAmount) ? totalAmount.toFixed(2) : '0.00'} ₺
        </span>
      </div>

      {/* Paramı Nasıl Ne Zaman Alırım Kartı */}
      <Card className="mt-3">
       <Card.Body className="d-flex justify-content-center align-items-center">
  <span style={{ fontWeight: 'bold', fontSize: '1.5rem', textAlign: 'center' }}>
    Paramı Nasıl Ne Zaman Alırım
  </span>
 <OverlayTrigger
  trigger={['hover', 'focus']}
  placement="left"
  overlay={
    <Tooltip id="tooltip-custom" style={{ backgroundColor: 'black', color: 'white', zIndex: 10500 }}>
     PTT ile Organize Ettiğiniz Kapıda Ödemeli Satışlarda;
Ürün müşteriye teslim edildikten sonrasında, Kapıda Tahsil edilen sipariş bedelinden PTT tarafından her 500 ₺ için 7,5₺ kesinti yapılarak kalan tutar tarafınıza ait PTT posta çeki hesabınıza aktarılır.

UPS kargo ile organize Ettiğiniz kapıda ödemeli Nakit satışlarda 7,5 ₺ kesinti yapılarak teslim sonrası 3 iş günü içerisinde, kredi kartlı satışlarda 7 iş günü içerisinde 7,5₺ + %3 banka komisyonu kesinti uygulanarak kalan tutar dijital cüzdan hesabınıza aktarılır
    </Tooltip>
  }
>
  <span style={{ marginLeft: '10px', cursor: 'pointer', color: 'black', fontSize: '1.5rem' }}>
    <FaInfoCircle />
  </span>
</OverlayTrigger>

</Card.Body>

      </Card>
    </Col>
  </Row>
)}



      {/* Kapıda Ödeme Tutarı */}
      {paymentMethod === 'kapida' && (
     <Row className="mb-3">
      <Col>
        <Form.Group controlId="payAtDoorPrice">
          <Form.Label>Kapıda Ödeme Tutarı (₺)</Form.Label>
          <Form.Control
            type="number"
            value={payAtDoorPrice}
            onChange={handlePriceChange}
            onBlur={handleBlur} // Input kaybedildiğinde çalışacak
            placeholder="Kapıda ödeme tutarını girin"
            required
            step="0.01" // Ondalık girişi destekler
          />
        </Form.Group>
      </Col>
    </Row>
      )}

      {/* PTT Posta Çeki Hesabı */}
      {cargoCompany === 'ptt' && paymentMethod === 'kapida' && (
        <Row className="mb-3">
  <Col md={6}>
    <Form.Group controlId="postalCheckAccount">
      <Form.Label>
        PTT Posta Çeki Hesabı
    <OverlayTrigger
  placement="right"
  overlay={
    <Tooltip id="tooltip-custom" style={{ backgroundColor: 'white', color: 'black', border: '1px solid black' }}>
      Sistemimizde PTT posta çeki hesabınız daha önce kaydedilmediği için ve kapıda ödemeli PTT gönderimlerinde ücretin tarafınıza aktarılabilmesi için zorunludur. Hakkediş bedelinin tarafınıza aktarılabilmesi için PTT posta çeki hesabının sistemimizde kayıtlı hesap sahibine (Sanatkardan.com'da kayıtlı satıcı kişi veya kurum) ait olması gerekmektedir. <br />
      * PTT Posta Çeki Hesap numaranızı değiştirmek için bizimle iletişime geçiniz.
    </Tooltip>
  }
>
  <span className="ms-2"><FaInfoCircle/></span>
</OverlayTrigger>

      </Form.Label>
      <Form.Control
        type="text"
        placeholder="PTT Posta Çeki Hesabınızı Girin"
        // Diğer özellikler
      />
    </Form.Group>
  </Col>
</Row>

      )}

      {/* Hesapla Butonu */}
      <Row className="mb-3">
        <Col className="text-center">
          <Button variant="primary" onClick={handleCalculateCargoShow}>
            Desi Hesapla
          </Button>
        </Col>
      </Row>

      {/* Form */}
      <Card className="mb-3">
        <Card.Body className="text-center">
          <Form onSubmit={handleSubmit}>
          <Form.Group controlId="eInvoice" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="E-Fatura Oluştur (+2,00₺)"
                onChange={() => handleShowInvoiceForm(payeeAddress)} // Checkbox tıklanınca E-Fatura formunu aç
              />
            </Form.Group>
            <Form.Group controlId="sendEmail" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="Alıcıya e-posta kargo takip URL'si gönder"
                name="sendEmail"
                checked={formData.sendEmail}
                onChange={handleFormChange}
              />
            </Form.Group>
            <Form.Group controlId="sendSMS" className="d-flex justify-content-center">
              <Form.Check
                type="checkbox"
                label="Alıcıya SMS ile Takip URL bildirimi gönder (+1,00₺)"
                name="sendSMS"
                checked={formData.sendSMS}
                onChange={handleFormChange}
              />
            </Form.Group>

            {/* Mesafeli Satış Sözleşmesi ve KVKK */}
            <Card className="mb-7">
              <Card.Body>
                <p><i className="bi bi-info-circle"></i> K.V.K.K gereği 3. şahıslara sistem üzerinden göndereceğiniz bildirim için müşterinizin açık rızasını almak satıcı olarak tarafınızın yükümlülüğündedir.</p>
                <p><i className="bi bi-info-circle"></i> E-ticaret Satışınıza istinaden gönderi yapıyorsanız, dilerseniz <Button variant="link" className="text-warning p-0" onClick={handleShowDistanceContract}>Buradan</Button> Mesafeli satış sözleşmesine ulaşabilirsiniz.</p>
              </Card.Body>
            </Card>

            <Button variant="warning" type="submit" className="mt-3 w-100">
              Gönder
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {/* Offcanvas'lar */}
      <AddAddress
        show={showOffcanvas}
        handleClose={handleCloseOffcanvas}
        handleSubmit={(newAddress) => (editingAddress ? handleUpdateAddress(newAddress) : handleAddAddress(newAddress))}
        editAddress={editingAddress}
        setUpdateTrigger={setUpdateTrigger}
      />

      <AddCustomerAddress
        show={showCustomerOffcanvas}
        handleClose={handleCloseCustomerOffcanvas}
        editAddress={editingCustomerAddress}
        setUpdateCustomerTrigger={setUpdateCustomerTrigger}
      />

<AddProducts
  show={showProductOffcanvas}
  handleClose={handleCloseProductOffcanvas}
  onProductAdded={handleAddProduct} // Ürün eklendiğinde veya güncellendiğinde handleAddProduct'ı çağırır
  initialData={editingProduct} // Düzenleme moduna geçmek için ürünü geçiriyoruz
/>


      <DistanceContract show={showDistanceContract} handleClose={handleCloseDistanceContract} />
        <InvoiceForm 
        show={showInvoiceForm} 
        handleClose={handleCloseInvoiceForm} 
        payeeAddress={payeeAddress} 
      />

      {/* CalculateCargo Offcanvas */}
      <Offcanvas show={showCalculateCargo} onHide={handleCalculateCargoClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Desi Hesaplama</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <CalculateCargo cargoCompany={cargoCompany} onCalculate={handleCalculateCargo} />
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default ManuelShipments;
