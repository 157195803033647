import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../App';  // Giriş durumunu context ile kontrol ediyoruz

const ProtectedRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();  // Giriş durumu

  // Eğer kullanıcı giriş yapmamışsa, giriş sayfasına yönlendir
  if (!isLoggedIn) {
    return <Navigate to="/giriş-yap" />;
  }

  // Giriş yapılmışsa, istenen sayfayı render et
  return children;
};

export default ProtectedRoute;
