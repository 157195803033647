import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PaymentEftSuccess = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100">
      <Row className="text-center w-100">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          <h1 className="display-4 text-success">Talebiniz Alınmıştır!</h1>
          <p className="lead mb-4 text-light">
            Para çekme talebiniz başarıyla alınmıştır. En kısa sürede hesabınıza tutar geçecektir. Teşekkür ederiz!
          </p>
          <Button
            variant="success"
            size="lg"
            onClick={() => navigate('/panelim')}
            className="px-4 py-2"
          >
            Geri Dön
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentEftSuccess;
