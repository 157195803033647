import React from 'react';
import { Container, Row, Col, Card, Button, Form, Dropdown } from 'react-bootstrap';
import { BsCart3, BsFileEarmarkText } from 'react-icons/bs'; // Import icons

const Integration = () => {
  return (
    <Container fluid className="py-4">
      {/* Header Section */}
      <Row className="align-items-center mb-4">
        <Col>
          <div className="d-flex align-items-center">
            <img src="/path/to/your/logo.png" alt="İndirimli Kargo" style={{ width: '32px', marginRight: '8px' }} />
            <h5 className="mb-0">API ENTEGRASYON</h5>
          </div>
        </Col>
      </Row>

      {/* Search Section */}
      <Row className="mb-4">
        <Col>
          <Card className="p-3">
            <Form>
              <Form.Group as={Row}>
                <Col md={10}>
                  <Form.Control type="text" placeholder="Müşteri Ad, Soyad, Telefon ile ara." />
                </Col>
                <Col md={2}>
                  <Button variant="warning" className="w-100">ARA</Button>
                </Col>
              </Form.Group>
            </Form>
          </Card>
        </Col>
      </Row>

      {/* Information Section */}
      <Row className="mb-4">
        <Col>
          <p>
            API Entegrasyonu ile aktarılan siparişleriniz aşağıda listelenmiştir. Dilerseniz İşlemler Butonu ile Siparişlerinizin Kargo ve Faturalarını Kolayca oluşturabilirsiniz.
          </p>
        </Col>
      </Row>

      {/* Order List Section */}
      <Row>
        <Col>
          <Card className="p-3">
            <Form.Check type="checkbox" label="" className="mb-3" />
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <BsCart3 size={24} className="me-3" /> {/* Shopping cart icon */}
                <BsFileEarmarkText size={24} className="me-3" /> {/* Document icon */}
              </div>
              <div>
                <h6 className="mb-0">Ergün KUŞCU</h6>
                <p className="mb-0 text-muted">Elbise Askılık - 500,00₺</p>
                <p className="mb-0 text-muted">Çankaya / ANKARA</p>
                <p className="mb-0 text-muted">0850 304 0555</p>
                <p className="mb-0 text-muted">Trendyol</p>
              </div>
              <div>
                {/* Dropdown for İşlemler */}
                <Dropdown>
                  <Dropdown.Toggle variant="warning" id="dropdown-basic">
                    İŞLEMLER
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Kargo Oluştur</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">E-Fatura Oluştur</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Siparişi İptal Et</Dropdown.Item>
                    <Dropdown.Item href="#/action-4">Güncelle</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Integration;
