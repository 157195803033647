import React, { useState, useEffect } from 'react';
import { Accordion, Button, Card, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios';
import AddAddress from './AddAddress'; // Offcanvas içeren adres ekleme componenti

const MeTo = () => {
  const [addresses, setAddresses] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [editingAddress, setEditingAddress] = useState(null);
  const [selectedCargo, setSelectedCargo] = useState(''); // Kargo firması seçimi
  const [paymentResponsibility, setPaymentResponsibility] = useState(''); // Kim öder seçimi
  const [isCustomer, setIsCustomer] = useState(false); // isCustomer state ekledik
const apiUrl = process.env.REACT_APP_API_URL;
  // Backend'den adresleri çekmek için kullanıyoruz.


  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const fetchAddresses = async () => {
      try {
      const response = await axios.get(`${apiUrl}/addresses`); // Backend'den adresleri çekiyoruz.
        setAddresses(response.data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    const fetchCustomerAddresses = async () => {
      try {
        const response = await axios.get(`${apiUrl}/customerAddresses`); // Backend'den alıcı adresleri çekiyoruz.
        setCustomerAddresses(response.data);
      } catch (error) {
        console.error('Error fetching customer addresses:', error);
      }
    };

    fetchAddresses();
    fetchCustomerAddresses();
  }, []);

  // Adres Silme İşlemi
  const handleDelete = async (id, isCustomer) => {
    try {
     const endpoint = isCustomer ? `${apiUrl}/customerAddresses` : `${apiUrl}/addresses`
      await axios.delete(`${endpoint}/${id}`); // Backend'de silme işlemi
      if (isCustomer) {
        setCustomerAddresses(customerAddresses.filter((address) => address.id !== id));
      } else {
        setAddresses(addresses.filter((address) => address.id !== id));
      }
    } catch (error) {
      console.error('Error deleting address:', error);
    }
  };

  // Adres Düzenleme İşlemi
  const handleEdit = (address, isCustomer) => {
    setEditingAddress(address);
    setIsCustomer(isCustomer); // isCustomer state'ini güncelliyoruz
    setShowOffcanvas(true);
  };

  const handleShowOffcanvas = (isCustomer) => {
    setIsCustomer(isCustomer); // isCustomer state'ini güncelliyoruz
    setShowOffcanvas(true);
  };

  const handleCloseOffcanvas = () => {
    setShowOffcanvas(false);
    setEditingAddress(null);
  };

  const handleAddAddress = (newAddress) => {
    if (editingAddress) {
      // Düzenlenen adresi güncelleme
      if (isCustomer) {
        const updatedAddresses = customerAddresses.map((address) =>
          address.id === editingAddress.id ? newAddress : address
        );
        setCustomerAddresses(updatedAddresses);
      } else {
        const updatedAddresses = addresses.map((address) =>
          address.id === editingAddress.id ? newAddress : address
        );
        setAddresses(updatedAddresses);
      }
    } else {
      // Yeni adres ekleme
      setAddresses([...addresses, newAddress]);
    }
  };

  // Kargo firması ve kim öder seçimi
  const handleCargoSelect = (e) => setSelectedCargo(e.target.value);
  const handlePaymentResponsibility = (e) => setPaymentResponsibility(e.target.value);

  // Kargo firması ve ödeme seçenekleri
  const renderPaymentOptions = () => {
    if (selectedCargo === 'PTT' || selectedCargo === 'UPS') {
      return (
        <>
          <Form.Group controlId="paymentResponsibility">
            <Form.Label>Kim Öder Seçimi</Form.Label>
            <Form.Select value={paymentResponsibility} onChange={handlePaymentResponsibility}>
              <option value="">Kim Öder Seç</option>
              <option value="kapida">Kapıda Ödeme</option>
              <option value="buyerPaid">Alıcı Ödemeli</option>
              <option value="sellerPaid">Satıcı Ödemeli</option>
              <option value="customerConfirmed">Müşteri Doğrulamalı Kapıda Ödeme</option>
              <option value="online">Online Ödeme Al</option>
            </Form.Select>
          </Form.Group>
        </>
      );
    }

    if (selectedCargo === 'Yurtiçi') {
      return (
        <>
          <Form.Group controlId="paymentResponsibility">
            <Form.Label>Kim Öder Seçimi</Form.Label>
            <Form.Select value={paymentResponsibility} onChange={handlePaymentResponsibility}>
              <option value="">Kim Öder Seç</option>
              <option value="sellerPaid">Satıcı Ödemeli</option>
              <option value="online">Online Ödeme Al</option>
            </Form.Select>
          </Form.Group>
        </>
      );
    }

    return null;
  };

  return (
    <div className="container mt-3" id="bana-gelecek">
      {/* Gönderici Adresi */}
      <Card className="mb-3">
        <Card.Header>Gönderici Adresi Seç, Ekle</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Gönderici Adresi Seç, Ekle</Accordion.Header>
              <Accordion.Body>
                {addresses.length > 0 ? (
                  addresses.map((address, index) => (
                    <div key={index} className="mb-3">
                      <div>
                        <strong>{address.title}</strong> <br />
                        {address.street}, {address.city} / {address.district} <br />
                        {address.phone}
                      </div>
                      <div className="mt-2">
                        <Button
                          variant="link"
                          className="text-warning p-0"
                          onClick={() => handleEdit(address, false)} // isCustomer: false
                        >
                          DÜZENLE
                        </Button>{' '}
                        |{' '}
                        <Button
                          variant="link"
                          className="text-warning p-0"
                          onClick={() => handleDelete(address.id, false)} // isCustomer: false
                        >
                          SİL
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Henüz adres eklenmemiş.</p>
                )}
                <Button
                  variant="link"
                  className="text-warning"
                  onClick={() => handleShowOffcanvas(false)} // isCustomer: false
                >
                  + YENİ ADRES EKLE
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

      {/* Alıcı Adresi */}
      <Card className="mb-3">
        <Card.Header>Alıcı Adresi Seç, Ekle</Card.Header>
        <Card.Body>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>Alıcı Adresi Seç, Ekle</Accordion.Header>
              <Accordion.Body>
                {customerAddresses.length > 0 ? (
                  customerAddresses.map((address, index) => (
                    <div key={index} className="mb-3">
                      <div>
                        <strong>{address.title}</strong> <br />
                        {address.street}, {address.city} / {address.district} <br />
                        {address.phone}
                      </div>
                      <div className="mt-2">
                        <Button
                          variant="link"
                          className="text-warning p-0"
                          onClick={() => handleEdit(address, true)} // isCustomer: true
                        >
                          DÜZENLE
                        </Button>{' '}
                        |{' '}
                        <Button
                          variant="link"
                          className="text-warning p-0"
                          onClick={() => handleDelete(address.id, true)} // isCustomer: true
                        >
                          SİL
                        </Button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Henüz alıcı adresi eklenmemiş.</p>
                )}
                <Button
                  variant="link"
                  className="text-warning"
                  onClick={() => handleShowOffcanvas(true)} // isCustomer: true
                >
                  + YENİ ALICI ADRESİ EKLE
                </Button>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Card.Body>
      </Card>

      {/* Kargo Firması ve Kim Öder Seçenekleri */}
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>Kargo Firması</Card.Header>
            <Card.Body>
              <Form.Select value={selectedCargo} onChange={handleCargoSelect}>
                <option value="">Kargo Firması Seç</option>
                <option value="PTT">PTT - 75,00 ₺ (Başlayan Fiyatlarla)</option>
                <option value="UPS">UPS - 120,00 ₺ (Başlayan Fiyatlarla)</option>
                <option value="Yurtiçi">Yurtiçi - 124,00 ₺ (Başlayan Fiyatlarla)</option>
              </Form.Select>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={6}>
          <Card>
            <Card.Header>Kim Öder</Card.Header>
            <Card.Body>
              {renderPaymentOptions()}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Ekle Butonu */}
      <Row className="text-center">
        <Button variant="warning" className="w-50 mx-auto">
          Ekle
        </Button>
      </Row>

      {/* Add Address Offcanvas */}
      <AddAddress
        show={showOffcanvas}
        handleClose={handleCloseOffcanvas}
        handleSubmit={handleAddAddress}
        editingAddress={editingAddress}
      />
    </div>
  );
};

export default MeTo;
