import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Form, Accordion, Table } from 'react-bootstrap';
import ExportForm from '../Panel/Export/ExportForm';
import WalletCard from './Wallet/WalletCard';

const ExportPage = () => {
  const [showExportForm, setShowExportForm] = useState(false);

  const [searchFilters, setSearchFilters] = useState({
    searchByName: '',
    searchByRef: '',
    startDate: '',
    endDate: '',
  });

  const [cargoData, setCargoData] = useState([]);

  const handleFilterChange = (e) => {
    setSearchFilters({
      ...searchFilters,
      [e.target.name]: e.target.value,
    });
  };

  const fetchCargoData = () => {
    setCargoData([]); // Mock verileri kaldırdık
  };

  React.useEffect(() => {
    fetchCargoData();
  }, []);

  return (
    <Container fluid className="py-4">
      {/* Üst Kısım */}
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h4 className="mb-0">İhracat (Yurtdışı Kargo)</h4>
        </Col>
        <Col xs={12} md={6} className="text-end">
          <Button variant="warning" onClick={() => setShowExportForm(true)}>+ Oluştur</Button>
        </Col>
      </Row>

      {/* Bakiye Bilgileri */}
      <Row className="align-items-stretch mb-4">
    <WalletCard/>
      </Row>

      {/* Arama ve Filtreleme Bölümü */}
      <Accordion defaultActiveKey="0" className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Arama, Filtreleme</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col xs={12} md={4}>
                <Card className="mb-3">
                  <Card.Header>Taşıma Durumuna Göre Filtrele</Card.Header>
                  <Card.Body>
                    <Form.Check type="radio" id="durum1" label="Kargonun Ofise Ulaşması Bekleniyor" />
                    <Form.Check type="radio" id="durum2" label="Alıcıdan Online Ödeme Bekleniyor" />
                    <Form.Check type="radio" id="durum3" label="Gönderi Bedeli Ödemesi Bekleniyor" />
                    <Form.Check type="radio" id="durum4" label="Alıcıya Gönderildi Transferde" />
                    <Form.Check type="radio" id="durum5" label="İade Gönderildi" />
                    <Form.Check type="radio" id="durum6" label="Teslim Edildi" />
                    <Button variant="warning" className="w-100 mt-3">Filtrele</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={8}>
                <Row>
                  <Col md={4}>
                    <Card className="mb-3">
                      <Card.Header>Müşteri Isım Veya Telefon Ile Arama</Card.Header>
                      <Card.Body>
                        <Form.Control
                          type="text"
                          placeholder="Müşteri Isım / Soy Isım veya Telefon no Ile Arayın"
                          name="searchByName"
                          value={searchFilters.searchByName}
                          onChange={handleFilterChange}
                        />
                        <Button variant="warning" className="w-100 mt-3">Ara</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mb-3">
                      <Card.Header>Kargo Referans Veya Takip Kodu Ile Arama</Card.Header>
                      <Card.Body>
                        <Form.Control
                          type="text"
                          placeholder="Kargo Referans Veya Takip Kodu Ile Arayın"
                          name="searchByRef"
                          value={searchFilters.searchByRef}
                          onChange={handleFilterChange}
                        />
                        <Button variant="warning" className="w-100 mt-3">Ara</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={4}>
                    <Card className="mb-3">
                      <Card.Header>Tarih Aralığı Ile Filtrele</Card.Header>
                      <Card.Body>
                        <Form.Control
                          type="text"
                          placeholder="Başlangıç Tarihi"
                          name="startDate"
                          value={searchFilters.startDate}
                          onChange={handleFilterChange}
                        />
                        <Form.Control
                          type="text"
                          placeholder="Bitiş Tarihi"
                          name="endDate"
                          value={searchFilters.endDate}
                          onChange={handleFilterChange}
                          className="mt-3"
                        />
                        <Button variant="warning" className="w-100 mt-3">Ara</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Kargo Listesi */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">KARGOLAR</h5>
              <div>
                <Button variant="outline-warning" className="me-2">Barkod Yazdır</Button>
                <Button variant="outline-warning">Çıktı Al</Button>
              </div>
            </Card.Header>
            <Card.Body>
              <Table hover responsive>
                <thead>
                  <tr>
                    <th>Alıcı</th>
                    <th>Durum</th>
                    <th>Desi/Kg</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {cargoData.length > 0 ? (
                    cargoData.map((cargo, index) => (
                      <tr key={index}>
                        <td>{cargo.recipient}</td>
                        <td>{cargo.status}<br />{cargo.ref}</td>
                        <td>{cargo.weight}</td>
                        <td></td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">Veri bulunamadı</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* ExportForm Offcanvas */}
      <ExportForm 
        show={showExportForm} 
        onHide={() => setShowExportForm(false)} 
      />
    </Container>
  );
};

export default ExportPage;
