import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaLightbulb } from 'react-icons/fa';
import woman from '../assets/images/Kargo/woman.webp';
import '../assets/css/How.css'; // CSS dosyasını import ediyoruz
import  '../assets/css/Animate.css'
import  '../assets/css/style.css'
// Logo dosyalarını import etme
import pttLogo from '../assets/images/Kargo/pttbg.webp';

import upsLogo from '../assets/images/Kargo/upsbg.webp';
import yurtiKargoLogo from '../assets/images/Kargo/yurtiçikargobg.webp';
import trendyolLogo from '../assets/images/Kargo/trendyollogo.webp';

const How = () => {

   useEffect(() => {
    const animateOnScroll = () => {
      const elements = document.querySelectorAll('.animate-left, .animate-right, .animate-top, .animate-bottom');
      elements.forEach(element => {
        const rect = element.getBoundingClientRect();

        // Eleman viewport içerisinde mi kontrol ediyoruz
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          element.classList.add('animated');
        } else {
          element.classList.remove('animated'); // Eğer eleman görünüm dışındaysa animasyon sınıfını kaldırıyoruz
        }
      });
    };

    window.addEventListener('scroll', animateOnScroll);
    animateOnScroll(); // Sayfa ilk yüklendiğinde animasyonları tetikleyin

    return () => {
      window.removeEventListener('scroll', animateOnScroll);
    };
  }, []);


  return (
    
    <Container fluid className="p-4" id="nasil-calisir" style={{ borderTop: '2px solid grey' }}>
      <Row>
        <Col md={6} className="d-flex align-items-center justify-content-center animate-left" style={{ backgroundImage: `url(${woman})`, backgroundSize: 'cover', backgroundPosition: 'center', color: 'white'  }}>
          <div className="text-center p-4 border rounded" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <h3>Tek Panelde Tüm Kontrol Sende</h3>
          </div>
        </Col>

        <Col md={6} className="d-flex flex-column justify-content-center animate-right">
          <div className="text-center mb-4">
            <FaLightbulb size={50} style={{ color: '#FFC107' }} />
            <h2 className="d-inline-block ml-3 text-warning">Pratik Ekonomik İnovatif</h2>
            <p className="lead">Hem Vakit Hem Nakit Kazan</p>
          </div>
          <Card className="bg-dark text-light p-4">
            <Card.Body>
              <h3 style={{ color: 'white' }}>Ücretsiz Kayıt Ol:</h3>
              <p style={{ color: 'gray' }}>Buradan Dakikalar İçerisinde Tamamen Ücretsiz Kayıt Ol!</p>

              <h3 style={{ color: 'white' }}>Hesabına Bakiye Yükle:</h3>
              <p style={{ color: 'gray' }}>
                Gönderi bedelleri Dijital Cüzdan Hesabından Kargo Tesliminde Anlık olarak Tahsil Edilir. Şubede nakit ödemeyle vakit kaybetmezsin. 
                Hesabına Kredi / Banka Kartı veya Havale / EFT yöntemiyle 7/24 Güvenle yükleme yapabilirsin.
              </p>

              <h3 style={{ color: 'white' }}>Gönderini Sisteme Gir:</h3>
              <p style={{ color: 'gray' }}>
                Gönderi bilgilerini kolayca sisteme manuel olarak gir veya API desteğiyle pazaryeri / e-ticaret sisteminden otomatik aktarılan 
                siparişlerinin Gönderilerini ve dilersen E-Faturalarını tek tuşla kolayca oluştur.
              </p>

              <h3 style={{ color: 'white' }}>Ürünlerini Kargoya Teslim Et!</h3>
              <p style={{ color: 'gray' }}>
                Seçtiğin Kargo Firmasının Dilediğin Şubesinde, sadece Sistemin tarafına bildirdiği 8 haneli kargo referans kodunu belirterek veya 
                barkod çıktısını okutarak, ürünlerini anında Kargola, tüm gönderilerini tek sayfada anlık olarak takip et. Dilersen alıcılara 
                kargo takip sayfasını tek tuşla ilet.
              </p>

              <div className="mt-4">
                <a href="https://www.example.com" target="_blank" rel="noopener noreferrer" className="text-warning" style={{ fontSize: '1.2rem', textDecoration: 'none' }}>
                  Detaylı Kullanım Videosu 
                </a>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <div style={{ borderTop: '5px solid #212529', borderBottom: '2px solid #212529', marginTop: '20px', paddingTop: '20px', paddingBottom: '20px' }}>
        <Row className="text-center">
          <Col>
            <h4 style={{ color: 'white' }}>İşbirliği Yaptığımız Firmalar</h4>
          </Col>
        </Row>

        <Row className="text-center justify-content-center mt-3 animate-bottom">
          <Col xs={4} sm={4} md={2} className="d-flex justify-content-center align-items-center logo-container ">
            <img src={pttLogo} alt="PTT" className="logo-img ptt-logo" />
          </Col>
   
          <Col xs={4} sm={4} md={2} className="d-flex justify-content-center align-items-center logo-container">
            <img src={upsLogo} alt="UPS" className="logo-img ups-logo" />
          </Col>
          <Col xs={4} sm={4} md={2} className="d-flex justify-content-center align-items-center logo-container">
            <img src={yurtiKargoLogo} alt="Yurtiçi Kargo" className="logo-img yurtiKargo-logo" />
          </Col>
          <Col xs={4} sm={4} md={2} className="d-flex justify-content-center align-items-center logo-container">
            <img src={trendyolLogo} alt="Trendyol" className="logo-img trendyol-logo" />
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default How;