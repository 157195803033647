import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Popover, Overlay } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import '../assets/css/Services.css';
import '../assets/css/References.css';
// import Image1 from '../assets/images/Kargo/indirimli.png';
// import Image2 from '../assets/images/Kargo/indirimli.png';

const popoverContent = {
  kargo: 'Anlaşma, Taahhüt ve Sınır olmaksızın Sadece Sistemimize ücretsiz olarak kayıt olarak Dilediğiniz kargo firmasıyla %60 a varan indirimli fiyatlarla kargo gönderimi yapabilir tüm gönderilerinizi tek sayfada anlık takip edebilirsiniz.',
  efatura: 'Abonelik ücreti ödemeden ve toplu kontrol satın almak zorunda olmadan, tek tuşla e-faturalarınızı oluşturabilir tarafınıza gelen faturaları kaydederek hiçbir ek ücret ödemeden ön muhasebenizi tutabilirsiniz.',
  api: 'Online pazar yerlerinde veya kendi e-ticaret sitenizden satış yapıyorsanız API desteğimizle siparişlerinizi otomatik olarak sisteme aktarabilir tek tuşla kargo ve e-faturalarınızı oluşturabilirsiniz.',
  odeme: 'İhtiyacın olması halinde Tek tuşla Link ile online sipariş ödemesi alabilir veya Kapıda ödemeli satış ile müşteri güvenini ve satışlarını artırabilirsin.'
};

const PopoverBox = ({ title, content, activePopover, setActivePopover, id }) => {
  const target = useRef(null);

  const show = activePopover === id;

  const handleMouseEnter = () => {
    setActivePopover(id);
  };

  const handleMouseLeave = () => {
    setActivePopover(null);
  };

  return (
    <>
      <Button
        variant="link"
        className="text-decoration-none popover-box"
        ref={target}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <span className="d-flex justify-content-between align-items-center">
          {title} <span className="ml-auto">{show ? '-' : '+'}</span>
        </span>
      </Button>

      <Overlay
        target={target.current}
        show={show}
        placement="auto"
        containerPadding={10}
        onHide={() => setActivePopover(null)}
      >
        <Popover
          id={`popover-${id}`}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Popover.Body className="text-center">{content}</Popover.Body>
        </Popover>
      </Overlay>
    </>
  );
};

const Services = () => {
  const [activePopover, setActivePopover] = useState(null);

  return (
    <Container fluid id='main-container'>
      {/* Hizmetler Bölümü */}
      <Container fluid className="d-flex flex-column" id='hizmetlerimiz'>
        <Row className="flex-grow-1 flex-md-row flex-column">
          <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-center p-5">
            <div className="text-center">
              <h4 className="text-warning">HİZMETLERİMİZ</h4>
              <h1 className='text-light'>Kazandıran Çözümler.</h1>
              <p className="text-light mt-4">
                Tüm tacir ve üreticilerin ihtiyaçlarına yönelik geliştirdiğimiz yenilikçi ve kullanımı kolay hizmetlerimizle işlerinizi kolaylaştırırken kazancınızı katlayın.
              </p>
              <div className="d-flex justify-content-center circle-button-container">
                <div className="circle-button">
                  <p className="mb-0">Bizi Arayın</p>
                  <p className="small-text mb-0">7/24</p>
                </div>
                <div className="circle-button">
                  <p className="mb-0">Bize Yazın</p>
                  <p className="small-text mb-0">7/24</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-column justify-content-center align-items-start p-1 popover-section">
            <PopoverBox
              title="Kargo Çözümleri"
              content={popoverContent.kargo}
              activePopover={activePopover}
              setActivePopover={setActivePopover}
              id="kargo"
            />
            <PopoverBox
              title="E-Fatura"
              content={popoverContent.efatura}
              activePopover={activePopover}
              setActivePopover={setActivePopover}
              id="efatura"
            />
            <PopoverBox
              title="API Desteği"
              content={popoverContent.api}
              activePopover={activePopover}
              setActivePopover={setActivePopover}
              id="api"
            />
            <PopoverBox
              title="Ödeme Çözümleri"
              content={popoverContent.odeme}
              activePopover={activePopover}
              setActivePopover={setActivePopover}
              id="odeme"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Services;

