import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const PaymentFailure = () => {
  const navigate = useNavigate();

  return (
    <Container fluid className="d-flex align-items-center justify-content-center vh-100">
      <Row className="text-center w-100">
        <Col xs={12} md={8} lg={6} className="mx-auto">
          {/* Ödeme başarısız görüntüsü (İsteğe bağlı) */}
          <h1 className="display-4 text-light">Ödeme Başarısız!</h1>
          <p className="lead mb-4 text-warning">
            Ödemeniz sırasında bir hata oluştu. Lütfen tekrar deneyiniz.
          </p>
          <Button
            variant="danger"
            size="lg"
            onClick={() => navigate('/panelim')}
            className="px-4 py-2"
          >
            Tekrar Dene
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentFailure;
