import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

function PageNotFound() {
  return (
    <Container fluid className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
      <Row className="justify-content-center w-100">
        <Col xs={12} md={8} lg={6}>
          <Card className="text-center">
            <Card.Body>
              <Card.Title style={{ fontSize: '3rem', fontWeight: 'bold', color: '#FF0000' }}>404</Card.Title>
              <Card.Subtitle className="mb-3" style={{ fontSize: '1.5rem', color: '#555' }}>
                Ooops! Sayfa bulunamadı.
              </Card.Subtitle>
              <Card.Text style={{ fontSize: '1.1rem', color: '#888' }}>
                Aradığınız sayfa mevcut değil veya taşındı. Lütfen ana sayfaya dönmek için aşağıdaki butona tıklayın.
              </Card.Text>
              <Button variant="primary" href="/" style={{ padding: '10px 20px', fontSize: '1.2rem' }}>
                Anasayfaya Dön
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default PageNotFound;
