import React, { useState } from 'react';
import { Offcanvas, Button, Form, Row, Col } from 'react-bootstrap';
import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;  // Dinamik API URL'si

const DistanceContract = ({ show, handleClose }) => {
  const [email, setEmail] = useState('');
  const [insurance, setInsurance] = useState(false);  // Sigorta durumu için state

  // E-posta gönderme isteği
  const handleSend = async () => {
    try {
      // Backend'e POST isteği yapılıyor
      const response = await axios.post(`${apiUrl}/send-distance-contract`, { email, insurance });

      if (response.status === 200) {
        alert(`Mesafeli satış sözleşmesi ${email} adresine gönderildi.`);
      } else {
        alert('E-posta gönderilemedi. Lütfen tekrar deneyin.');
      }
    } catch (error) {
      console.error('E-posta gönderilirken bir hata oluştu:', error);
      alert('E-posta gönderilemedi. Lütfen tekrar deneyin.');
    }
  };

  // Mesafeli satış sözleşmesi indirme isteği
  const handleDownload = () => {
    // Dosya indirme işlemi için URL'ye yönlendirme
    window.open(`${apiUrl}/download-distance-contract`, '_blank');
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Mesafeli Satış Sözleşmesi</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <p>
          Mesafeli Satış Sözleşmesini İndirebilir, ya da Belirttiğiniz E-posta adresine gönderebilirsiniz.
        </p>
        <div className="d-flex justify-content-between">
          <Button variant="warning" onClick={handleDownload}>İndir</Button>
          <Form>
            <Row>
              <Col>
                <Form.Control
                  type="email"
                  placeholder="E-Posta Adresi"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Col>
              <Col>
                <Button variant="warning" onClick={handleSend}>Gönder</Button>
              </Col>
            </Row>
            {/* Sigorta seçeneği */}
            <Form.Group className="mt-3" controlId="insurance">
              <Form.Check
                type="checkbox"
                label="Kargo İçeriğini Sigortala (+10,00₺)"
                checked={insurance}
                onChange={(e) => setInsurance(e.target.checked)}
              />
            </Form.Group>
          </Form>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default DistanceContract;
