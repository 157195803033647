import React from 'react';
import { Offcanvas, Card, Button } from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';

const OnlinePaymentConfirmation = ({ show, onHide }) => {
  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <span className="d-flex align-items-center">
            <BsCheckCircle style={{ color: '#FFC107', marginRight: '8px' }} />
            YURTDIŞI SATIŞ İÇİN LİNK İLE ÖDEME TALEBİ BAŞARIYLA OLUŞTURULDU!
          </span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Card className="mb-3">
          <Card.Body>
            <div className="mb-3">
              <h6 className="text-center">ALICININ ÖDEME YAPMASI BEKLENİYOR!</h6>
              <p>
                Alıcıya Ödeme sayfası linkini SMS ile ilettik.
                <br />
                Ödeme Tamamlandığında, sistem tarafınızca anlık bildirim yapar.
                <br />
                Sonrasında ürünlerinizi kargoya teslim edebilirsiniz.
              </p>
              <p>
                Dilerseniz size aşağıda yer alan ödeme linkini müşterinize iletebilirsiniz.
              </p>
            </div>

            <Button variant="warning" className="w-100">
              ONLINE ÖDEME LİNKİ
            </Button>
            <div className="text-center mt-2">
              <a href="https://snt.tc/link-ile-odeme/458jfkdsjfdjsfh" target="_blank" rel="noopener noreferrer">
                www.snt.tc/link-ile-odeme/458jfkdsjfdjsfh
              </a>
            </div>
          </Card.Body>
        </Card>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OnlinePaymentConfirmation;
