import React from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import Footer from '../Footer';



const Kkvp = () => {
const handleDownload = () => {
    console.log("PDF dosyası indiriliyor...");
  };


  return (
    <Container fluid className="px-4 py-3">
      <Row className="mb-4">
        <Col>
          <h2 
            className="text-center font-weight-bold" 
            style={{ marginTop: '140px', color:'#EFB02F' }}
          >
            Kişisel Verilerin Korunması ve İşlenmesi Politikası
          </h2>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h4 className='mb-3 px-2' style={{ color: '#EFB02F' }}>TANIMLAR</h4>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>TANIM</th>
                <th>AÇIKLAMA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Açık Rıza</td>
                <td>Belirli bir konuya ilişkin, bilgilendirilmeye dayanan ve özgür iradeyle açıklanan rızadır.</td>
              </tr>
              <tr>
                <td>Anonim Hale Getirme</td>
                <td>İşisel verilerin başka verilerle eşleştirilse dahi hiçbir surette kimliği belirli veya belirlenebilir bir gerçek kişiyle ilişkilendirilemeyecek hale getirilmesidir.</td>
              </tr>
              <tr>
                <td>Çalışan Adayı</td>
                <td>İndirimlikargo bünyesinde çalışmayan ancak çalışan adayı statüsünde olan gerçek kişiler.</td>
              </tr>
              <tr>
                <td>Kişisel Veri</td>
                <td>Ad-soyad, adres, TC kimlik numarası, telefon numarası, e-posta dahil ancak bunlarla sınırlı olmamak üzere kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgidir.</td>
              </tr> 
              <tr>
                <td>Özel Nitelikli Kişisel Veri</td>
                <td>Irk, etnik köken, siyasi düşünce, felsefi inanç, din, mezhep veya diğer inançlar, kılık kıyafet, dernek vakıf ya da sendika üyeliği, sağlık, cinsel hayat, ceza mahkumiyeti ve güvenlik tedbirleriyle ilgili veriler ile biyometrik ve genetik veriler.</td>
              </tr> 
              <tr>
                <td>Politika</td>
                <td>Bu “indirimlikargo.com Kişisel Verilerin Korunması, İşlenmesi, Saklanması Ve İmhası Politikası”nı ifade eder.</td>
              </tr>
              {/* New rows for the additional terms */}
              <tr>
                <td>Veri İşleyen</td>
                <td>Veri sorumlusunun verdiği yetkiye dayanarak onun adına kişisel veri işleyen gerçek ve tüzel kişidir.</td>
              </tr>
              <tr>
                <td>Veri Sorumlusu</td>
                <td>Kişisel verilerin işlenme amaçlarını ve vasıtalarını belirleyen, verilerin sistematik bir şekilde tutulduğu yeri yöneten kişidir.</td>
              </tr>
              <tr>
                <td>Veri Kayıt Sistemi</td>
                <td>Kişisel verilerin belirli kriterlere göre yapılandırılarak işlendiği kayıt sistemidir.</td>
              </tr>
              <tr>
                <td>İş Ortakları</td>
                <td>İndirimlikargo ticari faaliyetleri çerçevesinde sözleşmesel ilişkiler kapsamında ortaklık kurduğu kişiler.</td>
              </tr>
              <tr>
                <td>Başvuru Formu</td>
                <td>Veri Sahibi’nin Kişisel Verilerin Korunması Hakkında Kanun’un 11. Maddesi uyarınca sahip olduğu hakları kullanması ve bu amaçla inidirimlikargo.com’a başvurabilmesi adına indirimlikargo.com tarafından hazırlanmış, Web Sitesi’nde yayınlanan ve ayrıca bu Politika’nın Ek-1’inde yer alan formu ifade eder.</td>
              </tr>
              <tr>
                <td>İlgili Kullanıcı</td>
                <td>Kişisel Verilerin teknik olarak depolanması, korunması ve yedeklenmesinden sorumlu olan kişi ya da birim hariç olmak üzere indirimlikargo.com organizasyonu içerisinde veya indirimlikargo.com’dan aldığı yetki ve talimat doğrultusunda Kişisel Verileri İşleyen kişileri ifade eder.</td>
              </tr>
              <tr>
                <td>Kurul</td>
                <td>Kurul Kişisel Verileri Koruma Kurulu’nu ifade eder.</td>
              </tr>
              <tr>
                <td>Kurum</td>
                <td>Kurul Kişisel Verileri Koruma Kurumu’nu ifade eder.</td>
              </tr>
              <tr>
                <td>Kişisel Verilerin İmha Edilmesi</td>
                <td>Kişisel verilerin silinmesi, yok edilmesi veya anonim hale getirilmesidir.</td>
              </tr>
              <tr>
                <td>Silme</td>
                <td>Kişisel verilerin ilgili kullanıcılar için hiçbir şekilde erişilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.</td>
              </tr>
              <tr>
                <td>Veri Sahibi</td>
                <td>Kişisel verisi işlenen gerçek kişiyi ifade eder.</td>
              </tr>
              <tr>
                <td>Web Sitesi</td>
                <td>indirimlikargo.com</td>
              </tr>
              <tr>
                <td>Yok Etme</td>
                <td>Kişisel verilerin hiç kimse tarafından hiçbir şekilde erişilemez, geri getirilemez ve tekrar kullanılamaz hale getirilmesi işlemidir.</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

      {/* New sections below the table */}



      <Row className="mt-5">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>2. POLİTİKANIN AMACI</h4>
          <p style={{ color: 'white' }}>
           Bu politikanın amacı, verileri Sanatkardan E-Ticaret İnternet Mağazacılık Sanayi Ticaret Limited Şirketi (‘’Şirket’’ veya ‘’Sanatkardan.com’’) tarafından veri sorumlusu sıfatı ile işlenen gerçek kişileri; Kişisel verilerin, işlenme, saklanma, korunma ve imha süreç, şekil ve amaçlarını, 6698 sayılı Kişisel Verilerin Korunması Kanunu’nun (‘’Kanun’’) düzenlediği şekilde yönetmektir. Bu bağlamda, Kanun’un 10. Maddesi gereğince veri sahiplerine haklarını ve haklarını kullanma yöntemlerini bildirmek amacıyla işbu Politika bilgilerinize sunulmuştur.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>3. POLİTİKANIN KAPSAMI</h4>
          <p style={{ color: 'white' }}>
           İşbu Politika, kişisel verilerin işlenme ve korunma şartlarını, Kanun'un belirlediği yönergeler uyarınca belirlemekte olup sanatkardan.com web sitesinde yayınlandığı tarihte yürürlüğe girecektir. Bu bağlamda Politika’nın kapsamı; ortaklarımızın, yetkililerimizin, müşterilerimizin çalışanlarının, ortaklarının, yetkililerinin, iş birliği içinde olduğumuz kurumların çalışanlarının, hissedarlarının, yetkililerinin ve üçüncü kişilerin işlenen tüm kişisel verilerine ilişkindir.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>4. POLİTİKANIN UYGULANMASI</h4>
          <p style={{ color: 'white' }}>
           4. POLİTİKANIN UYGULANMASI
Bu Politika, Şirket uygulamalarının ilgili mevzuat tarafından ortaya konulan kurallara göre düzenlenmesinden oluşturulmuştur. Başta Kanun olmak üzere yürürlükteki mevzuat ile bu politika hükümlerinin çelişmesi halinde güncel mevzuat hükümleri uygulanır.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5. KİŞİSEL VERİLERİN İŞLENMESİ FAALİYETİNE YÖNELİK PRENSİP VE KOŞULLAR</h4>
          <p style={{ color: 'white' }}>
           Şirketimiz kişisel verilerin işlenmesi konusunda, yasada öngörüldüğü şekilde kişisel veri ve özel nitelikli kişisel veri olarak sınıflandırılmakta, Kanun’un 4. Maddesine uygun olarak aşağıda belirtilen prensiplere göre işlemekte, güvenli bir şekilde muhafaza ederek ve üçüncü taraflara aktarabilmektedir.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A Kişisel Verilerin İşlenme Prensipleri</h4>
          <p style={{ color: 'white' }}>
           Şirketimiz Kanun’un 10. Maddesinde belirtildiği şekilde veri sahiplerini bilgilendirmekte, açık rıza alınması gerekli durumlarda veri sahiplerinin açık rızalarını talep ederek kişisel verileri aşağıdaki belirtilen prensiplere uygun olarak işlemektedir.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A.1 Hukuka ve Dürüstlük Kurallarına Uygun Olma</h4>
          <p style={{ color: 'white' }}>
           Şirketimiz, kişisel verilerin işlenmesinde hukuksal düzenlemelerle getirilen ilkeler ile genel güven ve dürüstlük kuralına uygun hareket etmektedir. Bu bağlamda veri işleme çalışmalarımızı yürütürken, kişisel veriler amacı dışında kullanılmamaktadır.
          </p>
        </Col>
      </Row>   
      
        <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A.2 Doğru ve Gerektiğinde Güncel Olma</h4>
          <p style={{ color: 'white' }}>
          Şirketimiz Kişisel verilerin doğru ve güncel şekilde muhafaza edilebilmesi adına düzenli şekilde takibat ve güncelleme çalışmalarına gereken özeni göstermektedir. Veri sahiplerinin bilgilerinin doğru ve güncelliğinin sağlanması amacıyla bu durumu temin edecek tüm iletişim kanalları açıktır
          </p>
        </Col>
      </Row>   
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A.3 Belirlilik ve Şeffaflık</h4>
          <p style={{ color: 'white' }}>
         Şirketimiz kişisel verileri Kanun’a uygun olarak sunduğumuz hizmetler kapsamında belirli ve meşru amaçlar için işlemekte, gerekli olan dışında veri işlememektedir.
          </p>
        </Col>
      </Row>
      
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A.4 İşlendikleri Amaçla Bağlantılı, Sınırlı ve Ölçülü Olma</h4>
          <p style={{ color: 'white' }}>
         Şirketimiz kişisel verileri ticari faaliyet konusu ile ilgili ve işinin devam edebilmesi için gerekli olan amaçla bağlantılı olacak şekilde sınırlı ve ölçülü şekilde işlemektedir. Bu sebeple Kişisel veriler belirli amaçlarına uygun şekilde işlenmekte ve amaç dışı verilerin işlenmesinden kaçınılmaktadır.
          </p>
        </Col>
      </Row>      
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.A.5 Sınırlı Süre</h4>
          <p style={{ color: 'white' }}>
        Şirketimiz kişisel verileri işlendikleri amaç için gerekli olan süre boyunca muhafaza eder. Kanun’da ilgili kişisel verinin saklanması için belirli bir süre öngörüldüyse, şirketimiz belirlenen süreye uyarak ilgili verileri siler, yok eder veya anonimleştirir.
          </p>
        </Col>
      </Row>  
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B Kişisel Verilerin İşlenme Koşulları</h4>
          <p style={{ color: 'white' }}>
        Kişisel veriler, Kanun’un 5. Maddesinde yer alan kişisel veri işleme şartlarından en az birinin haiz olması durumunda şirketimiz tarafından işlenmektedir.
          </p>
        </Col>
      </Row>   
        <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.1 Açık Rıza</h4>
          <p style={{ color: 'white' }}>
      Kişisel veri sahibinin açık rızası belirli bir konuya ilişkin, bilgilendirilmeye dayalı olarak ve özgür iradeyle açıklanmalıdır. Şirketimiz, kişisel veri sahibinin açık rızası sonrasında verilerinin işlenebilmesi adına, kullanıcılarından açık rıza almaktadır.
          </p>
        </Col>
      </Row> 
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.2 Kanunlarda Açıkca Ön Görülmesi</h4>
          <p style={{ color: 'white' }}>
      Veri sahibinin kişisel verileri, kanunda açıkça öngörülmesi halinde veri sahibinin açık rızası olmadan da hukuka uygun olarak işlenebilmektedir.
          </p>
        </Col>
      </Row>
     <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.3 Fiili İmkansızlık Sebebiyle Kişinin Açık Rızasının Alınamaması</h4>
          <p style={{ color: 'white' }}>
    Fiili imkansızlık nedeniyle rızasını açıklayamayacak durumda olan veya rızasına geçerlilik tanınmayacak olan kişinin kendisinin veya başka bir kişinin hayatı ya da beden bütünlüğünü korumak için kişisel verisinin işlenmesinin zorunlu olması halinde veri sahibinin kişisel verileri işlenebilmektedir.
          </p>
        </Col>
      </Row>  
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.4 Bir Sözleşmenin Kurulması Veya İfasıyla Doğrudan İlgisi Olması</h4>
          <p style={{ color: 'white' }}>
    Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması halinde kişisel verilerin işlenmesi mümkündür. 

 Örn; Yapılan sözleşme gereği ödeme yapılabilmesi için alacaklı tarafın banka ve hesap bilgilerinin alınması, mesafeli satış sözleşmesine konu ürünün teslimi için alıcının ad-soyad, adres bilgilerinin kargo firması ile paylaşılması.
          </p>
        </Col>
      </Row>  
        <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.5 Hukuki Yükümlülüğün Yerine Getirilmesi</h4>
          <p style={{ color: 'white' }}>
   Şirketimizin veri sorumlusu olarak hukuki yükümlülüklerini yerine getirmesi için işlenmenin zorunlu olması durumunda veri sahibinin kişisel verileri işlenebilmektedir
          </p>
        </Col>
      </Row>    
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.6 Veri Sahibinin Kişisel Verilerinin Alenileştirilmesi</h4>
          <p style={{ color: 'white' }}>
  Veri sahibinin, kişisel verisini kendisi tarafından alenileştirilmiş olması halinde ilgili kişisel veriler işlenebilecektir.
          </p>
        </Col>
      </Row>  
       <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.7 Bir Hakkın Tesisi Veya Korunması İçin Veri İşlemenin Zorunlu Olması</h4>
          <p style={{ color: 'white' }}>
Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması halinde veri sahibinin kişisel verileri işlenebilecektir.
          </p>
        </Col>
      </Row>  
      <Row className="mt-4">
        <Col>
          <h4 className="text-center" style={{ color: '#EFB02F' }}>5.B.8 Şirket'in Meşru Menfaati İçin Veri İşlemenin Zorunlu Olması</h4>
          <p style={{ color: 'white' }}>
Kişisel veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirketin meşru menfaatleri için veri işlemesinin zorunlu olması halinde veri sahibinin kişisel verileri işlenebilecektir.
          </p>
        </Col>
      </Row>   
        <Row className="mt-4">
          <Col>
            <h4 className="text-center" style={{ color: '#EFB02F' }}>6. ÖZEL NİTELİKLİ KİŞİSEL VERİLERİN İŞLENMESİ</h4>
            <p style={{ color: 'white' }}>
              Kişisel veri sahibinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, Şirketin meşru menfaatleri için veri işlemesinin zorunlu olması halinde veri sahibinin kişisel verileri işlenebilecektir.
              Şirketimiz Kanun ile ‘’Özel nitelikli olarak belirlenmiş kişisel verilerin işlenmesinde, Kanun’un belirlediği yönergelere titizlikle uygun davranmaktadır.
              <br /><br />
              Özel nitelikli kişisel veriler KVK Kurulu tarafından belirlenecek olan yeterli önlemlerin alınması kaydıyla aşağıdaki durumlarda işlenmektedir:
            </p>
            <ul style={{ color: 'white' }}>
              <li><strong>• Kişisel veri sahibinin açık rızası var ise veya</strong></li>
              <li><strong>• Kişisel veri sahibinin açık rızası yok ise;</strong></li>
              <li>
                <strong>• Kişisel veri sahibinin sağlığı ve cinsel hayatı dışındaki özel nitelikli kişisel veriler, kanunlarda öngörülen hallerde,</strong>
              </li>
              <li>
                <strong>• Kişisel veri sahibinin sağlığına ve cinsel hayatına ilişkin özel nitelikli kişisel verileri ise ancak kamu sağlığının korunması, koruyucu hekimlik, tıbbi teşhis, tedavi ve bakım hizmetlerinin yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve kuruluşlar tarafından işlenmektedir.</strong>
              </li>
            </ul>
          </Col>
        </Row>


{/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<         */}
    <Row className="mt-5">
  
        <Col>
               <h2 
            className="text-center font-weight-bold" 
            style={{ marginTop: '14px', color:'#EFB02F' }}
          >
          7.KİŞİSEL VERİLERİN İŞLENMESİ FAALİYETİNE YÖNELİK BİLGİLER
          </h2>
          <h4 className='mb-3 px-2' style={{ marginTop: '14px', color:'#EFB02F' }}>7.A Kişisel Veri Kategorileri</h4>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>VERİ KATEGORİSİ</th>
                <th>AÇIKLAMA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kimlik Bilgisi</td>
                <td>Ehliyet, nüfus cüzdanı, ikametgâh, pasaport, avukatlık kimliği, evlilik cüzdanı gibi dokümanlarda yer alan bilgiler (örn. TCKN, pasaport no., nüfus cüzdanı seri no., ad-doyad, fotoğraf, doğum yeri, doğum tarihi, yaş, nüfusa kayıtlı olduğu yer, avukuatlı nüfus cüzdanı örneği)</td>
              </tr>
              <tr>
                <td>İletişim Bilgisi </td>
                <td>Kişiyle iletişim kurulması amacıyla kullanılan bilgiler (örn. e-mail adresi, telefon numarası, cep telefonu numarası, adres).</td>
              </tr>
              <tr>
                <td>Lokasyon Verisi </td>
                <td>	
                Veri sahibinin konumunu tespit etmeye yarayan veriler (örn. araç kullanımı sırasında edinilen lokasyon verileri)</td>
              </tr>
              <tr>
                <td>Müşteri Bilgisi</td>
                <td>Ürün ve hizmetlerimizden faydalanan müşterilere ait bilgiler (örn. müşteri no, meslek bilgisi, vb.)</td>
              </tr> 
              <tr>
                <td>Müşteri İşlem Bilgisi</td>
                <td>Ürün ve hizmetlerimizden faydalanan müşteriler tarafından gerçekleştirilen her türlü işleme ilişkin bilgiler (örn. talep ve talimatlar, sipariş ve sepet bilgileri, vb.)</td>
              </tr> 
              <tr>
                <td>İşlem Güvenliği Bilgisi</td>
                <td>Şirketimiz ve ilgili tarafların teknik, idari, hukuki ve ticari güvenliğini sağlamak amacıyla işlenen kişisel veriler (örn. kişisel veri sahibiyle ilişkilendirilen işlem ile o kişiyi eşleştirmeye ve kişinin o işlemi yapmaya yetkili olduğunu gösteren Internet sitesi şifre ve parola gibi bilgiler)</td>
              </tr>
              {/* New rows for the additional terms */}
              <tr>
                <td>Risk Yönetimi Bilgisi </td>
                <td>Şirketimizin ticari, teknik ve idari risklerini yönetebilmek için işlenen kişisel veriler (örn. IP adresi, Mac ID vb. kayıtlar).</td>
              </tr>
              <tr>
                <td>Finansal Bilgi</td>
                <td>Kişisel veri sahibi ile mevcut hukuki ilişkinin tipine göre yaratılan her türlü finansal sonucu gösteren bilgi, belge ve kayıtlar kapsamındaki kişisel veriler (Örneğin: veri sahibinin yapmış olduğu işlemlerin finansal sonucunu gösteren bilgiler, kart bilgisi, ödenecek faiz tutarı ve oranı, borç bakiyesi, alacak bakiyesi vb.)</td>
              </tr>
              <tr>
                <td>Özlük Bilgisi</td>
                <td>	
               Şirket’in tedarikçilerinin çalışanlarının özlük haklarının oluşmasına temel olan kişisel veriler (kanunen özlük dosyasına girmesi gereken her türlü bilgi ve belge)</td>
              </tr>
              <tr>
                <td>Çalışan Adayı Bilgisi</td>
                <td>Şirketimiz nezdinde iş başvurusu yapmak üzere bilgilerini paylaşan veri sahiplerine ait, başvuru değerlendirme sürecinde kullanılan kişisel veriler (örn. özgeçmiş, mülakat notları, kişilik testleri sonuçları vb.)</td>
              </tr>
              <tr>
                <td>Çalışan İşlem Bilgisi</td>
                <td>Şirket’in tedarikçi çalışanlarının işle ilgili gerçekleştirdiği her türlü işleme ilişkin kişisel veriler (örn. işe giriş-çıkış kayıtları, iş seyahatleri, katıldığı toplantılara ilişkin bilgiler, güvenlik sorgusu, e-posta trafikleri izleme bilgisi, araç kullanım bilgisi, şirket kartı harcama bilgisi</td>
              </tr>
              <tr>
                <td>Çalışan Performans ve Kariyer Gelişim Bilgisi</td>
                <td>Şirket’in tedarikçi çalışanlarının performanslarının ölçülmesi ve kariyer gelişimlerinin insan kaynakları politikaları kapsamında planlanması ve yürütülmesi amacıyla işlenen kişisel veriler (örn. performans değerlendirme raporları, mülakat sonuçları, kariyer gelişimine yönelik eğitimler)</td>
              </tr>
             <tr>
                <td>Yan Haklar ve Menfaatler Bilgisi</td>
                <td>Şirket’in tedarikçi çalışanlarına sunulan yan hak ve menfaatlerinin takibinin yapılması ve tedarikçi çalışanlarının bunlardan faydalandırılmasına yönelik işlenen kişisel veriler (örn. özel sağlık sigortası, araç tahsisi)</td>
              </tr>
             <tr>
                <td>Pazarlama Bilgisi</td>
                <td>Şirketimiz tarafından pazarlama faaliyetlerinde kullanılacak veriler (örn. pazarlama amacıyla kullanılmak üzere toplanan kişinin alışkanlıkları, beğenilerini gösteren raporlar ve değerlendirmeler, hedefleme bilgileri, cookie kayıtları, veri zenginleştirme faaliyetleri)</td>
              </tr>
             <tr>
                <td>Hukuki İşlem ve Uyum Bilgis</td>
                <td>Hukuki alacak ve hakların tespiti ve takibi ile borç ve kanuni yükümlülüklerin ifası amacıyla işlenen kişisel veriler (örn. mahkeme ve idari merci kararı gibi belgelerde yer alan veriler)</td>
              </tr>
             <tr>
                <td>Denetim ve Teftiş Bilgisi</td>
                <td>Şirketimizin kanuni yükümlülükleri ve şirket politikalarına uyumu kapsamında işlenen kişisel veriler (örn. denetim ve teftiş raporları, ilgili görüşme kayıtları ve benzeri kayıtlar)</td>
              </tr>
             <tr>
                <td>Özel Nitelikli Kişisel Veri</td>
                <td>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri </td>
              </tr>
             <tr>
                <td>Talep/Şikayet Yönetimi Bilgisi</td>
                <td>Şirketimize yöneltilmiş olan her türlü talep veya şikayetin alınması ve değerlendirilmesine ilişkin kişisel veriler (örn. Şirket’e yönelik talep ve şikayetler, bunlarla ilgili kayıt ve raporlar)</td>
              </tr>
             <tr>
                <td>Görsel ve İşitsel Veri</td>
                <td>Kişisel veri sahibiyle ilişkilendirilen görsel ve işitsel kayıtlar (örn. fotoğraflar, kamera kayıtları ve ses kayıtları)</td>
              </tr>
          
            
            </tbody>
          </Table>
        </Col>
      </Row>


{/* <<<<<<<<<<<<<<<<<<<<<<7b<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< */}

 <Row className="mt-5">
  
        <Col>
       
          <h4 className='mb-3 px-2' style={{ marginTop: '14px', color:'#EFB02F' }}>7.B. VERİ SAHİBİ KATEGORİLERİ</h4>
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>VERİ KATEGORİSİ</th>
                <th>AÇIKLAMA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Başvuran</td>
                <td>	
Kişisel verilere ilişkin olanlar dahil, yazılı, sözlü veya elektronik olarak Şirketimize başvurarak soru, talep, öneri, şikayet, başvuruları ileten gerçek kişileri ifade eder. Bu kategorizasyonda belirtilen diğer veri sahipleri de başvuran olabilir. </td>
              </tr>
               <tr>
                <td>Çalışan</td>
                <td>Bir iş sözleşmesine bağlı olsun olmasın şirketimizin bordrosunda çalışan kişiler ile staj (zorunlu/isteğe bağlı) eğitimi gören öğrencileri/mezunları ifade eder </td>
              </tr>
               <tr>
                <td>Gerçek Kişi Müşteri</td>
                <td> Bir sözleşmeye bağlı olsun olmasın şirketimizin tarafından sunulan ürün, servis veya hizmetlerden faydalanan veya faydalanması değerlendirilen ya da görüşülen gerçek kişileri ve şahıs şirketlerini ifade eder.</td>
              </tr>
               <tr>
                <td>Gerçek Kişi Tedarikçi</td>
                <td> Şirketimiz tarafından ürün, servis ve/veya hizmetlerin sunulabilmesi için gerekli bir mal veya hizmeti sağlayan gerçek kişileri ve şahıs şirketlerini ifade eder.</td>
              </tr>
               <tr>
                <td>Hizmet Alınan Gerçek Kişi Firmalar</td>
                <td>Bir sözleşme kapsamında olsun olmasın, Şirketimize hizmet sunan, gerçek kişileri ve şahıs şirketlerini tanımlar. Alt yükleniciler de bu kapsamda değerlendirilir. </td>
              </tr>
               <tr>
                <td>İş birliği Yapılan Gerçek Kişi Firma</td>
                <td>Şirketimiz ile belli bir işin yapılmasını birlikte üstlenen ve bu iş sonucu kazancın paylaşıldığı gerçek kişileri ve şahıs şirketlerini ifade eder. </td>
              </tr>
               <tr>
                <td>İş Birliği Yapılan Firma İlgilileri</td>
                <td>Şirketimizin iş birliği yaptığı gerçek veya tüzel kişilerin, pay sahibi/ortaklarını, yetkililerini ve çalışanlarını ifade eder. </td>
              </tr>
               <tr>
                <td>Potansiyel Çalışan</td>
                <td>Şirketimiz bünyesinde istihdam edilmek veya stajını (zorunlu/isteğe bağlı) yapmak üzere Şirketimize özgeçmişini ileten kişileri ifade eder </td>
              </tr>
               <tr>
                <td>Tedarikçi İlgilileri</td>
                <td>	
Şirketimizin gerçek veya tüzel kişi tedarikçilerinin pay sahibi/ortaklarını, yetkililerini ve çalışanlarını ifade eder </td>
              </tr>
               <tr>
                <td>Uygulama Kullanıcısı</td>
                <td>Şirketimiz tarafından geliştirilen ve kullanıma sunulan Uygulamaları mobil işletim sistemli cihazına indiren/kullanan gerçek kişileri ifade eder </td>
              </tr>
               <tr>
                <td>Ziyaretçi</td>
                <td>Şirketimizin Web Sitesi’ni üye olsun olmasın kullanan, verilerini buraya kaydeden, Web Sitesi üzerinden verilerini sunan veya verileri Web Sitesi kullanım koşullarına uygun olarak toplanan gerçek kişi ziyaretçileri ifade eder. Bu kategorizasyonda belirtilen diğer veri sahipleri de Web Sitesi ziyaretçisi olabilir </td>
              </tr>
             
          
            
            </tbody>
          </Table>
        </Col>
      </Row>


      <Row className="mt-5">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>7.C Kişisel Verilerin Toplanması</h4>
    <p style={{ color: 'white' }}>
      Şirketimiz Kanun’un ve ilgili sair mevzuatın düzenlemelerine uygun olarak, aşağıda belirtilen yöntemlerle veri toplamaktadır:
    </p>
    <ul style={{ color: 'white' }}>
      <li>
        <strong>Hizmetlerimizi kullandığınızda veya hesap oluşturduğunuzda bize sağladığınız kişisel veriler:</strong>
        <ul>
          <li>• Hesap oluşturduğunuzda; adınız, telefon numaranız ve e-posta adresiniz gibi tanımlayıcı bilgileriniz.</li>
          <li>• Bir işlemin gerçekleştirilmesi sırasında alım veya satımla ilgili olarak paylaştığınız bilgiler ile işlemle ilgili olarak ürettiğiniz diğer içerikler veya işleme taraf olmanız nedeniyle hesabınızla bağlantılı olarak ortaya çıkan içerikler. Ürettiğiniz veya hesabınız ile bağlantılı, ürünlerin alışveriş sepetinize veya favorilerime eklenmesi, satıcı takip edilmesi gibi diğer içerikler.</li>
          <li>• İşlemleriniz ile bağlantılı, banka hesap numaraları gibi finansal bilgiler.</li>
          <li>• Ürünlerin satın alınması veya listelenmesi dâhil ürünün satılması amacıyla kullanılan e-posta ve telefon bilgileri, vergi kimlik numarası ve diğer kimlik numaraları dâhil fatura bilgileri, kargo/posta işlemleri için gerekli olan takip numaraları ve güncel takip bilgileri gibi bilgiler.</li>
          <li>• Bazı hallerde, kullanıcı hesabı oluştururken veya Hizmetlerimizi kullanırken sağladığınız yaş, cinsiyet, ilgi alanlarınız ve beğendikleriniz ile ilgili bilgiler. (Örneğin, Facebook hesabınız ile kaydolmuşsanız, Facebook'un bizimle paylaşmasına izin verdiğiniz profil bilgileriniz, arkadaş listeniz, doğum gününüz, beğenileriniz ve e-posta adresiniz de dahildir).</li>
          <li>• Sanatkardan.com’da yer alan formlar aracılığıyla, Site üyeliği için oluşturduğunuz kullanıcı hesabınıza ilişkin bilgilerinizi güncelleyerek veya bilgi ekleyerek, uyuşmazlık çözümüne katılarak veya ürün ve/veya hizmet satın aldığınızda veya sattığınızda veya Hizmetlerimiz ile ilgili olarak bizimle başka şekilde iletişime geçerek sunmuş olduğunuz diğer bilgiler ve bu kapsamda oluşan kullanıcılar dâhil yapılan her türlü görüşme/yazışma içerikleri ile bunlara ilişkin kayıtlar.</li>
          <li>• Kimliğinizi doğrulayabilmek veya tespit edebilmek için yürürlükteki kanunlar uyarınca toplamamız ve işlememiz gereken veya buna izin verilen diğer bilgiler.</li>
          <li>• Bazı hallerde, hukuka aykırı, tehditkâr, hakaret içeren ve sair istenmeyen mesajlar alındığına ilişkin kullanıcılarımızdan veya üçüncü kişilerden gelen şikâyetleri değerlendirebilmek amacıyla incelediğimiz mesajlaşma ve diğer yazışma içerikleri.</li>
        </ul>
      </li>
      <li>
        <strong>Hizmetlerimizi kullandığınızda veya hesap oluşturduğunuzda otomatik olarak topladığımız kişisel veriler:</strong>
        <ul>
          <li>• Hizmetlerimizi kullanımınıza ve reklam tercihlerinize ilişkin konular ile bizimle iletişim kurduğunuz konularda bilgi toplamaktayız. Bu bilgiler, mobil cihazlar da dâhil olmak üzere Hizmetlerimize ulaşmak amacıyla kullandığınız cihazlardan elde ettiğimiz bilgilerdir. Bu bilgiler, cihaz kimliği veya benzersiz tanıtıcı, cihaz türü, reklam tanımlayıcı ve benzersiz cihaz simgesinden oluşmaktadır.</li>
          <li>• Mobil cihazınızdan elde edilen lokasyon bilgileri dâhil olmak üzere coğrafi konum bilgileri. Mobil cihazların çoğunun, mobil cihazın cihaz ayarlar menüsündeki bulunan bir uygulama ile lokasyon hizmetlerini kontrol etmenize veya kullanımını devre dışı bırakmanıza olanak sağladığını hatırlatmak isteriz.</li>
          <li>• Sayfa görüntüleme istatistikleri, sayfa gelen ve giden trafik bilgileri, yönlendiren URL, reklam verileri, IP adresiniz, göz atma geçmişiniz ve web log bilgileriniz gibi bilgisayar ve bağlantı bilgileri.</li>
        </ul>
      </li>
      <li>
        <strong>Çerezler, web işaretçilerini kullanarak topladığımız kişisel bilgiler:</strong>
        <ul>
          <li>• Hizmetlerimizi kullanımınız kapsamında ve reklam ve e-posta içeriğimiz aracılığıyla, görüntülediğiniz sayfalar, tıkladığınız bağlantılar ve diğer işlemleriniz hakkında bilgi toplamak için çerez, web işaretçileri (beacons), benzersiz tanımlayıcı ve benzer teknolojiler kullanmaktayız.</li>
          <li>• Bu teknolojileri kullanımımız ve bunları nasıl kontrol edebileceğiniz hakkında daha ayrıntılı bilgi için Çerezler Politikamızı inceleyiniz.</li>
        </ul>
      </li>
      <li>
        <strong>Diğer kaynaklardan topladığımız kişisel veriler:</strong>
        <ul>
          <li>• Topladığımız verileri, üçüncü kişilerden aldığımız veriler ile birleştirerek hesap bilgilerinize ekleyebiliriz. Örneğin, herkese açık olan demografik ve diğer bilgileri, ek iletişim bilgilerini, kredi bilgilerini ve kredi kayıt bürosu ve benzeri kuruluşlardan alınan bilgileri kullanabiliriz.</li>
          <li>• Sosyal medya siteleri ile bilgi paylaşımınıza izin verdiğinizde, bu sosyal medya siteleri bize (görüntülediğiniz içerik, beğendiğiniz içerik ve size gösterilen veya tıkladığınız reklamlar hakkında bilgiler gibi) otomatik erişim sağlayabilir.</li>
          <li>• Sosyal medya sitesi tarafından yönetilen bir hesabı, Sanatkardan.com’daki hesabınız ile ilişkilendirerek, bu sosyal medya siteleri tarafından sunulan bilgileri Gizlilik Bildirimi uyarınca kullanabileceğimizi kabul etmiş olursunuz.</li>
          <li>• Başkalarına ait kişisel verileri (başka bir kişiye ait olan teslim adresi, işlemler için kullandığınız başka bir kartın hamiline ilişkin bilgiler ve benzeri bilgiler), yalnızca söz konusu kişilerden önceden alınmış açık rızaları varsa bizimle paylaşabilirsiniz. Bu kişileri aynı zamanda, Gizlilik Bildirimimiz uyarınca nasıl bilgi topladığımız, kullandığımız, paylaştığımız ve muhafaza ettiğimiz konularında bilgilendirmelisiniz.</li>
        </ul>
      </li>
    </ul>
  </Col>
</Row>

<Row className="mt-5">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin İşlenme Amaçları</h4>
    <p style={{ color: 'white' }}>
      Şirketimiz, Kişisel Verileri Kanun’un öngördüğü şekilde belli, açık ve meşru amaçlarla işlemektedir. Bu kapsamda Kişisel Veriler aşağıda sayılan amaçlarla işlenebilmektedir:
    </p>
    <ul style={{ color: 'white' }}>
      <li>• Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası</li>
      <li>• Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi</li>
      <li>• Çalışanların iş faaliyetlerinin takibi ve/veya denetimi</li>
      <li>• Çalışanların bilgiye erişim yetkilerinin planlanması ve icrası</li>
      <li>• Finans ve/veya muhasebe işlerinin takibi</li>
      <li>• Hukuk işlerinin takibi</li>
      <li>• İnsan kaynakları süreçlerinin planlanması</li>
      <li>• İş faaliyetlerinin etkinlik/verimlilik ve/veya yerindelik analizlerinin gerçekleştirilmesi faaliyetlerinin planlanması ve/veya icrası</li>
      <li>• İş faaliyetlerinin planlanması ve icrası</li>
      <li>• İş ortakları ve/veya tedarikçilerin bilgiye erişim yetkilerinin planlanması ve icrası</li>
      <li>• İş ortakları ve/veya tedarikçilerle olan ilişkilerin yönetimi</li>
      <li>• İş sağlığı ve/veya güvenliği süreçlerinin planlanması ve/veya icrası</li>
      <li>• İş sürekliliğinin sağlanması faaliyetlerinin planlanması ve/veya icrası</li>
      <li>• Lojistik faaliyetlerinin planlanması ve icrası</li>
      <li>• Müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası</li>
      <li>• Müşteri memnuniyeti aktivitelerinin planlanması ve/veya icrası</li>
      <li>• Müşteri talep ve/veya şikayetlerinin takibi</li>
      <li>• Personel temin süreçlerinin yürütülmesi</li>
      <li>• Şirket çalışanları için iş akdi ve/veya mevzuattan kaynaklı yükümlülüklerin yerine getirilmesi</li>
      <li>• Şirket dışı eğitim faaliyetlerinin planlanması ve icrası</li>
      <li>• Şirket faaliyetlerinin şirket prosedürleri ve/veya ilgili mevzuata uygun olarak yürütülmesinin temini için gerekli operasyonel faaliyetlerinin planlanması ve icrası</li>
      <li>• Şirket içi eğitim faaliyetlerinin planlanması ve/veya icrası</li>
      <li>• Şirket içi oryantasyon aktivitelerinin planlanması ve icrası</li>
      <li>• Şirketin sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması ve/veya icrası</li>
      <li>• Sözleşme süreçlerinin ve/veya hukuki taleplerin takibi</li>
      <li>• Stratejik planlama faaliyetlerinin icrası</li>
      <li>• Tedarik zinciri yönetimi süreçlerinin planlanması ve icrası</li>
      <li>• Ücret yönetimi</li>
      <li>• Üretim ve/veya operasyon süreçlerinin planlanması ve icrası</li>
      <li>• Ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması faaliyetlerinin planlanması ve icrası</li>
      <li>• Ürün ve/veya hizmetlerin pazarlama süreçlerinin planlanması ve icrası</li>
      <li>• Ürün ve/veya hizmetlerin satış süreçlerinin planlanması ve icrası</li>
      <li>• Verilerin doğru ve güncel olmasının sağlanması</li>
      <li>• Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi</li>
      <li>• Ziyaretçi kayıtlarının oluşturulması ve takibi</li>
    </ul>
  </Col>
</Row>


<Row className="mt-5">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin Aktarılması</h4>
    <ul style={{ color: 'white' }}>
      <li>
        <strong  style={{ color: '#EFB02F' }}>Kişisel Verilerin Yurt İçine Aktarılması:</strong> 
        <p>Kanun’un 8. maddesi uyarınca Kişisel Veriler kural olarak, Veri Sahibinin açık rızası olmaksızın üçüncü kişilere aktarılamaz.</p>
        <p>Ancak Politika’nın 5. Maddesinde belirtilen veri işleme şartlarından en az birinin varlığı halinde ve veri işleme şartlarına ilişkin temel ilkelere uymak şartıyla kişisel verileriniz Şirketimiz tarafından aktarılabilmektedir.</p>
      </li>

      <li>
        <strong  style={{ color: '#EFB02F' }}>Kişisel Verilerin Yurt Dışına Aktarılması:</strong> 
        <p>Kanun’un 9. maddesi uyarınca Kişisel Veriler kural olarak, Veri Sahibinin açık rızası olmaksızın yurt dışına aktarılamaz.</p>
        <p>Ancak aşağıda belirtilen hallerden birinin mevcut olması halinde Kişisel Verilerin, Veri Sahibinin açık rızası aranmaksızın yurt içinde üçüncü kişilere aktarımı mümkündür:</p>
        <ul>
          <li>• Bu Politika’nın 5.maddesinde belirtilen, Veri Sahibinin rızasının aranmayacağının belirtildiği hallerden birinin mevcut olması,</li>
          <li>• Kişisel Verilerin aktarılacağı yabancı ülkede yeterli korumanın bulunması,</li>
          <li>• Yeterli korumanın bulunmaması durumunda Türkiye'deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması.</li>
        </ul>
        <p>Yeterli korumanın bulunduğu ülkeler Kurulca belirlenerek ilan edilir.</p>
        <p>Kişisel Veriler, uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye'nin veya Veri Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, ancak ilgili kamu kurum veya kuruluşunun görüşü alınarak Kurulun izniyle yurt dışına aktarılabilir.</p>
      </li>

      <li>
        <strong  style={{ color: '#EFB02F' }}>Kişisel Verilerin Aktarılabileceği 3.Kişiler:</strong>
        <p>Şirketimiz, bu Politika’nın 8. maddesinde belirtilen amaçlarını gerçekleştirmek için, Kanun’un 8. ve 9. maddelerine uygun olarak, yurt içinde veya yurtdışındaki, gerçek veya tüzel kişi olabilecek aşağıda belirtilen üçüncü kişilere veri aktarabilmektedir:</p>
        <ul>
          <li>• Danışmanlar</li>
          <li>• Denetim Firmaları</li>
          <li>• Hizmet Alınan Firmalar</li>
          <li>• İş birliği Yapılan Firmalar</li>
          <li>• Müşteriler</li>
          <li>• Pay Sahipleri</li>
          <li>• Tedarikçiler</li>
          <li>• Bankalar ve Finans Kuruluşları</li>
          <li>• Yargısal Merciler ve Kamu Otoriterleri</li>
        </ul>
      </li>

      <li>
        <h4 style={{ color: '#EFB02F' }}>10.A Veri Sahibinin Hakları ve Bu Hakların Kullanılması</h4>
        <p>Veri Sahibi, Kanun’un 11. maddesi kapsamında aşağıdaki haklara sahiptir:</p>
        <ul>
          <li>• Kişisel verilerin işlenip işlenmediğini öğrenme,</li>
          <li>• Kişisel veriler işlenmişse buna ilişkin bilgi talep etme,</li>
          <li>• Kişisel verilerin işlenme amacını ve amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>• Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
          <li>• Kişisel verilerin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme,</li>
          <li>• Kişisel verilerin işlenmesini gerektiren sebeplerin ortadan kalkması halinde silinmesini veya yok edilmesini isteme,</li>
          <li>• İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
          <li>• Kişisel verilerin kanuna aykırı işlenmesi sebebiyle zarara uğraması halinde zararın giderilmesini talep etme.</li>
        </ul>
        <p>Veri Sahibi bu haklarına ilişkin taleplerini, Şirket’in ilgili iletişim kanalları üzerinden iletebilir.</p>
      </li>
    </ul>
  </Col>
</Row>

<Row className="mt-5">
  <Col>
    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>10.B Veri Sahibinin Haklarını İleri Süremeyeceği Durumlar</h4>
    <p style={{ color: 'white' }}>
      Kişisel veri sahipleri, KVK Kanunu’nun 28. maddesi gereğince aşağıdaki haller KVK Kanunu kapsamı dışında tutulduğundan, bu konularda 5.1’de sayılan haklarını ileri süremezler:
    </p>
    <ul style={{ color: 'white' }}>
      <li>• Kişisel verilerin, üçüncü kişilere verilmemek ve veri güvenliğine ilişkin yükümlülüklere uyulmak kaydıyla gerçek kişiler tarafından tamamen kendisiyle veya aynı konutta yaşayan aile fertleriyle ilgili faaliyetler kapsamında işlenmesi.</li>
      <li>• Kişisel verilerin resmi istatistik ile anonim hâle getirilmek suretiyle araştırma, planlama ve istatistik gibi amaçlarla işlenmesi.</li>
      <li>• Kişisel verilerin milli savunmayı, milli güvenliği, kamu güvenliğini, kamu düzenini, ekonomik güvenliği, özel hayatın gizliliğini veya kişilik haklarını ihlal etmemek ya da suç teşkil etmemek kaydıyla sanat, tarih, edebiyat veya bilimsel amaçlarla ya da ifade özgürlüğü kapsamında işlenmesi.</li>
      <li>• Kişisel verilerin milli savunmayı, milli güvenliği, kamu güvenliğini, kamu düzenini veya ekonomik güvenliği sağlamaya yönelik olarak kanunla görev ve yetki verilmiş kamu kurum ve kuruluşları tarafından yürütülen önleyici, koruyucu ve istihbari faaliyetler kapsamında işlenmesi.</li>
      <li>• Kişisel verilerin soruşturma, kovuşturma, yargılama veya infaz işlemlerine ilişkin olarak yargı makamları veya infaz mercileri tarafından işlenmesi.</li>
    </ul>

    <p style={{ color: 'white' }}>
      KVK Kanunu’nun 28.2. maddesi gereğince; aşağıda sıralanan hallerde kişisel veri sahipleri zararın giderilmesini talep etme hakkı hariç, 5.1’de sayılan diğer haklarını ileri süremezler:
    </p>
    <ul style={{ color: 'white' }}>
      <li>• Kişisel veri işlemenin suç işlenmesinin önlenmesi veya suç soruşturması için gerekli olması.</li>
      <li>• Kişisel veri sahibinin kendisi tarafından alenileştirilmiş kişisel verilerin işlenmesi.</li>
      <li>• Kişisel veri işlemenin kanunun verdiği yetkiye dayanılarak görevli ve yetkili kamu kurum ve kuruluşları ile kamu kurumu niteliğindeki meslek kuruluşlarınca, denetleme veya düzenleme görevlerinin yürütülmesi ile disiplin soruşturma veya kovuşturması için gerekli olması.</li>
      <li>• Kişisel veri işlemenin bütçe, vergi ve mali konulara ilişkin olarak Devletin ekonomik ve mali çıkarlarının korunması için gerekli olması.</li>
    </ul>

    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>10.C Veri Sahibinin Haklarını Kullanma Usulleri</h4>
    <p style={{ color: 'white' }}>
      Veri Sahibi, bu Politika’nın 10.A maddesinde belirtilen haklarını, Ek-1’de yer alan başvuru formunu doldurup, ıslak imzalı olarak, noter aracılığıyla
      “Kızılırmak Mahallesi Dumlupınar Bulvarı, Next Level, 3A/81 Çankaya / Ankara” adresine göndermek veya aynı adrese bizzat ya da vekili aracılığıyla elden ulaştırmak suretiyle kullanabilir. Kimliğini tevsik edici belgelerin, varsa talebi destekleyici belgelerin, Veri Sahibinin bu hakkını vekili aracılığıyla kullanmak istemesi durumunda, bu konuda özel yetkiyi içeren vekaletname suretinin formun ekinde Şirketimize iletilmesi zorunludur.
    </p>

    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>10.D Hak Sahibi Başvurusunun Şirket Tarafından Değerlendirilmesi</h4>
    <p style={{ color: 'white' }}>
      Başvuruda; bilgilerin eksik veya yanlış paylaşılması, talebin açık ve anlaşılır bir şekilde dile getirilmemiş olması, talebi destekleyici nitelikteki belgelerin hiç veya gereği gibi iletilmemesi, vekil suretiyle yapılan başvurularda vekaletname suretinin eklenmemesi gibi durumlarda şirketimiz, talepleri karşılamakta güçlük yaşayabilecek ve araştırma sürecinde gecikmeler yaşanabilecektir. Bu nedenle Kanun’un 11. Maddesinde belirtilen hakları kullanımında bu hususlara riayet edilmesi önem arz etmektedir. Aksi durumda yaşanacak gecikmelerden Şirketimiz sorumlu tutulamayacaktır. Hatalı, gerçeğe/hukuka aykırı ve/veya kötü niyetli başvurular karşısında Şirketimizin yasal hakları saklıdır.
    </p>
    <p style={{ color: 'white' }}>
      Başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya süresinde başvuruya cevap verilmemesi hâllerinde; Veri Sahibi, Sanatkardan.com’un cevabını öğrendiği tarihten itibaren otuz ve herhâlde başvuru tarihinden itibaren altmış gün içinde Kurula şikâyette bulunma hakkına sahiptir.
    </p>

    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>11. Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi</h4>
    <p style={{ color: 'white' }}>
      Türk Ceza Kanunu’nun 138. maddesinde ve KVK Kanunu’nun 7. maddesinde düzenlendiği üzere ilgili kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde Şirketimizin kararına istinaden veya kişisel veri sahibinin talebi üzerine kişisel veriler silinir, yok edilir veya anonim hâle getirilir. Bu kapsamda şirketimiz ilgili yükümlülüğünü yerine getirmek üzere Şirket içerisinde gerekli teknik ve idari tedbirleri alarak; bu konuda gerekli işleyiş mekanizmaları geliştirmiş olup; bu yükümlülüklerine uygun davranmak üzere ilgili iş birimlerini eğitmekte, görevlendirme ve farkındalıklarını sağlamaktadır.
    </p>

    <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>12. Politikanın Yürürlüğü</h4>
    <p style={{ color: 'white' }}>
      Şirketimiz tarafından düzenlenen bu Politika web sitesinde yayınlandığı tarihten itibaren geçerli olur. Başta Kanun olmak üzere yürürlükteki mevzuat ile bu Politika’da yer verilen düzenlemelerin çelişmesi halinde güncel mevzuat hükümleri uygulanır.
    </p>
    <p style={{ color: 'white' }}>
      Şirketimiz, Kanuna uygun olmak kaydı ile Politika’da değişiklik yapma hakkını saklı tutar. İşbu politika’da güncelleme yapılması durumunda, tüm üyelerimize bilgilendirme yapılacaktır. Politikanın güncel haline Web sitesinden (sanatkardan.com) erişilebilirsiniz.
    </p>
  </Col>
</Row>
<Row className="mt-5">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>EK-1</h4>
          <p style={{ color: 'white' }}>
            <strong>Ön Bilgilendirme Formu</strong>
          </p>
          
        </Col>
      </Row>

      {/* Ön Bilgilendirme Formu indirme bağlantısı */}
      <Row className="mt-4">
       <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Ön Bilgilendirme Formu İndir</h4>
        <a
  href="/pdf/önbilgilendirme.pdf"  // public/pdf klasörüne doğru erişim
  download="önbilgilendirme.pdf"
  onClick={handleDownload}
  className="btn btn-warning"
>
  Ön Bilgilendirme Formu İndir
</a>

        </Col>
      </Row>
      <Footer/>

      {/* Continue with other sections as needed */}
    </Container>
  );
};

export default Kkvp;
