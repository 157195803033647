import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';

const AddProducts = ({ show, handleClose, onProductAdded, onProductUpdated, initialData }) => {
    const userId = useSelector((state) => state.user.userId);
    const [formData, setFormData] = useState({
        name: '',
        price: '',
    });

  const [errors, setErrors] = useState({
    name: '',
    price: '',
  });

  const [touched, setTouched] = useState({
    name: false,
    price: false,
  });

  // useEffect ile initialData değiştiğinde formu doldur
  useEffect(() => {
    if (initialData) {
      setFormData({
        name: initialData.name,
        price: initialData.price.replace(" TL", "").trim(), // Fiyatın sayısal olmasını sağlıyoruz
      });
    } else {
      // Yeni ürün ekleniyorsa formu sıfırla
      setFormData({
        name: '',
        price: '',
      });
    }
  }, [initialData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (touched[name]) {
      validateInput(name, value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setTouched({ ...touched, [name]: true });
    validateInput(name, value);

    // Fiyat alanında iki ondalık haneli görüntüleme
    if (name === 'price' && value) {
      const formattedValue = parseFloat(value).toFixed(2); // İki ondalık haneli format
      setFormData((prevData) => ({ ...prevData, price: formattedValue }));
    }
  };

  const validateInput = (name, value) => {
    let error = '';
    if (name === 'name' && value.length < 3) {
      error = 'Ürün adı en az 3 karakter olmalıdır.';
    }
    if (name === 'price' && (isNaN(value) || value <= 0)) {
      error = 'Ürün fiyatı pozitif bir sayı olmalıdır.';
    }
    setErrors({ ...errors, [name]: error });
  };
const onSubmit = async (e) => {
    e.preventDefault();

    // Geçerli bilgiler olup olmadığını kontrol edin
    if (errors.name || errors.price || !formData.name || !formData.price) {
        alert('Lütfen geçerli bilgileri girin.');
        return;
    }

    const priceInTL = `${formData.price} TL`; // Fiyatı uygun formatta gönderin

    try {
        let response;

        // Mevcut ürünü güncelleyin veya yeni ürün ekleyin
        if (initialData) {
            response = await axios.put(`${process.env.REACT_APP_API_URL}/products/${initialData._id}`, {
                name: formData.name,
                price: priceInTL,
                userId: userId,
            }, { withCredentials: true });
        } else {
            response = await axios.post(`${process.env.REACT_APP_API_URL}/products`, {
                name: formData.name,
                price: priceInTL,
                userId: userId,
            }, { withCredentials: true });
        }

        if (response.status === 200 || response.status === 201) {
            alert(initialData ? 'Ürün başarıyla güncellendi!' : 'Ürün başarıyla eklendi!');
            onProductAdded(response.data); // Güncellenen ürünü geri gönderin
            handleClose(); // Offcanvas'ı kapatın
        }
    } catch (error) {
        console.error('Ürün eklenirken bir hata oluştu:', error);
        if (error.response && error.response.data.msg) {
            alert(error.response.data.msg);
        } else {
            alert('Ürün eklenemedi, lütfen tekrar deneyin.');
        }
    }
};


  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ backgroundColor: '#f0f0f0' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-center w-100" style={{ color: 'white' }}>
          {initialData ? 'ÜRÜN DÜZENLE' : 'YENİ ÜRÜN EKLE'}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
        <Form onSubmit={onSubmit} className="w-100">
          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={10} md={6} lg={5} className="mb-3">
              <Form.Group controlId="name">
                <Form.Label>* Ürün Adı</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  required
                  isInvalid={!!errors.name && touched.name}
                  style={{ textAlign: 'center', width: '100%' }} // Genişlik %100
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={10} md={6} lg={5} className="mb-3">
              <Form.Group controlId="price">
                <Form.Label>* Ürün Fiyatı (₺)</Form.Label>
                <Form.Control
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                  required
                  isInvalid={!!errors.price && touched.price}
                  style={{ textAlign: 'center', width: '100%' }} // Genişlik %100
                />
                <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Button 
            variant="warning" 
            type="submit" 
            className="mt-3" 
            style={{ width: '150px', margin: '0 auto', borderRadius: '25px', padding: '10px', display: 'block' }}>
            {initialData ? 'DÜZENLE' : 'KAYDET'} {/* Düzenleme moduna göre buton metni */}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddProducts;
