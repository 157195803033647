import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Card, Button, Dropdown, Form, Offcanvas } from 'react-bootstrap';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'; // Kütüphane stillerini dahil et
import './customer.css'


const Customer = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const userId = useSelector((state) => state.user.userId);
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [show, setShow] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newCustomer, setNewCustomer] = useState({
    name: '',
    surname: '',
    phone: '',
    mail: '',
    address: ''
  });
  const [editCustomerId, setEditCustomerId] = useState(null);

  const handleClose = () => {
    setShow(false);
    setEditMode(false);
    setNewCustomer({ name: '', surname: '', phone: '', mail: '', address: '' });
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await axios.get(`${apiUrl}/customers`, {
          params: { userId, searchTerm },
          withCredentials: true
        });
        setCustomers(response.data);
      } catch (error) {
        console.error('Müşteri verileri alınırken hata oluştu:', error);
      }
    };
    fetchCustomers();
  }, [apiUrl, userId, searchTerm]);

  const handleAddCustomer = async () => {
    try {
      const response = await axios.post(
        `${apiUrl}/customers`,
        { ...newCustomer, userId },
        { withCredentials: true }
      );
      setCustomers(prev => [...prev, response.data]);
      handleClose();
    } catch (error) {
      console.error('Yeni müşteri eklenirken hata oluştu:', error);
    }
  };

  const handleEditCustomer = async (customerId) => {
    setEditCustomerId(customerId);
    const customerToEdit = customers.find(customer => customer._id === customerId);
    setNewCustomer(customerToEdit);
    setEditMode(true);
    handleShow();
  };

  const handleUpdateCustomer = async () => {
    try {
      const response = await axios.put(
        `${apiUrl}/customers/${editCustomerId}`,
        newCustomer,
        { withCredentials: true }
      );
      setCustomers(prev =>
        prev.map(customer => customer._id === editCustomerId ? response.data : customer)
      );
      handleClose();
    } catch (error) {
      console.error('Müşteri güncellenirken hata oluştu:', error);
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    const confirmDelete = window.confirm("Bu müşteriyi silmek istediğinize emin misiniz?");
    if (!confirmDelete) return;
    try {
      const response = await axios.delete(`${apiUrl}/customers/${customerId}`, { withCredentials: true });
      if (response.status === 200) {
        alert("Müşteri başarıyla silindi.");
        setCustomers(prev => prev.filter(customer => customer._id !== customerId));
      }
    } catch (error) {
      console.error('Müşteri silinirken hata oluştu:', error);
    }
  };

  return (
    <Container fluid className="py-4 text-center">
      <Row className="mb-3">
        <Col xs={12} md={6}>
          <Button variant="warning" className="w-100" onClick={() => { handleShow(); setEditMode(false); }}>YENİ MÜŞTERİ EKLE</Button>
        </Col>
        <Col xs={12} md={6}>
          <Form.Control
            type="text"
            placeholder="Müşteri Ad, Soyad, Telefon ile ara."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Col>
      </Row>

      <Card>
        <Card.Header>MÜŞTERİLER</Card.Header>
        <Card.Body>
          <Row className="justify-content-center">
            {customers
              .filter(customer =>
                customer.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                customer.phone.includes(searchTerm)
              )
              .map(customer => (
                <Col key={customer._id} xs={12} className="mb-2 pb-2" style={{ borderBottom: '1px solid #dee2e6' }}>
                  <Row>
                    <Col md={6} className="text-center">
                      <p><strong>{customer.name} {customer.surname}</strong></p>
                      <p>{customer.phone} - {customer.mail}</p>
                    </Col>
                    <Col md={4} className="text-center">
                      <p><strong>Adres:</strong></p>
                      <p>{customer.address}</p>
                    </Col>
                    <Col md={2} className="text-center">
                      <Dropdown size="sm">
                        <Dropdown.Toggle variant="warning" id="dropdown-basic" className="btn-sm">
                          İŞLEMLER
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleEditCustomer(customer._id)}>DÜZENLE</Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteCustomer(customer._id)}>SİL</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </Col>
                  </Row>
                </Col>
              ))}
          </Row>
        </Card.Body>
      </Card>

      <Offcanvas show={show} onHide={handleClose} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="w-100 text-center">
            {editMode ? 'Müşteriyi Düzenle' : 'Yeni Müşteri Ekle'}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="d-flex flex-column align-items-center">
          <Form style={{ width: '100%', maxWidth: '300px' }}>
            <Form.Group className="mb-3 text-center" controlId="formName">
              <Form.Label>İsim</Form.Label>
              <Form.Control
                type="text"
                placeholder="Müşteri ismi"
                value={newCustomer.name}
                onChange={(e) => setNewCustomer({ ...newCustomer, name: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="formSurname">
              <Form.Label>Soyisim</Form.Label>
              <Form.Control
                type="text"
                placeholder="Müşteri soyismi"
                value={newCustomer.surname}
                onChange={(e) => setNewCustomer({ ...newCustomer, surname: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="formPhone">
              <Form.Label>Telefon</Form.Label>
           <PhoneInput
  country={'tr'}
  value={newCustomer.phone}
  onChange={(phone) => setNewCustomer({ ...newCustomer, phone })}
  inputStyle={{
    width: '100%',
    height: '40px',
    fontSize: '16px',
    paddingLeft: '20px'
  }}
  dropdownStyle={{
    maxHeight: '300px',
    overflowY: 'scroll'
  }}
  containerStyle={{
    width: '100%',
    marginBottom: '15px'
  }}
  preferredCountries={['us', 'gb', 'tr']}
  disableDropdown={false}
/>


            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="formMail">
              <Form.Label>E-posta</Form.Label>
              <Form.Control
                type="email"
                placeholder="E-posta adresi"
                value={newCustomer.mail}
                onChange={(e) => setNewCustomer({ ...newCustomer, mail: e.target.value })}
              />
            </Form.Group>
            <Form.Group className="mb-3 text-center" controlId="formAddress">
              <Form.Label>Adres</Form.Label>
              <Form.Control
                type="text"
                placeholder="Adres"
                value={newCustomer.address}
                onChange={(e) => setNewCustomer({ ...newCustomer, address: e.target.value })}
              />
            </Form.Group>

            <Button variant="primary" onClick={editMode ? handleUpdateCustomer : handleAddCustomer} className="w-100">
              {editMode ? 'Güncelle' : 'Müşteri Ekle'}
            </Button>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default Customer;
