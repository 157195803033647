import React from 'react';
import { Container, Row, Col,Table } from 'react-bootstrap';
import Footer from '../Footer';

const DistanceSalesAgreement = () => {
  return (
    <Container fluid className="px-4 py-3 mt-5">
      <Row className="mt-5">
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Mesafeli Satış Sözleşmesi</h2>
        </Col>
      </Row>

      {/* TARAFLAR */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>1- TARAFLAR</h4>
          <h5 style={{ color: '#EFB02F' }}>Satıcı (İndirimlikargo) Bilgileri</h5>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li><strong>Satıcı İsim/Unvanı: Sanatkardan E-Ticaret İnternet Mağazacılık San.Tic.Ltd.Şti.</strong> </li>
            <li><strong>Satıcı Açık Adres: KIZILIRMAK MAH. DUMLUPINAR BLV. NEXTLEVEL NO:3 A İÇKAPI NO:10 ÇANKAYA ANKARA
</strong> </li>
            <li><strong>Satıcı E-Posta Adresi:: destek@sanatkardan.com</strong> </li>
            <li><strong>Telefon:+90 850 304 0555
</strong> </li>
          </ul>

          <h5 style={{ color: '#EFB02F', marginTop: '20px' }}>Alıcı Bilgileri</h5>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li><strong>İsim:</strong> </li>
            <li><strong>Soyisim:</strong> </li>
            <li><strong>Telefon:</strong> </li>
            <li><strong>E-Posta:</strong> </li>
            <li><strong>Ülke:</strong> </li>
            <li><strong>Şehir:</strong> </li>
            <li><strong>İlçe:</strong> </li>
            <li><strong>Adres:</strong> </li>
            <li><strong>Posta Kodu:</strong> </li>
          </ul>

          <p style={{ color: 'white', marginTop: '20px' }}>
            Bundan böyle Alıcı ve Satıcı birlikte "Taraflar" olarak anılacaktır.
          </p>
        </Col>
      </Row>
       {/* SÖZLEŞMENİN KONUSU VE KAPSAMI */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>2- SÖZLEŞMENİN KONUSU VE KAPSAMI</h4>
          <p style={{ color: 'white' }}>
            İşbu Mesafeli Satış Sözleşmesi'nin (“Sözleşme”) konusu, aşağıdaki detayları ve satış fiyatı belirlenen ürün ile ilgili olarak 6502
            sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği hükümleri gereğince tarafların hak ve
            yükümlülüklerinin tespitidir. Taraflar işbu sözleşmede yer alan yükümlülüklerini bildiklerini ve anladıklarını kabul ve beyan etmiş
            sayılırlar.
          </p>
        </Col>
      </Row>
        {/* SÖZLEŞMEYE KONU ÜRÜN/HİZMET BİLGİLERİ */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>SÖZLEŞMEYE KONU ÜRÜN/HİZMET BİLGİLERİ</h4>
          <p style={{ color: 'white' }}>
            <strong>- SÖZLEŞMEYE KONU ÜRÜN/HİZMETİN TEMEL NİTELİKLERİ VE FİYATI (KDV DAHİL)</strong>
          </p>
          <Table striped bordered hover variant="dark" responsive>
            <thead>
              <tr>
                <th>Ürün Adı</th>
                <th>Adet</th>
                <th>Tutar</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td> {/* Ürün Adı bilgisi */}
                <td></td> {/* Adet bilgisi */}
                <td></td> {/* Tutar bilgisi */}
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      {/* GENEL HÜKÜMLER */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>GENEL HÜKÜMLER</h4>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>
              <strong>4.1:</strong> Alıcı, indirimlikargo.com internet sitesinde veya satıcıya ait diğer web sitesi üzerinden talep ettiği sipariş içeriğine istinaden
              sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu
              ve elektronik ortamda gerekli teyidi verdiğini beyan eder.
            </li>
            <li>
              <strong>4.2:</strong> Sözleşme konusu ürün, yasal 30 günlük süreyi aşmamak koşulu ile her bir ürün için ALICI'nın yerleşim yerinin uzaklığına
              bağlı olarak internet sitesinde ön bilgiler içinde açıklanan süre içinde ALICI veya gösterdiği adresteki kişi/kuruluşa teslim edilir.
            </li>
            <li>
              <strong>4.3:</strong> Sözleşme konusu ürün, ALICI'dan başka bir kişi/kuruluşa teslim edilecek ise, teslim edilecek kişi/kuruluşun teslimatı kabul
              etmemesinden SATICI sorumlu tutulamaz.
            </li>
            <li>
              <strong>4.4:</strong> SATICI, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım
              kılavuzları ile teslim edilmesinden sorumludur.
            </li>
            <li>
              <strong>4.5:</strong> Sözleşme konusu ürünün teslimatı için işbu sözleşmenin alıcı tarafından elektronik ortamda onaylanması ve sipariş bedelinin
              ALICI'nın tercih ettiği ödeme şekli ile ödenmiş olması şarttır. Herhangi bir nedenle ürün bedeli ödenmez veya banka kayıtlarında
              iptal edilirse, SATICI ürünün teslimi yükümlülüğünden kurtulmuş kabul edilir.
            </li>
            <li>
              <strong>4.6:</strong> Ürünün tesliminden sonra ALICI'ya ait kredi kartının ALICI'nın kusurundan kaynaklanmayan bir şekilde yetkisiz kişilerce
              haksız veya hukuka aykırı olarak kullanılması nedeni ile ilgili banka veya finans kuruluşunun ürün bedelini SATICI'ya ödememesi
              halinde, ALICI'nın kendisine teslim edilmiş olması kaydıyla ürünün 3 gün içinde SATICI'ya geri gönderilmesi zorunludur. Bu
              takdirde nakliye giderleri ALICI'ya aittir.
            </li>
            <li>
              <strong>4.7:</strong> SATICI mücbir sebepler veya nakliyeyi engelleyen hava muhalefeti, ulaşımın kesilmesi gibi olağanüstü durumlar nedeniyle
              sözleşme konusu ürünü süresi içinde teslim edemezse, durumu ALICI'ya bildirmekle yükümlüdür. Bu takdirde ALICI siparişin
              iptal edilmesini, sözleşme konusu ürünün varsa emsali ile değiştirilmesini ve/veya teslimat süresinin engelleyici durumun ortadan
              kalkmasına kadar ertelenmesi haklarından birini kullanabilir. ALICI'nın siparişi iptal etmesi halinde SATICI sipariş tutarını 10
              gün içerisinde ALICIYA iade etmekle yükümlüdür.
            </li>
            <li>
              <strong>4.8:</strong> Garanti belgesi ile satılan ürünlerden olan veya olmayan ürünlerin arızalı veya bozuk olanlar, garanti şartları içinde gerekli
              onarımın yapılması için SATICI'ya gönderilebilir, bu takdirde kargo giderleri SATICI tarafından karşılanacaktır.
            </li>
            <li>
              <strong>4.9:</strong> İndirimlikargo.com işbu sözleşmenin tarafı olmadığından ALICI’NIN, indirimlikargo.com’a karşı cayma hakkı yoktur ve
              indirimlikargo.com’dan sipariş bedeli iadesi ve ürün değişimi talep edemez. Cayma hakkını satıcıdan talep edebilir.
            </li>
          </ul>
        </Col>
      </Row>
       {/* CAYMA HAKKI */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>3-CAYMA HAKKI</h4>
          <p style={{ color: 'white' }}>
            ALICI, sözleşme konusu ürünün kendisine veya gösterdiği adresteki kişi/kuruluşa tesliminden itibaren 14 gün içinde cayma hakkına sahiptir.
            Cayma hakkının kullanılması için bu süre içinde SATICI'ya faks, email veya telefon ile bildirimde bulunulması ve ürünün 6. madde hükümleri
            çerçevesinde kullanılmamış olması şarttır. Bu hakkın kullanılması halinde, 3. kişiye veya ALICI'ya teslim edilen ürünün SATICI'ya gönderilmesine
            takiben, SATICI 10 gün içinde ürün bedelini ALICI'ya iade etmekle yükümlü olduğunu beyan, taahhüt ve kabul eder. Cayma hakkı nedeni ile iade edilen
            ürünün kargo bedeli SATICI tarafından karşılanır.
          </p>
        </Col>
      </Row>
          {/* CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>CAYMA HAKKI KULLANILAMAYACAK ÜRÜNLER</h4>
          <p style={{ color: 'white' }}>
            Alıcı, Mesafeli Sözleşmeler Yönetmeliği Md. 15/1 uyarınca cayma hakkını aşağıdaki hallerde kullanamayacağını kabul etmiştir:
          </p>
          <ul style={{ color: 'white' }}>
            <li>
              Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve satıcı veya sağlayıcının kontrolünde olmayan mal veya hizmetler,
            </li>
            <li>
              Tüketicinin istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallar,
            </li>
            <li>
              Çabuk bozulabilen veya son kullanma tarihi geçebilecek mallar,
            </li>
            <li>
              Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanlar,
            </li>
            <li>
              Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallar,
            </li>
            <li>
              Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemeleri,
            </li>
            <li>
              Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınlar,
            </li>
            <li>
              Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin hizmetler,
            </li>
            <li>
              Elektronik ortamda anında ifa edilen hizmetler veya tüketiciye anında teslim edilen gayri maddi mallar,
            </li>
            <li>
              Cayma hakkı süresi sona ermeden önce, tüketicinin onayı ile ifasına başlanan hizmetler.
            </li>
          </ul>
        </Col>
      </Row>
        {/* YETKİLİ MAHKEME */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>YETKİLİ MAHKEME</h4>
          <p style={{ color: 'white' }}>
            İşbu sözleşmenin uygulanmasında, Sanayi ve Ticaret Bakanlığınca ilan edilen değere kadar Tüketici Hakem Heyetleri ile
            ALICI'nın veya SATICI'nın yerleşim yerindeki Tüketici Mahkemeleri yetkilidir. Siparişin gerçekleşmesi durumunda ALICI işbu
            sözleşmenin tüm koşullarını kabul etmiş sayılır.
          </p>
          <p style={{ color: 'white' }}>
            İşbu sözleşme 08.11.2024 tarihinde yukarıda belirtilen taraflar arasında kurulmuştur.
          </p>
        </Col>
      </Row>
      <Footer/>
    </Container>
  );
};

export default DistanceSalesAgreement;
