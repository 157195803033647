import React, { useState, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, Button, Table, Form, Popover, Overlay, Offcanvas } from 'react-bootstrap';
import { FaShoppingCart, FaFileCsv, FaTruck, FaBox } from 'react-icons/fa';
import { AiOutlineRobot } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import WalletCard from './Wallet/WalletCard';

const Shipments = () => {
  const [shipments, setShipments] = useState([]);
  const [showAccordion, setShowAccordion] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false); // State for controlling offcanvas visibility
  const [showPopover, setShowPopover] = useState(false); // State for controlling popover visibility
  const popoverTarget = useRef(null); // Ref for positioning the popover
  const [aiInput, setAiInput] = useState('');
  const [balance, setBalance] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [usableBalance, setUsableBalance] = useState(0);
  const [averageShippingCost, setAverageShippingCost] = useState(0);
  const [trackingData, setTrackingData] = useState({}); // Kargo takip verileri
  const [smsChecked, setSmsChecked] = useState(false); // SMS checkbox durumu

  // Redux'tan ownerId'yi alıyoruz
  const ownerId = useSelector((state) => state.user.userId);
  const apiUrl = process.env.REACT_APP_API_URL; // Dinamik API URL

  useEffect(() => {
    if (!ownerId) {
      console.warn('Owner ID is undefined or null. Skipping data fetch.');
      return;
    }

    const fetchWalletData = async () => {
      try {
        const token = localStorage.getItem('x-auth-token');
        const balanceResponse = await axios.get(`${apiUrl}/wallet/${ownerId}/balance`, {
          headers: {
            'x-auth-token': token,
          },
        });

        if (balanceResponse && balanceResponse.data) {
          setBalance(balanceResponse.data.balance || 0);
          setBlockedBalance(balanceResponse.data.blockedBalance || 0);
          setUsableBalance(balanceResponse.data.usableBalance || 0);
          setAverageShippingCost(balanceResponse.data.averageShippingCost || 0);
        } else {
          console.warn('No data received from API.');
        }
      } catch (error) {
        console.error('Error occurred while fetching wallet data:', error);
      }
    };

    fetchWalletData();
  }, [ownerId, apiUrl]);  // ownerId ve apiUrl'yi bağımlılık olarak ekledik.

  const fetchShipments = async () => {
    try {
      const token = localStorage.getItem('x-auth-token');
      const response = await axios.get(`${apiUrl}/shipments`, {
        headers: {
          'x-auth-token': token,
        },
      });

      setShipments(response.data);

      for (const shipment of response.data) {
        fetchTrackingData(shipment.id, shipment.trackingCode, shipment.company);
      }
    } catch (error) {
      console.error('Veri çekme hatası:', error);
    }
  };

  useEffect(() => {
    fetchShipments();
  }, [apiUrl]); // apiUrl değiştiğinde fetchShipments fonksiyonunu tetikleyelim.

  const fetchTrackingData = async (shipmentId, trackingCode, company) => {
    try {
      const token = localStorage.getItem('x-auth-token');
      const response = await axios.post(
        `${apiUrl}/track-shipment`,
        {
          trackingNumber: trackingCode,
          company: company,
          store_id: ownerId,
        },
        {
          headers: {
            'x-auth-token': token,
            'Content-Type': 'application/json',
          },
        }
      );

      setTrackingData((prevTrackingData) => ({
        ...prevTrackingData,
        [shipmentId]: response.data.data.trackShipmentout,
      }));
    } catch (error) {
      console.error('Kargo takip hatası:', error);
    }
  };

  const handleCreateShipment = async () => {
    try {
      const token = localStorage.getItem('x-auth-token');
      const response = await fetch(`${apiUrl}/shipments/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Kargo oluşturulamadı.');
      }

      fetchShipments();
    } catch (error) {
      console.error('Kargo oluşturma hatası:', error);
    }
  };

  const handleCancelShipment = async (shipmentId, trackingCode, company) => {
    try {
      const token = localStorage.getItem('x-auth-token');
      const response = await axios.post(
        `${apiUrl}/shipments/cancel`,
        {
          tracking_code: trackingCode,
          company: company,
          cancel_reason: 'Müşteri talebi',
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      if (response.status === 200) {
        console.log('Kargo başarıyla iptal edildi');
        fetchShipments();
      } else {
        console.error('Kargo iptal edilemedi');
      }
    } catch (error) {
      console.error('Kargo iptal hatası:', error);
    }
  };

  const handleSMSPayment = async () => {
    try {
      const token = localStorage.getItem('x-auth-token');
      const response = await axios.post(
        `${apiUrl}/wallet/${ownerId}/withdraw`,
        {
          amount: 1,
          description: 'SMS Hizmeti Ücreti',
        },
        {
          headers: {
            'x-auth-token': token,
          },
        }
      );

      if (response.status === 200) {
        console.log('1 TL başarıyla çekildi.');
      } else {
        console.error('1 TL çekilemedi.');
      }
    } catch (error) {
      console.error('SMS ücreti çekme hatası:', error);
    }
  };

  const handleSMSCheckboxChange = () => {
    setSmsChecked(!smsChecked);

    if (!smsChecked) {
      handleSMSPayment(); // Checkbox işaretlendiğinde 1 TL'yi çek
    }
  };

  const handlePushNotificationSubscribe = async () => {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js');
      const subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(process.env.VAPID_PUBLIC_KEY),
      });

      const token = localStorage.getItem('x-auth-token');
      await fetch(`${apiUrl}/push/subscribe`, {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      alert('Push Notification aboneliği başarıyla oluşturuldu.');
    } catch (error) {
      console.error('Push Notification abonelik hatası:', error);
    }
  };

  const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  };

  const handleOffcanvasSubmit = () => {
    console.log('Gönderilen Mesaj:', aiInput);
    setShowOffcanvas(false); // Close the offcanvas after submit
  };

  const popover = (
    <Popover id="popover-centered" className="custom-popover">
      <Popover.Header as="h3">Nasıl Kullanırım?</Popover.Header>
      <Popover.Body>
        <strong>Hangi kolonlara hangi veriler gelmeli?</strong>
        <ul>
          <li>A-) Müşteri Adı</li>
          <li>B-) Müşteri Soyadı</li>
          <li>C-) Müşteri Telefon</li>
          <li>D-) Kargo Firması</li>
          <li>E-) Gönderi Türü</li>
          <li>F-) Kapıda Ödeme Bedeli</li>
          <li>G-) UPS Kapıda Ödeme Türü</li>
          <li>H-) Ürün Adı</li>
          <li>I-) Ürün Fiyatı</li>
          <li>J-) Açık Adres</li>
        </ul>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid className="py-4">
      {/* Üst Kısım */}
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h4 className="mb-0">KARGO OLUŞTUR</h4>
        </Col>
        <Col xs={12} md={6} className="text-end">
          <Button variant="warning" onClick={handleCreateShipment}>+Gönderi Oluştur</Button>
        </Col>
      </Row>

      {/* SMS Checkbox */}
      <Form.Group controlId="smsCheckbox">
        <Form.Check 
          type="checkbox" 
          label="SMS Hizmeti (1 TL)" 
          checked={smsChecked}
          onChange={handleSMSCheckboxChange}
        />
      </Form.Group>

      {/* Bakiye Bilgileri */}
      <Row className="align-items-stretch mb-4">
       <WalletCard/>
      </Row>

      {/* Kargo Ekleme Yöntemleri Butonları */}
      <Row className="text-center mb-4">
        <Col xs={12} md={4} className="mb-2">
          <Link to="/panelim/manuel-ekle">
            <Button variant="warning" className="w-100 d-flex align-items-center justify-content-center">
              <FaShoppingCart className="me-2" /> Manuel Ekle
            </Button>
          </Link>
        </Col>
        <Col xs={12} md={4} className="mb-2">
          <Button variant="outline-warning" className="w-100 d-flex align-items-center justify-content-center" onClick={() => setShowAccordion(!showAccordion)}>
            <FaFileCsv className="me-2" /> CSV / Excel Dosyasıyla Ekle
          </Button>
        </Col>
        <Col xs={12} md={4} className="mb-2">
          <Button variant="warning" className="w-100 d-flex align-items-center justify-content-center" onClick={() => setShowOffcanvas(true)}>
            <AiOutlineRobot className="me-2" /> Yapay Zekayla Ekle
          </Button>
        </Col>
      </Row>

      {/* Offcanvas for AI-Based Entry */}
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>AI Tabanlı Metinle Gönderi Algılama</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleOffcanvasSubmit(); }}>
            <Form.Group controlId="aiInput">
              <Form.Label>Gönderi Bilgileri</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Sipariş bilgilerini buraya giriniz..."
                value={aiInput}
                onChange={(e) => setAiInput(e.target.value)}
                required
              />
              <Form.Text className="text-muted">
                Sosyal Medya, Whatsapp vb. uygulamalar üzerinden aldığınız siparişleri yukarıdaki formatta giriniz.
              </Form.Text>
            </Form.Group>
            <div className="text-end mt-3">
              <Button variant="secondary" className="me-2" onClick={() => setShowOffcanvas(false)}>
                İptal
              </Button>
              <Button variant="warning" type="submit">
                Gönder
              </Button>
            </div>
          </Form>
        </Offcanvas.Body>
      </Offcanvas>

      {/* CSV/Excel Dosyası Yükleme Bölümü */}
      {showAccordion && (
        <Card className="p-3 mb-4">
          <div className="d-flex align-items-center">
            <div className="me-3">
              <FaFileCsv size={50} />
            </div>
            <div>
              <p className="mb-1">
                Dilerseniz <strong>Buradan</strong> örnek Excel tablosunu indirerek işlem yapabilirsiniz. 
                Detaylı Kullanım Bilgisi İçin 
                <Button variant="link" className="p-0" ref={popoverTarget} onClick={() => setShowPopover(!showPopover)}>Tıklayınız</Button>
              </p>
              <Button variant="outline-primary">CSV / Excel Dosyası Yükle</Button>
            </div>
          </div>
        </Card>
      )}

      {/* Centered Popover */}
      <Overlay
        show={showPopover}
        target={popoverTarget.current}
        placement="bottom"
        containerPadding={20}
        rootClose
        onHide={() => setShowPopover(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              position: 'fixed',
              zIndex: 1040,
              width: '500px',
              maxWidth: '500px',
              maxHeight: '90%',
              overflowY: 'auto',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            {popover}
          </div>
        )}
      </Overlay>

      {/* Kargo Tablosu */}
      <Table bordered hover responsive className="align-middle">
        <thead>
          <tr>
            <th>
              <Form.Check type="checkbox" />
            </th>
            <th>Kargo Bilgileri</th>
            <th>Ürün Bilgileri</th>
            <th>Durumu</th>
            <th>İşlemler</th>
          </tr>
        </thead>
        <tbody>
          {shipments.map((shipment, index) => (
            <tr key={shipment.id}>
              <td>
                <Form.Check type="checkbox" />
              </td>
              <td>
                <div className="d-flex">
                  <FaTruck size={30} className="me-3" />
                  <div>
                    <strong>{shipment.customerName}</strong><br />
                    {shipment.address}<br />
                    {shipment.phone}<br />
                    {shipment.cargoType}
                  </div>
                </div>
                {trackingData[shipment.id] && (
                  <div>
                    <strong>Kargo Durumu:</strong> {trackingData[shipment.id].status}<br />
                    <strong>Tarih:</strong> {new Date(trackingData[shipment.id].history[0]?.timestamp).toLocaleDateString()}
                  </div>
                )}
              </td>
              <td>
                <div className="d-flex align-items-center">
                  <FaBox size={30} className="me-3" />
                  <div>
                    {shipment.productName}<br />
                    <strong>{shipment.price} ₺</strong>
                    {shipment.status === 'Hatalı' && (
                      <span style={{ color: 'orange', fontWeight: 'bold', border: '1px dashed orange', padding: '2px 5px', borderRadius: '5px' }}>Hatalı</span>
                    )}
                  </div>
                </div>
              </td>
              <td>
                {trackingData[shipment.id]?.status || 'Bilinmiyor'}
              </td>
              <td className="text-center">
                <Button
                  variant="danger"
                  size="sm"
                  className="me-2"
                  onClick={() => handleCancelShipment(shipment.id, shipment.trackingCode, shipment.company)}
                >
                  İptal Et
                </Button>
              </td>
              <td>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={handlePushNotificationSubscribe}
                >
                  Abone Ol
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default Shipments;
