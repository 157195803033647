import React, { createContext, useContext, useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home'; // Ana sayfa
import PageNotFound from './pages/PageNotFound'; // 404 sayfası
import Header from './Layout/Header'; // Header bileşeni
import Register from './Authentication/Register';
import Login from './Authentication/Login';
import { Provider } from 'react-redux';
import store from './store';  
import ProtectedRoute from './Panel/ProtectedRoute.js'; // Korumalı Route bileşeni
import Welcome from './Authentication/Welcome.jsx';
import Kvkp from './Components/legallink/Kvkp.jsx';
import TermsofUse from './Components/legallink/TermsofUse.jsx';
import SalesAgreement from './Components/legallink/SalesAgreement.jsx';
import CookiePolicy from './Components/legallink/CookiePolicy.jsx';
import TaxExemption from './Components/legallink/TaxExemption.jsx';
import Distance from './Components/legallink/Distance.jsx';
import DashboardRouter from './Panel/DashboardRouter.jsx';

// AuthContext oluşturalım
const AuthContext = createContext();

// Context üzerinden oturum bilgisini kullanmak için
export const useAuth = () => useContext(AuthContext);

function App() {
  // Oturum bilgisini yönetmek için state
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem('token') ? true : false); // Kullanıcı oturumu kontrolü

  // Giriş işlemi başarılı olduktan sonra oturum bilgisini güncelle
  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
      <Provider store={store}>
        <Router>
          <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
          <Routes>
            {/* Ana Sayfa */}
            <Route path="/" element={<Home />} />

            {/* Diğer Sayfalar */}
            <Route path="/giriş-yap" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/üye-ol" element={<Register />} />
             <Route path="/hoşgeldiniz" element={<Welcome />} />
            <Route path="/kisisel-verilerin-korunmasi-politikasi" element={<Kvkp />} />
            <Route path="/kullanim-kosullarimiz" element={<TermsofUse />} />
            <Route path="/aydinlatma-ve-acik-riza-metni" element={<SalesAgreement />} />
            <Route path="/gizlilik-ve-cerez-politikasi" element={<CookiePolicy />} />
            <Route path="/esnaf-vergi-muafiyeti" element={<TaxExemption />} />
            <Route path="/mesafeli-satis-ve-iptal-iade-sözlesmesi" element={<Distance />} />

            {/* Korumalı Panel Sayfası */}
            <Route 
              path="/panelim/*" 
              element={
                <ProtectedRoute isLoggedIn={isLoggedIn}>
                  <DashboardRouter />
                </ProtectedRoute>
              } 
            />

            {/* 404 Sayfası */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </Provider>
    </AuthContext.Provider>
  );
}

export default App;
