import React, { useState, useEffect } from 'react';
import { Button, Card, Form, Alert, CardBody } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FaInfoCircle } from 'react-icons/fa'; // React Icons kütüphanesinden info simgesini import ediyoruz
import Master from '../../assets/images/bankalar/master.png';
import Visa from '../../assets/images/bankalar/visa.png';
import Troy from '../../assets/images/bankalar/troy.png';

import HavaleEftOffcanvas from './HavaleEftOffcanvas';
import { IoWallet } from "react-icons/io5";


const BalanceLoad = ({ handleBankTransferDeposit }) => {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [depositAmount, setDepositAmount] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [withdrawDetails, setWithdrawDetails] = useState({
    firstName: '', // Kullanıcının adı
    lastName: '', // Kullanıcının soyadı
    iban: '', // IBAN numarası
    phoneNumber: '', // Telefon numarası
    email: '' // E-posta
  });

  const [usableBalance, setUsableBalance] = useState(0); // Kullanılabilir bakiye
  const [showDepositAlert, setShowDepositAlert] = useState(false);
  const [depositStatusMessage, setDepositStatusMessage] = useState('');
  const [showWithdrawAlert, setShowWithdrawAlert] = useState(false);
  const [withdrawStatusMessage, setWithdrawStatusMessage] = useState('');
 const [paymentMethod, setPaymentMethod] = useState('online'); 
  const userId = useSelector((state) => state.user.userId); // Kullanıcı ID'sini Redux'tan alıyoruz
  const storeId = useSelector((state) => state.user.storeId); // Store ID'sini Redux'tan alıyoruz
  const navigate = useNavigate();

  // Kullanılabilir bakiyeyi çekmek için fonksiyon
  const fetchWalletData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const balanceResponse = await axios.get(`${apiUrl}/wallet/balance`, {
        withCredentials: true,
      });

      // Kullanılabilir bakiyeyi güncelle
      setUsableBalance(balanceResponse.data.usableBalance || 0);
    } catch (error) {
      console.error('Wallet data fetching error:', error);
    }
  };

  // Bileşen yüklendiğinde kullanılabilir bakiyeyi çek
  useEffect(() => {
    fetchWalletData();
  }, [userId]);

const handleDeposit = async () => {
  const formattedAmount = parseFloat(depositAmount).toFixed(2).replace(',', '.');

  if (isNaN(formattedAmount) || formattedAmount <= 0) {
    alert('Lütfen geçerli bir tutar girin.');
    return;
  }

  try {
    const apiUrl = process.env.REACT_APP_API_URL;

    // API'ye gönderilecek verilerin oluşturulması
    const postData = {
      userId: userId, // Kullanıcı ID'si
      storeId: storeId, // Store ID'si
      orderPrice: formattedAmount, // Yatırılacak tutar
      buyerName: withdrawDetails.firstName, // Kullanıcının tam adı
      buyerSurName: withdrawDetails.lastName, // Kullanıcının soyadı
      buyerGsmNo: withdrawDetails.phoneNumber, // Telefon numarası
      buyerMail: withdrawDetails.email, // E-posta adresi
      productName: 'Cüzdan Bakiyesi Yükleme', // Sabit ürün adı
    };

    console.log('Yükleme isteği gönderiliyor:', postData);

    const response = await axios.post(
      `${apiUrl}/wallet/create-payment-link`, // Backend endpoint
      postData,
      {
        withCredentials: true,
      }
    );

    if (response.data.success) {
      // Kullanıcıyı Vallet'in ödeme sayfasına yönlendirin
      window.location.href = response.data.payment_page_url; 
    } else {
      setDepositStatusMessage(`Hata: ${response.data.message}`);
      setShowDepositAlert(true);
      navigate('/payment-failure');
    }
  } catch (error) {
    console.error('Para yükleme hatası:', error);
    setDepositStatusMessage('Para yükleme sırasında bir hata oluştu.');
    setShowDepositAlert(true);
    navigate('/payment-failure');
  }
};


  // Para çekme işlemi
  const handleWithdraw = async () => {
    const { fullName, iban } = withdrawDetails;

    if (!fullName || !iban || withdrawAmount <= 0) {
      alert('Lütfen isim-soyisim, IBAN numarasını ve geçerli bir tutar girin.');
      return;
    }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await axios.post(
        `${apiUrl}/wallet/withdraw`,
        {
          amount: withdrawAmount,
          withdrawDetails: {
            fullName,
            iban,
            phoneNumber: withdrawDetails.phoneNumber,
            email: withdrawDetails.email,
          },
          category: 'PARA_CEKME',
        },
        {
          withCredentials: true,
        }
      );

      if (response.data.success) {
        setWithdrawStatusMessage('Para çekme talebiniz başarılı bir şekilde oluşturulmuştur.');
        setShowWithdrawAlert(true);
        navigate('/payment-eftsuccess');
      } else {
        setWithdrawStatusMessage(`İşlem başarısız: ${response.data.message}`);
        setShowWithdrawAlert(true);
        navigate('/payment-failure');
      }
    } catch (error) {
      console.error('Para çekme hatası:', error);
      setWithdrawStatusMessage('Para çekme sırasında bir hata oluştu.');
      setShowWithdrawAlert(true);
      navigate('/payment-failure');
    }
  };

  return (
    <>
      <Card className="p-3">
        
        {/* <h5 className="text-center mb-4"></h5> */}
        <h2 className="text-center mb-4"><IoWallet /> <span></span>{usableBalance.toFixed(2)}₺</h2>
        <p style={{ color: '#FFCA2C', textAlign: 'right' }}><span>KULLANILABİLİR BAKİYE </span></p>

 {/* Ödeme Yöntemi Seçeneği */}
<Card className="mt-3">
  <Form.Group className="mb-3 d-flex flex-column align-items-center" style={{ width: '100%' }}>
    <div style={{ width: '200px', textAlign: 'center' }}> {/* Checkbox alanını sabit genişlikte ortalamak için */}
      <Form.Check
        type="checkbox"
        label="Online Öde"
        name="paymentMethod"
        checked={paymentMethod === 'online'}
        onChange={() => setPaymentMethod('online')}
        className="mb-0"
      />
      <Form.Check
        type="checkbox"
        label="Havale/EFT ile Öde"
        name="paymentMethod"
        checked={paymentMethod === 'eft'}
        onChange={() => {
          setPaymentMethod('eft');
          setShowOffcanvas(true);
        }}
        className="mb-0"
      />
    </div>
  </Form.Group>

  {/* Havale/EFT Offcanvas */}
  {paymentMethod === 'eft' && (
    <HavaleEftOffcanvas
      show={showOffcanvas}
      onHide={() => {
        setShowOffcanvas(false);
        setPaymentMethod('online'); // Offcanvas kapandığında "Online Öde"yi seçili yap
      }}
    />
  )}
</Card>





            {/* ////////////////////////7 */}
        <Card className="mb-3">

        <CardBody> 
          <Card.Header className="text-center">BAKİYE YÜKLE
            <span style={{ marginLeft: '10px' }}>
              <img src={Master} alt="Master" style={{ width: '30px', marginRight: '5px' }} />
              <img src={Visa} alt="Visa" style={{ width: '30px', marginRight: '5px' }} />
              <img src={Troy} alt="Troy" style={{ width: '30px', marginRight: '5px' }} />
            </span>
          </Card.Header>

      
      


         



            {/* Kullanıcı Bilgileri Kısmı */}
        <Form.Group className="mb-3 text-center">
  <Form.Control
    type="text"
    placeholder="Yüklenecek Tutar"
    value={depositAmount}  
    onChange={(e) => {
      const value = e.target.value;
      // Sadece sayı ve ondalık kısıma izin veren regex kontrolü
      if (value === '' || /^\d*\.?\d*$/.test(value)) {
        setDepositAmount(value);
      }
    }}
    onBlur={() => {
      // Kullanıcı alan dışına çıktığında formatı uygula
      if (depositAmount) {
        const formattedValue = parseFloat(depositAmount).toFixed(2);
        setDepositAmount(formattedValue);
      }
    }}
    style={{ textAlign: 'center' }}
  />
</Form.Group>

              <Form.Group className="mb-3 text-center">
              <Form.Control
                type="text"
                placeholder="Adınızı giriniz"
                value={withdrawDetails.firstName}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, firstName: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-center">
              <Form.Control
                type="text"
                placeholder="Soyadınızı giriniz"
                value={withdrawDetails.lastName}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, lastName: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-center">
              <Form.Control
                type="text"
                placeholder="Telefon numaranızı giriniz"
                value={withdrawDetails.phoneNumber}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, phoneNumber: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-center">
              <Form.Control
                type="email"
                placeholder="Email adresinizi giriniz"
                value={withdrawDetails.email}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, email: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            {/* Yatır Butonu ve Tooltip */}
            <OverlayTrigger
              placement="top" // Tooltip'in nerede görüneceği
              overlay={
                <Tooltip id="tooltip-deposit">
                  Online ödeme sayfasına yönlendirileceksiniz
                </Tooltip>
              }
            >
              <Button variant="warning" className="w-100" onClick={handleDeposit}>
                Yatır
              </Button>
            </OverlayTrigger>

            {showDepositAlert && (
              <Alert variant={depositStatusMessage.includes('Hata') ? 'danger' : 'success'} className="mt-3">
                {depositStatusMessage}
              </Alert>
            )}
            </CardBody>
          </Card>
   
       

        <Card>
          <Card.Header className="text-center">PARA ÇEK
            <OverlayTrigger
              placement="left"
              overlay={
                <Tooltip id="tooltip-left">
                  15:00 Saatine kadar oluşturduğunuz para çekim talepleri aynı gün, 15:00 saatinden
                  sonra oluşturduğunuz para çekim talepleri, ertesi gün 15:00 saatinden
                  sonrasında hesabınıza aktarılır.
                </Tooltip>
              }
            >
              <span style={{ cursor: 'pointer', marginLeft: '5px' }}>
                <FaInfoCircle />
              </span>
            </OverlayTrigger>
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3" controlId="formLoadAmount">
              <Form.Label className="w-100 text-center">Çekilecek Tutar ₺</Form.Label>
              <Form.Control
                type="text"
                placeholder="Çekilecek Tutarı Giriniz ₺"
                value={withdrawAmount}
                onChange={(e) => setWithdrawAmount(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="İsim ve Soyisim"
                value={withdrawDetails.fullName}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, fullName: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="IBAN Numarası"
                value={withdrawDetails.iban}
                onChange={(e) => setWithdrawDetails({ ...withdrawDetails, iban: e.target.value })}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>
            <Button variant="warning" className="w-100" onClick={handleWithdraw}>Çekim Bildir</Button>
            {showWithdrawAlert && (
              <Alert variant={withdrawStatusMessage.includes('Hata') ? 'danger' : 'success'} className="mt-3">
                {withdrawStatusMessage}
              </Alert>
            )}
          </Card.Body>
        </Card>  
          </Card>

    </>
  );
};

export default BalanceLoad;
