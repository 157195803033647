import React, { useState, useEffect } from 'react';
import { Offcanvas, Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import qnblogo from '../../assets/images/bankalar/QNB.png';
import enparalogo from '../../assets/images/bankalar/enpara.webp';
import işbank from '../../assets/images/bankalar/işbank.webp';
import garantibank from '../../assets/images/bankalar/garanti_1.webp';
import denizbank from '../../assets/images/bankalar/denizbank.webp';
import pttbank from '../../assets/images/bankalar/ptt.webp';
import { IoWallet } from "react-icons/io5";

const HavaleEftOffcanvas = ({ show, onHide, onDepositSuccess }) => {
  const [usableBalance, setUsableBalance] = useState(0);
  const [depositAmount, setDepositAmount] = useState('');
  const [transactionId, setTransactionId] = useState(''); // İşlem numarası
  const [accountIban, setAccountIban] = useState(''); // Gönderen IBAN
  const [selectedBankIban, setSelectedBankIban] = useState(''); // Seçilen bankanın IBAN'ı
 
  // Uyarı mesajları için state'ler
  const [depositStatusMessage, setDepositStatusMessage] = useState(''); // Havale/EFT bildirimi mesajı

  const [showDepositAlert, setShowDepositAlert] = useState(false); // Havale/EFT bildirimi uyarısı durumu

  const [error, setError] = useState(false); // Hata durumu

  const userId = useSelector((state) => state.user.userId);

  // Kullanılabilir bakiyeyi çekmek için fonksiyon
  const fetchWalletData = async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const balanceResponse = await axios.get(`${apiUrl}/wallet/balance`, {
        withCredentials: true,
      });

      // Kullanılabilir bakiyeyi güncelle
      setUsableBalance(balanceResponse.data.usableBalance || 0);
    } catch (error) {
      console.error('Wallet data fetching error:', error);
    }
  };

  // Bileşen yüklendiğinde kullanılabilir bakiyeyi çek
  useEffect(() => {
    fetchWalletData();
  }, [userId]);

const handleeftDeposit = async () => {
  try {
    const apiUrl = process.env.REACT_APP_API_URL;

    const response = await axios.post(
      `${apiUrl}/wallet/bank-transfer`,
      {
        amount: depositAmount,
        transactionId: transactionId,
        accountIban: accountIban,
        selectedBankIban,
      },
      {
        withCredentials: true,
      }
    );

    // Eğer işlem başarılıysa kullanılabilir bakiyeyi güncellemek için fetchWalletData'yı çağırın
    if (response.data.success) {
      await fetchWalletData(); // Kullanılabilir bakiyeyi yeniden çek
    }

    // Başarı durumunda tüm verileri sıfırlayın ve başarı mesajını gösterin
    setDepositAmount('');
    setTransactionId('');
    setAccountIban('');
    setSelectedBankIban('');
    setDepositStatusMessage('İşleminiz başarıyla bildirildi, miktar en kısa sürede cüzdanınıza eklenecektir.');
    setShowDepositAlert(true);
    setError(false);

    if (onDepositSuccess) {
      onDepositSuccess();
    }
  } catch (error) {
    setDepositStatusMessage('Havale/EFT bildirimi sırasında bir hata oluştu.');
    setShowDepositAlert(true);
    setError(true);
    console.error('Havale/EFT bildirimi sırasında bir hata oluştu:', error);
  }
};



  const bankOptions = [
    {
      id: 1,
      name: 'QNB Finansbank',
      iban: 'TR72 0011 1000 0000 0067 0572 52',
      logo: qnblogo,
    },
    {
      id: 2,
      name: 'Enpara',
      iban: 'TR42 0011 1000 0000 0013 5307 03',
      logo: enparalogo,
    },
    {
      id: 3,
      name: 'Türkiye İş Bankası',
      iban: 'TR32 0006 4000 0010 0010 0115 88',
      logo: işbank,
    },
    {
      id: 4,
      name: 'Garanti BBVA',
      iban: 'TR24 0006 2000 0410 0006 9876 89',
      logo: garantibank,
    },
    {
      id: 5,
      name: 'DenizBank',
      iban: 'TR92 0013 4000 0017 0000 0015 44',
      logo: denizbank,
    },
    {
      id: 6,
      name: 'PttBank',
      iban: 'TR61 0011 5000 0000 0025 8789 87',
      logo: pttbank,
    },
  ];

  return (
    <Offcanvas show={show} onHide={onHide} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="w-100 text-center">Havale / EFT Bilgileri</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="px-3">
        <Card className="p-3">

             {/* <h5 className="text-center mb-4"></h5> */}
        <h2 className="text-center mb-4"><IoWallet /> <span></span>{usableBalance ? usableBalance.toFixed(2) : 0}₺</h2>
        <p style={{ color: '#FFCA2C', textAlign: 'right' }}><span>KULLANILABİLİR BAKİYE </span></p>

        </Card>

        <Card className="mb-3">
          <Card.Header className="text-center">BAKİYE YÜKLE</Card.Header>
          <Card.Body>
       <Form.Group className="mb-3" controlId="formLoadAmount">
  <Form.Control
    type="text"
    placeholder="Yüklenecek Tutarı Giriniz ₺"
    value={depositAmount}
    onChange={(e) => {
      const value = e.target.value;
      // Sadece sayılara ve bir ondalık noktaya izin veren regex
      if (value === '' || /^\d*\.?\d{0,2}$/.test(value) || /^\d+\.\d{0,2}$/.test(value)) {
        setDepositAmount(value);
      }
    }}
    onBlur={() => {
      // Kullanıcı alan dışına çıktığında formatı uygula
      if (depositAmount) {
        const formattedValue = parseFloat(depositAmount).toFixed(2);
        setDepositAmount(formattedValue);
      }
    }}
    style={{ textAlign: 'center' }}
    min="0"
  />
</Form.Group>


            <Form.Group className="mb-3 text-center">
              <Form.Control
                type="text"
                placeholder="İşlem Numarasını Giriniz"
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-center">
              <Form.Control
                type="text"
                placeholder="Kendi IBAN/Hesap Numaranızı Giriniz(Gönderici)"
                value={accountIban}
                onChange={(e) => setAccountIban(e.target.value)}
                style={{ textAlign: 'center' }}
              />
            </Form.Group>

            <Form.Group className="mb-3 text-center">
              <Form.Label>Gönderilecek Banka Hesabını Seçin</Form.Label>
              <Form.Select
                value={selectedBankIban}
                onChange={(e) => setSelectedBankIban(e.target.value)}
              >
                <option value="">Bir Banka Seçin</option>
                {bankOptions.map((bank) => (
                  <option key={bank.id} value={bank.iban}>
                    {bank.name} - {bank.iban}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button
              variant="warning"
              className="w-100 mt-3"
              onClick={handleeftDeposit}
            >
              HAVALE/EFT BİLDİR
            </Button>
            {showDepositAlert && (
              <Alert variant={error ? 'danger' : 'success'} className="mt-3">
                {depositStatusMessage} {/* Havale/EFT bildirimi mesajı */}
              </Alert>
            )}
          </Card.Body>
        </Card>

        <Card className="mb-3">
          <Card.Body className="text-center">
            <p className="mb-0">
              <strong>WAL39913569</strong> işlem numarasını açıklamaya ekleyerek aşağıdaki banka
              hesaplarımızdan dilediğinize Havale/EFT işlemi yapabilirsiniz.
            </p>
          </Card.Body>
        </Card>

        {bankOptions.map((bank) => (
          <Card className="mb-3" key={bank.id}>
            <Card.Body className="text-center">
              <img src={bank.logo} alt={`${bank.name} Logo`} style={{ width: '100px' }} />
              <p>HESAP ADI: {bank.name}</p>
              <p>IBAN: {bank.iban}</p>
              <Button
                variant="warning"
                className="w-100"
                onClick={() => navigator.clipboard.writeText(bank.iban)}
              >
                Kopyala
              </Button>
            </Card.Body>
          </Card>
        ))}

   
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default HavaleEftOffcanvas;
