import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { FaUser, FaTruck, FaFileAlt, FaBalanceScale, FaMapMarkerAlt } from 'react-icons/fa';
import { TbWorldShare } from 'react-icons/tb';
import WalletCard from './Wallet/WalletCard';  // WalletCard bileşenini import ettik

const Home = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [averageShipment, setAverageShipment] = useState(0);
  const [blockedBalance, setBlockedBalance] = useState(0);
  const [availableBalance, setAvailableBalance] = useState(0);

  // useEffect için API çağrısı yapıyoruz
  useEffect(() => {
    const fetchWalletData = async () => {
      try {
        const response = await fetch('/api/getWalletData');
        const data = await response.json();
        setTotalBalance(data.totalBalance);
        setAverageShipment(data.averageShipment);
        setBlockedBalance(data.blockedBalance);
        setAvailableBalance(data.availableBalance);
      } catch (error) {
        console.error('Error fetching wallet data:', error);
      }
    };

    fetchWalletData();
  }, []); // Yalnızca bileşen yüklendiğinde çalışacak şekilde boş bağımlılık dizisi

  return (
    <Container fluid>
      {/* Header Section */}
      {/* (Header kısmı daha önce kaldırıldı, kullanmıyorsanız çıkarabilirsiniz) */}

      {/* Main Content Section */}
      <Row className="my-3">
        {/* Wallet Card Bileşeni */}
        <WalletCard
          totalBalance={totalBalance}
          averageShipment={averageShipment}
          blockedBalance={blockedBalance}
          availableBalance={availableBalance}
        />
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 Kargo Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 KARGO</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaMapMarkerAlt style={{ marginRight: '5px' }} /> Durum
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Desi/Kg
                  </th>
                  <th style={{ width: '10%' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%',
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right',
              }}
              href="/kargo-olustur"
            >
              <FaTruck style={{ marginRight: '10px' }} />
              YENİ KARGO OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 E-Fatura Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 E-FATURA</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaFileAlt style={{ marginRight: '5px' }} /> Fatura No
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Tutar
                  </th>
                  <th style={{ width: '10%' }}>Tarih</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%',
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right',
              }}
              href="/efatura-olustur"
            >
              <FaFileAlt style={{ marginRight: '10px' }} />
              YENİ E-FATURA OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>

      {/* Full Border Between Sections */}
      <hr style={{ border: '1px solid #ddd', margin: '20px 0' }} />

      {/* Son 3 İhracat Tablosu */}
      <Row className="my-3">
        <Col md={12}>
          <div style={{ border: '1px solid #ddd', borderRadius: '10px', padding: '20px', backgroundColor: '#f8f9fa' }}>
            <h6 style={{ borderBottom: '1px solid #ddd', paddingBottom: '10px', marginBottom: '20px' }}>SON 3 İHRACAT</h6>
            <Table responsive>
              <thead>
                <tr>
                  <th style={{ width: '25%' }}>
                    <FaUser style={{ marginRight: '5px' }} /> Alıcı
                  </th>
                  <th style={{ width: '50%' }}>
                    <FaMapMarkerAlt style={{ marginRight: '5px' }} /> Durum
                  </th>
                  <th style={{ width: '15%' }}>
                    <FaBalanceScale style={{ marginRight: '5px' }} /> Desi/Kg
                  </th>
                  <th style={{ width: '10%' }}>Tutar</th>
                </tr>
              </thead>
              <tbody>
                {/* API ile veriler buraya gelecek */}
              </tbody>
            </Table>
            <Button
              variant="warning"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '25%',
                padding: '10px',
                borderRadius: '5px',
                marginTop: '10px',
                fontWeight: 'bold',
                float: 'right',
              }}
              href="/yurtdisi-kargo-olustur"
            >
              <TbWorldShare style={{ marginRight: '10px' }} />
              YENİ İHRACAT OLUŞTUR
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Home;
