import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Popover, Overlay, Form, Accordion, Table } from 'react-bootstrap';
import { FaFileDownload, FaSearch } from 'react-icons/fa';
import { AiOutlineUser } from 'react-icons/ai';
// import { BsCalendar, BsBarChart } from 'react-icons/bs';
import WalletCard from './Wallet/WalletCard'

const Invoices = () => {
  const [showPopover, setShowPopover] = useState(false);
  const target = useRef(null);

  return (
    <Container fluid className="py-4">
      {/* Üst Kısım */}
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6}>
          <h4 className="mb-0">FATURALARIM</h4>
        </Col>
        <Col xs={12} md={6} className="text-end">
          <Button ref={target} onClick={() => setShowPopover(!showPopover)} variant="warning">
            + Oluştur
          </Button>
          <Overlay
            show={showPopover}
            target={target.current}
            placement="bottom"
            containerPadding={20}
          >
            <Popover id="popover-contained">
              <Popover.Header as="h3">E-Fatura Oluştur</Popover.Header>
              <Popover.Body>
                {/* Popover içeriği */}
                <Card className="mb-3">
                  <Card.Header>Müşteri Seç, Ekle</Card.Header>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <strong>ERGÜN KUŞÇU</strong><br />
                        Kızılırmak Mh. Dumlupınar Bulv no: 3a/81<br />
                        Çankaya / ANKARA<br />
                        0850 304 0555
                      </div>
                      <div>
                        <Button variant="link" className="text-warning p-0">DÜZENLE</Button> | <Button variant="link" className="text-warning p-0">SİL</Button>
                      </div>
                    </div>
                    <Button variant="link" className="text-warning">+ YENİ ALICI ADRESİ EKLE</Button>
                  </Card.Body>
                </Card>

                <Card className="mb-3">
                  <Card.Header>Ürün Seç, Ekle</Card.Header>
                  <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <strong>Ürün Adı</strong><br />
                        500,00 ₺
                      </div>
                      <div>
                        <Button variant="link" className="text-warning p-0">DÜZENLE</Button> | <Button variant="link" className="text-warning p-0">SİL</Button>
                      </div>
                    </div>
                    <Button variant="link" className="text-warning">+ YENİ ÜRÜN EKLE</Button>
                  </Card.Body>
                </Card>

                <div className="text-end">
                  <Button variant="warning">Oluştur</Button>
                </div>
              </Popover.Body>
            </Popover>
          </Overlay>
        </Col>
      </Row>

      {/* Bakiye Bilgileri */}
      <Row className="align-items-stretch mb-4">
       <WalletCard />
      </Row>

      {/* Arama ve Filtreleme Bölümü */}
      <Accordion className="mb-4">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Arama, Filtreleme</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={4} className="d-flex">
                <Card className="flex-fill mb-3">
                  <Card.Header>Fatura Türüne Göre Filtrele</Card.Header>
                  <Card.Body>
                    <Form.Check type="radio" id="alıs-gider" label="Alış - Gider Faturaları (15)" name="faturaTuru" />
                    <Form.Check type="radio" id="satis-gelir" label="Satış - Gelir Faturaları (125)" name="faturaTuru" />
                    <Button variant="warning" className="w-100 mt-3">Filtrele</Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="d-flex">
                <Card className="flex-fill mb-3">
                  <Card.Header>Müşteri Isım Veya Telefon Ile Arama</Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Müşteri Isım / Soy Isım veya Telefon no Ile Arayın" />
                    </Form.Group>
                    <Button variant="warning" className="w-100 mt-3">
                      <FaSearch className="me-2" /> Ara
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="d-flex">
                <Card className="flex-fill mb-3">
                  <Card.Header>Tarih Aralığı Ile Filtrele</Card.Header>
                  <Card.Body>
                    <Form.Group>
                      <Form.Control type="text" placeholder="Başlangıç Tarihi" />
                    </Form.Group>
                    <Form.Group className="mt-3">
                      <Form.Control type="text" placeholder="Bitiş Tarihi" />
                    </Form.Group>
                    <Button variant="warning" className="w-100 mt-3">
                      <FaSearch className="me-2" /> Ara
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      {/* Faturalar Tablosu */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">FATURALAR</h5>
              <Button variant="outline-warning">
                <FaFileDownload className="me-2" /> Çıktı Al
              </Button>
            </Card.Header>
            <Card.Body>
              <Table bordered hover responsive>
                <thead>
                  <tr>
                    <th><AiOutlineUser size={20} /></th>
                    <th>Fatura No</th>
                    <th>Miktar</th>
                    <th>Tarih</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {/* Backend'den çekilecek veriler burada görüntülenecek */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Invoices;
