import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

const VerifyEmail = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      const token = new URLSearchParams(location.search).get('token'); // URL'den token'ı alın
      console.log('Token:', token); // Token'ı loglayın

      if (!token) {
        setMessage('Doğrulama için geçerli bir token bulunamadı.');
        return;
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/verify-email?token=${token}`);
        console.log('API Response:', response); // API yanıtını loglayın
        setMessage(response.data.msg);
      } catch (error) {
        console.error('API Error:', error); // Hata mesajını loglayın
        setMessage('E-posta doğrulama sırasında bir hata oluştu.');
      }
    };

    verifyEmail();
  }, [location]);

  const handleNavigate = () => {
    console.log('Navigating to /giriş-yap');
    navigate('/giriş-yap'); // Doğrulama sonrası giriş sayfasına yönlendirme
  };

  return (
    <div>
      <h1>E-posta Doğrulama</h1>
      <p>{message}</p>
      <button onClick={handleNavigate}>Giriş Yap</button>
    </div>
  );
};

export default VerifyEmail;
