import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import Ptt from '../../assets/images/Kargo/pttbg.webp';
import Ups from '../../assets/images/Kargo/upsbg.webp';
import Yurt from '../../assets/images/Kargo/yurtiçibg.webp';
import '../../assets/css/CalculateCargo.css';

const CalculateCargo = () => {
  const [dimensions, setDimensions] = useState({
    depth: '',
    width: '',
    height: '',
    weight: ''
  });

  const [finalWeight, setFinalWeight] = useState(null);
  const [finalPrice, setFinalPrice] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDimensions((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const calculateDesi = () => {
    const { depth, width, height, weight } = dimensions;
    if (depth && width && height && weight) {
      const desiValue = (depth * width * height) / 3000;
      const finalValue = Math.max(desiValue, parseFloat(weight));
      setFinalWeight(finalValue.toFixed(2));

      // Kargo fiyatlarını belirleme
      let price = 0;
      if (finalValue <= 1) {
        price = 75; // PTT
      } else if (finalValue <= 5) {
        price = 124; // Yurtiçi
      } else {
        price = 120; // UPS
      }
      setFinalPrice(price);
    }
  };

  return (
    <Container fluid className="calculate-cargo-container">
      <h4 className="mb-4">Kargo Paket Ölçülerini Giriniz.</h4>
      <Form>
        <Row className="mb-2">
          <Col>
            <Form.Control
              placeholder="Derinlik (Cm)"
              name="depth"
              value={dimensions.depth}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="Genişlik (Cm)"
              name="width"
              value={dimensions.width}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Row className="mb-1">
          <Col>
            <Form.Control
              placeholder="Yükseklik (Cm)"
              name="height"
              value={dimensions.height}
              onChange={handleChange}
            />
          </Col>
          <Col>
            <Form.Control
              placeholder="Ağırlık (Kg)"
              name="weight"
              value={dimensions.weight}
              onChange={handleChange}
            />
          </Col>
        </Row>
        <Button variant="warning" className="w-100" onClick={calculateDesi}>
          DESİ / FİYAT HESAPLA
        </Button>
      </Form>
      {finalWeight && (
        <h5 className="mt-4">{finalWeight} KG</h5>
      )}
      {finalPrice && (
        <h5 className="mt-4">Kargo Ücreti: {finalPrice} ₺</h5>
      )}
      <div className="result-container mt-3">
        <div className="result-item">
          <img src={Ptt} alt="PTT Kargo" className="result-logo" />
          <span>75,00₺</span>
          <span>KDV Dahil</span>
        </div>
        <div className="result-item">
          <img src={Yurt} alt="Yurtiçi Kargo" className="result-logo" />
          <span>124,00₺</span>
          <span>KDV Dahil</span>
        </div>
        <div className="result-item">
          <img src={Ups} alt="UPS Kargo" className="result-logo" />
          <span>120,00₺</span>
          <span>KDV Dahil</span>
        </div>
      </div>
    </Container>
  );
};

export default CalculateCargo;
