import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { FaHome, FaFileInvoice, FaWhatsapp, FaUsers, FaChartBar, FaCog, FaBoxes, FaUser, FaBell } from 'react-icons/fa';
import { MdAccountBalanceWallet } from 'react-icons/md';
import { TbWorldShare } from 'react-icons/tb';
import logo from '../assets/images/Kargo/indirimli.png';
import { useSelector } from 'react-redux';  // Redux'tan userId'yi almak için

const Dashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [companyName, setCompanyName] = useState(''); // Şirket adı için state
  const [error, setError] = useState('');
  
  // Backend'deki API URL
  const apiUrl = process.env.REACT_APP_API_URL;

  // Redux'tan userId'yi al
  const userId = useSelector((state) => state.user.userId);

  // fetch kullanarak kullanıcı oturumunu doğrulayan frontend kodu
  useEffect(() => {
    console.log('Kullanıcı ID:', userId);

    const fetchCompanyName = async () => {
      console.log('API URL:', apiUrl); // API URL'yi loglayalım

      try {
        console.log('Şirket adı alma isteği başlatılıyor...');

        // API isteği yapılıyor
        const response = await fetch(`${apiUrl}/user/${userId}/dashboard`, {
          method: 'GET',
          credentials: 'include', // Çerezleri dahil etmek için gerekli
        });

        console.log('İstek yapıldı, yanıt bekleniyor...');

        // HTTP yanıt durumu kontrol ediliyor
        if (!response.ok) {
          console.error(`HTTP error! status: ${response.status}`);
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log('Veri alındı:', data);

        // Şirket adını state'e set ediyoruz
        if (data.companyName) {
          setCompanyName(data.companyName);
          console.log('CompanyName state\'e başarıyla set edildi:', data.companyName);
        } else {
          console.warn('Yanıtta şirket adı bulunamadı.');
          setError('Yanıtta şirket adı bulunamadı.');
        }
      } catch (error) {
        console.error('Şirket adı alınırken bir hata oluştu:', error.message);
        setError('Şirket adı alınırken bir hata oluştu.');
      }
    };

    // Eğer userId varsa şirket adını alma işlemi başlatılıyor
    if (userId) {
      console.log('Veri alma fonksiyonu çalıştırılıyor...');
      fetchCompanyName();
    }
  }, [apiUrl, userId]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    console.log(`Sidebar ${isSidebarOpen ? 'kapatıldı' : 'açıldı'}`);
  };

  const popover = (
    <Popover
      id="popover-help"
      style={{ border: '1px solid #ccc', borderRadius: '10px', padding: '15px', width: '250px' }}
    >
      <Popover.Header as="h3" style={{ borderBottom: 'none', textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
        Yardım
      </Popover.Header>
      <Popover.Body>
        <div style={{ textAlign: 'center', fontSize: '14px' }}>
          Bizimle <FaWhatsapp style={{ color: '#25D366' }} /> WhatsApp üzerinden <br />
          <strong style={{ color: 'red' }}>0850 304 0 555</strong> <br />
          numarası ile iletişime geçebilirsiniz.
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <Container fluid className="bg-light" style={{ paddingTop: '70px' }}>
      <Row>
        <Col lg={isSidebarOpen ? 2 : 1} md={isSidebarOpen ? 3 : 1} className="bg-dark text-white p-3 sidebar" style={{ minHeight: '100vh' }}>
          <Button variant="outline-light" className="mb-3" onClick={toggleSidebar}>
            {isSidebarOpen ? '‹' : '›'}
          </Button>
          <div className="text-center mb-3">
            <img src={logo} alt="İndirimli Kargo Logo" className="img-fluid mb-2" style={{ width: isSidebarOpen ? '100px' : '50px', transition: 'width 0.3s' }} />
            {isSidebarOpen && (
              <>
                <h5>İNDİRİMLİ KARGO</h5>
                <p style={{ backgroundColor: 'yellow', color: 'black', padding: '5px', borderRadius: '5px' }}>
                  {companyName || 'Yükleniyor...'}
                </p>
              </>
            )}
          </div>
          <ListGroup variant="flush">
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim" className="text-white d-flex align-items-center">
                <FaHome className="me-2" /> {isSidebarOpen && 'Ana Sayfa'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/account" className="text-white d-flex align-items-center">
                <FaUser className="me-2" /> {isSidebarOpen && 'Hesabım'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/shipments" className="text-white d-flex align-items-center">
                <FaBoxes className="me-2" /> {isSidebarOpen && 'Kargolar'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/invoices" className="text-white d-flex align-items-center">
                <FaFileInvoice className="me-2" /> {isSidebarOpen && 'Faturalarım'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/wallet" className="text-white d-flex align-items-center">
                <MdAccountBalanceWallet className="me-2" /> {isSidebarOpen && 'Cüzdanım'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/customers" className="text-white d-flex align-items-center">
                <FaUsers className="me-2" /> {isSidebarOpen && 'Müşterilerim'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/payments" className="text-white d-flex align-items-center">
                <FaChartBar className="me-2" /> {isSidebarOpen && 'Online Ödemeler'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/export" className="text-white d-flex align-items-center">
                <TbWorldShare className="me-2" /> {isSidebarOpen && 'İhracat (Yurtdışı Gönderi)'}
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="bg-dark border-0 text-white">
              <Link to="/panelim/integration" className="text-white d-flex align-items-center">
                <FaCog className="me-2" /> {isSidebarOpen && 'API Entegrasyon'}
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col lg={isSidebarOpen ? 10 : 11} md={isSidebarOpen ? 9 : 11}>
          <Row className="align-items-center py-1" style={{ borderBottom: '1px solid #ddd', marginLeft: isSidebarOpen ? '20px' : '10px' }}>
            <Col xs={6} md={6} lg={4} className="text-start">
              <h5 className="mb-0" style={{ paddingLeft: '20px' }}>İNDİRİMLİ KARGO</h5>
            </Col>

            <Col xs={6} md={6} className="d-flex align-items-center justify-content-end">
              <a href="/profile" className="me-2 text-dark">
                <FaUser size={20} />
              </a>
              <a href="/notifications" className="me-2 text-dark">
                <FaBell size={20} />
              </a>
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <span
                  style={{
                    color: '#000',
                    textDecoration: 'none',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '5px 10px',
                    cursor: 'pointer',
                  }}
                  className="me-2"
                >
                  Yardım ?
                </span>
              </OverlayTrigger>
            </Col>
          </Row>

          <div className="content p-4" style={{ borderLeft: '1px solid #ddd' }}>
            {error && <p className="text-danger">{error}</p>} {/* Hata mesajı */}
            <Outlet />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
