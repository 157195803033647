import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Offcanvas, Button } from 'react-bootstrap';
import { FaArrowDown } from 'react-icons/fa';
import pttLogo from '../assets/images/Kargo/pttbg.webp';

import upsLogo from '../assets/images/Kargo/upsbg.webp';
import yurtiLogo from '../assets/images/Kargo/yurtiçibg.webp';
import '../assets/css/Price.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import PttDetails from './Calculate/PttDetails';
import UpsDetails from './Calculate/UpsDetails';
import YurticiDetails from './Calculate/YurtDetails';

const CargoPricing = () => {
  const [activeOffcanvas, setActiveOffcanvas] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const cards = document.querySelectorAll('.custom-card');
      cards.forEach(card => {
        const rect = card.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom > 0) {
          card.classList.remove('spin-card'); // Animasyon sınıfını kaldır
          void card.offsetWidth; // Reflow zorlaması yaparak animasyonu sıfırla
          card.classList.add('spin-card'); // Animasyonu yeniden başlat
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Sayfa yüklendiğinde animasyonu başlat

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const cargos = [
    { logo: pttLogo, price: "50₺", name: "Ptt", description: "+ KDV BAŞLAYAN FİYATLARLA", offcanvasKey: 'ptt' },
    { logo: upsLogo, price: "80₺", name: "UPS", description: "+ KDV BAŞLAYAN FİYATLARLA", offcanvasKey: 'ups' },
    { logo: yurtiLogo, price: "100₺", name: "Yurtiçi Kargo", description: "+ KDV BAŞLAYAN FİYATLARLA", offcanvasKey: 'yurti' },
  ];

  const handleShowOffcanvas = (offcanvasKey) => {
    setActiveOffcanvas(offcanvasKey);
  };

  const handleCloseOffcanvas = () => {
    setActiveOffcanvas(null);
  };

  return (
    <Container fluid className="pricing-cargo py-1 text-light" id='fiyatlar' style={{ borderBottom: '2px solid #6c757d' }}>
      <h2 className="text-center mb-1">ANLAŞMALI</h2>
      <h3 className="text-center mb-1">Kargo Fiyatları</h3>
      <Row className="justify-content-center">
        {cargos.map((cargo, index) => (
          <Col key={index} xs={12} sm={6} md={4} lg={3} className="pricing-cards mb-4 d-flex justify-content-center">
            <Card className="custom-card text-center" style={{ width: '15rem', height: '325px', borderRadius: '150px', padding: '1rem' }}>
              <Card.Img variant="top" src={cargo.logo} style={{ margin: '0 auto' }} />
              <Card.Body className="d-flex flex-column justify-content-center">
                <Card.Title style={{ fontSize: '2rem', color: 'white' }}>{cargo.price}</Card.Title>
                <Card.Text className="mb-3">{cargo.description}</Card.Text>
                <Button variant="link" className="text-warning p-1" onClick={() => handleShowOffcanvas(cargo.offcanvasKey)}>
                  TÜM FİYATLARI GÖR
                  <FaArrowDown className="arrow-icon" />
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <div style={{ borderTop: '1px solid black', marginTop: '40px' }}></div>

      {activeOffcanvas && (
        <Offcanvas show={true} onHide={handleCloseOffcanvas} placement="end" className="custom-offcanvas">
          {activeOffcanvas === 'ptt' && <PttDetails />}
          {activeOffcanvas === 'ups' && <UpsDetails />}
          {activeOffcanvas === 'yurti' && <YurticiDetails />}
        </Offcanvas>
      )}
    </Container>
  );
};

export default CargoPricing;
