import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../Footer';

const SalesAgreement = () => {
  return (
    <Container fluid className="px-4 py-3 mt-5"> {/* üstten boşluk için mt-5 eklendi */}
      <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F', marginTop: '80px' }}>Aydınlatma ve Açık Rıza Metni</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <p style={{ color: 'white' }}>
            Sanatkardan E-Ticaret İnternet Mağazacılık Sanayi Ticaret Limited Şirketi (“Sanatkardan” / ‘’Şirket’’) olarak kişisel verilerinizin korunması ve özel hayatınızın mahremiyeti bizler için son derece hassas ve önemlidir. 6698 sayılı Kişisel Verilerin Korunması Kanunu kapsamında Kişisel Verileri Koruma ve İşleme Politikası (‘’Politika’’) gereği aşağıda özetle belirtilen veri işleme faaliyetlerimize istinaden tarafınızın açık rızası aranmaktadır.
          </p>
          <p style={{ color: 'white' }}>
            Bu bağlamda, sistemimize kayıt olma aşamasında isim, soy isim, adres, telefon numarası, elektronik posta, doğum tarihine ait kişisel verilerinizi ve sisteme kaydınız sonrasında yaptığınız işlemler sırasında beyan ettiğiniz Politika’nın 7.A maddesinde belirtilen kişisel verileri, buradan tamamına ulaşabileceğiniz <strong style={{ color: '#EFB02F' }}>‘Kişisel Verileri İşleme ve Koruma Politikamız’</strong> (‘’Politika’’) kapsamında, öncelikle kayıt işlemlerinin tamamlanması, talep ve şikayetlerinizin incelenip tarafınızla iletişim sağlanabilmesi, ticari elektronik ileti onayınızı takiben size özel kampanyalar ve tanıtımlar sunulabilmesi ve Politika’nın 8. maddesinde yer alan diğer amaçlar için işlenip gene Politikada 9. Maddesinde belirtilen 3. kişi ve kurumlara aktarılabilmektedir.
          </p>
        </Col>
      </Row>
      <Footer/>
    </Container>
  );
};

export default SalesAgreement;

