import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import logo from '../../assets/images/Kargo/indirimli.png';

const CreatePage = () => {
  return (
    <Container fluid className="py-4">
      {/* Header Section */}
      <Row className="align-items-center mb-4">
        <Col>
          <div className="d-flex align-items-center">
            <img src={logo} alt="İndirimli Kargo" style={{ width: '32px', marginRight: '8px' }} />
            <h5 className="mb-0">TEBRİKLER YURTDIŞI KARGONUZ BAŞARIYLA OLUŞTURULDU!</h5>
          </div>
          <p className="text-muted">Yurtdışı Kargolarım sayfanıza eklendi!</p>
        </Col>
      </Row>

      {/* Info Section */}
      <Row className="mb-4">
        <Col>
          <Card className="bg-light p-3">
            <Card.Body>
              <p className="mb-0">
                <strong>Ürünlerinizi Aşağıdan Barkod çıkışı alarak veya Referans no belirterek, MNG kargo firmasının dilediğiniz şubesine SANATKARDAN E-Ticaret firmasını belirterek, teslim edebilirsiniz. Ürünler tarafımızca ulaştığında, gerekli işlemler yapılarak en hızlı şekilde alıcısına gönderilir. Yurtdışı gönderiler, genellikle 3-5 gün içerisinde alıcısına teslim edilir.</strong>
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Kargo Details Section */}
      <Row className="mb-4">
        <Col xs={12} className="mb-3">
          <Card className="p-3">
            <h6>MNG KARGO</h6>
            <p className="mb-2">Kampanyalar Sanatkardan ekranından Ücretsiz gönderim yapılabilirsiniz</p>
            <p className="text-muted mb-3">Referans No: 145896325 <i className="bi bi-clipboard-check"></i></p>
            <div className="d-flex">
              <Button variant="warning" className="me-2 w-50">Barkod Yazdır</Button>
              <Button variant="warning" className="w-50">E-Fatura Oluştur</Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePage;
