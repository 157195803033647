import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Button, Offcanvas } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const AddressForm = ({ show, handleClose, editAddress = null, setUpdateTrigger }) => {
  const userId = useSelector((state) => state.user.userId);
  const storeId = useSelector((state) => state.user.storeId);

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    cityName: '',
    districtName: '',
    fullAddress: '',
    postalCode: '',
    taxNumber: '1111111111',
    taxOffice: '',
    isCorporate: true,
    isEInvoice: false, // Başlangıçta false
    corporateTitle: '',
    phoneCode: '',
  });

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    if (editAddress) {
      setFormData({
        firstname: editAddress.firstname || '',
        lastname: editAddress.lastname || '',
        phone: editAddress.phone || '',
        email: editAddress.email || '',
        cityName: editAddress.cityName || '',
        districtName: editAddress.districtName || '',
        fullAddress: editAddress.fullAddress || '',
        postalCode: editAddress.postalCode || '',
        taxNumber: editAddress.taxNumber || '1111111111',
        taxOffice: editAddress.taxOffice || '',
        isCorporate: editAddress.isCorporate ?? true,
        isEInvoice: editAddress.isEInvoice || false,
        corporateTitle: editAddress.corporateTitle || '',
        phoneCode: editAddress.phoneCode || '',
      });
    } else {
      setFormData({
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        cityName: '',
        districtName: '',
        fullAddress: '',
        postalCode: '',
        taxNumber: '1111111111',
        taxOffice: '',
        isCorporate: true,
        isEInvoice: false,
        corporateTitle: '',
        phoneCode: '',
      });
    }
  }, [editAddress]);

  useEffect(() => {
    axios
      .get('https://turkiyeapi.dev/api/v1/provinces')
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error('İl listesi alınamadı:', error);
      });
  }, []);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    const selectedCity = cities.find((city) => city.id === parseInt(cityId));
    setFormData({ ...formData, cityId: selectedCity.id, cityName: selectedCity.name });

    axios
      .get(`https://turkiyeapi.dev/api/v1/provinces/${cityId}`)
      .then((response) => {
        setDistricts(response.data.data.districts);
      })
      .catch((error) => {
        console.error('İlçe listesi alınamadı:', error);
      });
  };

  const handleDistrictChange = (e) => {
    const districtId = e.target.value;
    const selectedDistrict = districts.find((district) => district.id === parseInt(districtId));
    setFormData({ ...formData, districtName: selectedDistrict.name });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Eğer bir checkbox ise, checked durumunu kullan
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("Form gönderim işlemi başlatıldı.");

    try {
      // Form verileri kullanıcı ID'si ve mağaza ID'si ile birleştiriliyor
      const formDataWithUserId = { ...formData, userId, storeId };
      console.log('Gönderilecek form verileri:', JSON.stringify(formDataWithUserId, null, 2));

      let response;

      // Eğer adres güncelleniyorsa
      if (editAddress) {
        console.log(`Adres güncelleniyor, ID: ${editAddress._id}`);
        response = await axios.put(
          `${process.env.REACT_APP_API_URL}/address/${editAddress._id}`,
          formDataWithUserId,
          { withCredentials: true }
        );
        console.log('Adres güncelleme isteği gönderildi.');
      } else {
        // Yeni adres ekleniyorsa
        console.log('Yeni adres ekleniyor.');
        response = await axios.post(
          `${process.env.REACT_APP_API_URL}/address`,
          formDataWithUserId,
          { withCredentials: true }
        );
        console.log('Yeni adres ekleme isteği gönderildi.');
      }

      // API yanıtı alındıktan sonra
      console.log('Sunucudan yanıt alındı:', JSON.stringify(response.data, null, 2));
      alert(editAddress ? 'Adres başarıyla güncellendi!' : 'Adres başarıyla eklendi!');

      // Adres listesinin güncellenmesi için tetikleyici ayarlandı
      setUpdateTrigger((prev) => !prev);
      console.log("Adres listesi güncelleme tetikleyici değiştirildi.");

      // Offcanvas kapatılıyor
      handleClose();
      console.log("Offcanvas kapatıldı.");

    } catch (error) {
      // Hata durumunda loglama
      console.error('Adres işlemi sırasında hata oluştu:', error);
      alert('Adres eklenirken hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ backgroundColor: '#f0f0f0' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-center w-100" style={{ color: 'white' }}>
          {editAddress ? 'ADRESİ DÜZENLE' : 'YENİ ADRES EKLE'}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
        <Form onSubmit={onSubmit} className="text-center">
          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="firstname">
                <Form.Label>Ad</Form.Label>
                <Form.Control
                  type="text"
                  name="firstname"
                  value={formData.firstname}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="lastname">
                <Form.Label>Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="lastname"
                  value={formData.lastname}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label>Telefon No</Form.Label>
                <PhoneInput
                  country={'tr'}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: '100%',  // Alanı tamamen kapla
                    padding: '8px',
                    textAlign: 'center',
                    height: '43px',
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label>E-posta</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="city">
                <Form.Label>İl</Form.Label>
                <Form.Select
                  name="city"
                  value={formData.cityId}
                  onChange={handleCityChange}
                  required
                  className="p-2"
                >
                  <option>İl Seçin</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} className="mb-3">
              <Form.Group controlId="districtName">
                <Form.Label>İlçe</Form.Label>
                <Form.Select
                  name="districtName"
                  value={formData.districtNameId}
                  onChange={handleDistrictChange}
                  required
                  className="p-2"
                >
                  <option>İlçe Seçin</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="fullAddress" className="mt-3 mb-3 text-center">
            <Form.Label>Tam Adres</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="fullAddress"
              value={formData.fullAddress}
              onChange={handleInputChange}
              required
              className="p-2"
              style={{ textAlign: 'center', width: '300px', margin: '0 auto' }}
            />
          </Form.Group>

          <Form.Group controlId="postalCode" className="mt-3 mb-3 text-center">
            <Form.Label>* POSTA KODU</Form.Label>
            <Form.Control
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleInputChange}
              required
              className="p-2"
              style={{ textAlign: 'center', width: '300px', margin: '0 auto' }}
            />
          </Form.Group>

          <Form.Group className="mt-3 mb-3 d-flex justify-content-center align-items-center">
            <Form.Check
              type="checkbox"
              name="isEInvoice"
              checked={formData.isEInvoice} // Checkbox durumu burada yönetilir
              onChange={handleInputChange} // Değişiklik durumunu handleInputChange ile yakala
              label="E-Fatura Mükellefiyim"
            />
          </Form.Group>

          <h5 className="mt-4">Kurumsal Bilgiler (Opsiyonel)</h5>
          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} lg={10} className="mb-3">
              <Form.Group controlId="corporateTitle">
                <Form.Label>Ünvan</Form.Label>
                <Form.Control
                  type="text"
                  name="corporateTitle"
                  value={formData.corporateTitle}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} lg={10} className="mb-3">
              <Form.Group controlId="taxNumber">
                <Form.Label>Vergi veya TC. No</Form.Label>
                <Form.Control
                  type="text"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group controlId="taxOffice" className="mt-3 text-center">
            <Form.Label>Vergi Dairesi</Form.Label>
            <Form.Control
              type="text"
              name="taxOffice"
              value={formData.taxOffice}
              onChange={handleInputChange}
              className="p-2"
              style={{ textAlign: 'center', width: '300px', margin: '0 auto' }}
            />
          </Form.Group>

          <Button
            variant="warning"
            type="submit"
            className="mt-3"
            style={{
              width: '50%',
              margin: '0 auto',
              display: 'block',
              fontSize: '16px',
              padding: '10px 0',
            }}
          >
            {editAddress ? 'GÜNCELLE' : 'EKLE'}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddressForm;
