import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './Dashboard';
import Home from './Home';
import Account from './Account';
import Shipments from './Shipments';
import Invoices from './Invoices';
import Wallet from './Wallet';
import Customers from './Customers';
import Payments from './Payments';
import Export from './Export';
import Integration from './Integration';
import Profile from './Account/Profile';
import Address from './Account/Address';
import Contract from './Account/Contract';
import Remove from './Account/Remove';
import ManuelShipments from './Shipments/ManuelShipments';
import MeTo from './Shipments/Meto';
import VerifyEmail from './Account/VerifyEmail';
import PaymentSuccess from '../Panel/Wallet/PaymentSuccess'; // Başarı sayfası
import PaymentFailure from '../Panel/Wallet/PaymentFailure'; // Hata sayfası
import PaymentEftSuccess from './Wallet/PaymentEftSuccess';

function DashboardRouter() {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />}>
        <Route index element={<Home />} />
        <Route path="account" element={<Account />} />
        <Route path="shipments" element={<Shipments />} />
        <Route path="invoices" element={<Invoices />} />
        <Route path="wallet" element={<Wallet />} />
        <Route path="customers" element={<Customers />} />
        <Route path="payments" element={<Payments />} />
        <Route path="export" element={<Export />} />
        <Route path="integration" element={<Integration />} />
        <Route path="profile" element={<Profile />} /> 
        <Route path="adreslerim" element={<Address />} /> 
        <Route path="sözleşmelerim" element={<Contract />} /> 
        <Route path="kaldır" element={<Remove />} /> 
        <Route path="manuel-ekle" element={<ManuelShipments />} /> 
        <Route path="bana-gelecek" element={<MeTo />} />  
        <Route path="mail-doğrulama" element={<VerifyEmail />} />
        <Route path="/payment-success" element={<PaymentSuccess/>} />
        <Route path="/payment-failure" element={<PaymentFailure/>} />
         <Route path="/payment-eftsuccess" element={<PaymentEftSuccess/>} />
      </Route>
    </Routes>
  );
}

export default DashboardRouter;
