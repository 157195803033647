import React from 'react';
import { Container, Row, Col , Table} from 'react-bootstrap';
import Footer from '../Footer';

const CookiePolicy = () => {
  return (
    <Container fluid className="px-4 py-3 mt-5">
      <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F', marginTop: '80px' }}>Gizlilik ve Çerez Politikası</h2>
          {/* <p className="text-center" style={{ color: 'white' }}>Güncellenme Tarihi: 22.05.2020</p> */}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Gizlilik Politikası</h4>
          <p style={{ color: 'white' }}>
            Sanatkardan E-Ticaret İnternet Mağazacılık Sanayi Ticaret Limited Şirketi (“Sanatkardan” / ‘’Şirket’’) olarak kişisel verilerinizin korunması ve güvenliğiniz bizler için de son derece hassas ve önemlidir. Bu bağlamda siz değerli kullanıcılarımızın gizliliğini korumak amacıyla 6698 sayılı Kişisel Verilerin Korunması Kanunu (‘Kanun’) kapsamında iş bu Gizlilik ve Çerez Politikası düzenlenerek sitemizde yayınlandığı tarih itibariyle yürürlüğe girmiştir.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin İşlenme Amaçları</h4>
          <p style={{ color: 'white' }}>
            Şirketimiz Kişisel Verileri Kanunun öngördüğü şekilde belli, açık ve meşru amaçlarla işlemektedir. Bu kapsamda Kişisel Veriler aşağıda sayılan amaçlarla işlenebilmektedir:
          </p>
          <ul style={{ color: 'white' }}>
            <li>•Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası</li>
            <li>•Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi</li>
            <li>•Çalışanların iş faaliyetlerinin takibi ve/veya denetimi</li>
            <li>•Çalışanların bilgiye erişim yetkilerinin planlanması ve icrası</li>
            <li>•Finans ve/veya muhasebe işlerinin takibi</li>
            <li>•Hukuk işlerinin takibi</li>
            <li>•İnsan kaynakları süreçlerinin planlanması</li>
            <li>•İş faaliyetlerinin etkinlik/verimlilik ve/veya yerindelik analizlerinin gerçekleştirilmesi faaliyetlerinin planlanması ve/veya icrası</li>
            <li>•İş faaliyetlerinin planlanması ve icrası</li>
            <li>•İş ortakları ve/veya tedarikçilerin bilgiye erişim yetkilerinin planlanması ve icrası</li>
            <li>•İş ortakları ve/veya tedarikçilerle olan ilişkilerin yönetimi</li>
            <li>•İş sağlığı ve/veya güvenliği süreçlerinin planlanması ve/veya icrası</li>
            <li>•İş sürekliliğinin sağlanması faaliyetlerinin planlanması ve/veya icrası</li>
            <li>•Lojistik faaliyetlerinin planlanması ve icrası</li>
            <li>•Müşteri ilişkileri yönetimi süreçlerinin planlanması ve icrası</li>
            <li>•Müşteri memnuniyeti aktivitelerinin planlanması ve/veya icrası</li>
            <li>•Müşteri talep ve/veya şikayetlerinin takibi</li>
            <li>•Personel temin süreçlerinin yürütülmesi</li>
            <li>•Şirket çalışanları için iş akdi ve/veya mevzuattan kaynaklı yükümlülüklerin yerine getirilmesi</li>
            <li>•Şirket dışı eğitim faaliyetlerinin planlanması ve icrası</li>
            <li>•Şirket faaliyetlerinin şirket prosedürleri ve/veya ilgili mevzuata uygun olarak yürütülmesinin temini için gerekli operasyonel faaliyetlerinin planlanması ve icrası</li>
            <li>•Şirket içi eğitim faaliyetlerinin planlanması ve/veya icrası</li>
            <li>•Şirket içi oryantasyon aktivitelerinin planlanması ve icrası</li>
            <li>•Şirketin sunduğu ürün ve/veya hizmetlere bağlılık oluşturulması ve/veya arttırılması süreçlerinin planlanması ve/veya icrası</li>
            <li>•Sözleşme süreçlerinin ve/veya hukuki taleplerin takibi</li>
            <li>•Stratejik planlama faaliyetlerinin icrası</li>
            <li>•Tedarik zinciri yönetimi süreçlerinin planlanması ve icrası</li>
            <li>•Ücret yönetimi</li>
            <li>•Üretim ve/veya operasyon süreçlerinin planlanması ve icrası</li>
            <li>•Ürün ve hizmetlerin satış ve pazarlaması için pazar araştırması faaliyetlerinin planlanması ve icrası</li>
            <li>•Ürün ve/veya hizmetlerin pazarlama süreçlerinin planlanması ve icrası</li>
            <li>•Ürün ve/veya hizmetlerin satış süreçlerinin planlanması ve icrası</li>
            <li>•Verilerin doğru ve güncel olmasının sağlanması</li>
            <li>•Yetkili kuruluşlara mevzuattan kaynaklı bilgi verilmesi</li>
            <li>•Ziyaretçi kayıtlarının oluşturulması ve takibi</li>
          </ul>
        </Col>
      </Row>
       <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>İşlenen Veriler</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <Table striped bordered hover variant="dark" responsive>
            <thead>
              <tr>
                <th>Veri Kategorisi</th>
                <th>Açıklama</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Kimlik Bilgisi</td>
                <td>	
Ehliyet, nüfus cüzdanı, ikametgâh, pasaport, avukatlık kimliği, evlilik cüzdanı gibi dokümanlarda yer alan bilgiler (örn. TCKN, pasaport no., nüfus cüzdanı seri no., ad-doyad, fotoğraf, doğum yeri, doğum tarihi, yaş, nüfusa kayıtlı olduğu yer, avukuatlı nüfus cüzdanı örneği)</td>
              </tr>
              <tr>
                <td>İletişim Bilgisi</td>
                <td>Kişiyle iletişim kurulması amacıyla kullanılan bilgiler (örn. e-mail adresi, telefon numarası, cep telefonu numarası, adres)</td>
              </tr>
              <tr>
                <td>Lokasyon Verisi</td>
                <td>Veri sahibinin konumunu tespit etmeye yarayan veriler (örn. araç kullanımı sırasında edinilen lokasyon verileri)</td>
              </tr>
              <tr>
                <td>Müşteri Bilgisi</td>
                <td>Ürün ve hizmetlerimizden faydalanan müşterilere ait bilgiler (örn. müşteri no, meslek bilgisi, vb.)</td>
              </tr>
              <tr>
                <td>Müşteri İşlem Bilgisi</td>
                <td>Ürün ve hizmetlerimizden faydalanan müşteriler tarafından gerçekleştirilen her türlü işleme ilişkin bilgiler (örn. talep ve talimatlar, sipariş ve sepet bilgileri, vb.)</td>
              </tr>
              <tr>
                <td>İşlem Güvenliği Bilgisi</td>
                <td>Şirketimiz ve ilgili tarafların teknik, idari, hukuki ve ticari güvenliğini sağlamak amacıyla işlenen kişisel veriler (örn. kişisel veri sahibiyle ilişkilendirilen işlem ile o kişiyi eşleştirmeye ve kişinin o işlemi yapmaya yetkili olduğunu gösteren Internet sitesi şifre ve parola gibi bilgiler)</td>
              </tr>
              <tr>
                <td>Risk Yönetimi Bilgisi</td>
                <td>Şirketimizin ticari, teknik ve idari risklerini yönetebilmek için işlenen kişisel veriler (örn. IP adresi, Mac ID vb. kayıtlar)</td>
              </tr>
              <tr>
                <td>Finansal Bilgi</td>
                <td>	
Kişisel veri sahibi ile mevcut hukuki ilişkinin tipine göre yaratılan her türlü finansal sonucu gösteren bilgi, belge ve kayıtlar kapsamındaki kişisel veriler (Örneğin: veri sahibinin yapmış olduğu işlemlerin finansal sonucunu gösteren bilgiler, kart bilgisi, ödenecek faiz tutarı ve oranı, borç bakiyesi, alacak bakiyesi vb.)</td>
              </tr>
              <tr>
                <td>Özlük Bilgisi</td>
                <td>Şirket’in tedarikçilerinin çalışanlarının özlük haklarının oluşmasına temel olan kişisel veriler (kanunen özlük dosyasına girmesi gereken her türlü bilgi ve belge)</td>
              </tr>
              <tr>
                <td>Çalışan Adayı Bilgisi</td>
                <td>İş başvurusu yapmak üzere bilgilerini paylaşan veri sahiplerine ait, başvuru değerlendirme sürecinde kullanılan kişisel veriler (örn. özgeçmiş, mülakat notları vb.)</td>
              </tr>
              <tr>
                <td>Çalışan İşlem Bilgisi</td>
                <td>Şirket’in tedarikçi çalışanlarının işle ilgili gerçekleştirdiği her türlü işleme ilişkin kişisel veriler (örn. işe giriş-çıkış kayıtları, iş seyahatleri, katıldığı toplantılara ilişkin bilgiler, güvenlik sorgusu, e-posta trafikleri izleme bilgisi, araç kullanım bilgisi, şirket kartı harcama bilgisi</td>
              </tr>
              <tr>
                <td>Çalışan Performans ve Kariyer Gelişim Bilgisi</td>
                <td>	
Şirket’in tedarikçi çalışanlarının performanslarının ölçülmesi ve kariyer gelişimlerinin insan kaynakları politikaları kapsamında planlanması ve yürütülmesi amacıyla işlenen kişisel veriler (örn. performans değerlendirme raporları, mülakat sonuçları, kariyer gelişimine yönelik eğitimler)</td>
              </tr>
              <tr>
                <td>Yan Haklar ve Menfaatler Bilgisi</td>
                <td>Şirket’in tedarikçi çalışanlarına sunulan yan hak ve menfaatlerinin takibinin yapılması ve tedarikçi çalışanlarının bunlardan faydalandırılmasına yönelik işlenen kişisel veriler (örn. özel sağlık sigortası, araç tahsisi)</td>
              </tr>
              <tr>
                <td>Pazarlama Bilgisi</td>
                <td>	
Şirketimiz tarafından pazarlama faaliyetlerinde kullanılacak veriler (örn. pazarlama amacıyla kullanılmak üzere toplanan kişinin alışkanlıkları, beğenilerini gösteren raporlar ve değerlendirmeler, hedefleme bilgileri, cookie kayıtları, veri zenginleştirme faaliyetleri)</td>
              </tr>
              <tr>
                <td>Hukuki İşlem ve Uyum Bilgisi</td>
                <td>Hukuki alacak ve hakların tespiti ve takibi ile borç ve kanuni yükümlülüklerin ifası amacıyla işlenen kişisel veriler (örn. mahkeme ve idari merci kararı gibi belgelerde yer alan veriler)</td>
              </tr>
              <tr>
                <td>Denetim ve Teftiş Bilgisi</td>
                <td>	
Şirketimizin kanuni yükümlülükleri ve şirket politikalarına uyumu kapsamında işlenen kişisel veriler (örn. denetim ve teftiş raporları, ilgili görüşme kayıtları ve benzeri kayıtlar)</td>
              </tr>
              <tr>
                <td>Özel Nitelikli Kişisel Veri</td>
                <td>Kişilerin ırkı, etnik kökeni, siyasi düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile biyometrik ve genetik verileri </td>
              </tr>
              <tr>
                <td>Talep/Şikayet Yönetimi Bilgisi</td>
                <td>Şirketimize yöneltilmiş olan her türlü talep veya şikayetin alınması ve değerlendirilmesine ilişkin kişisel veriler (örn. Şirket’e yönelik talep ve şikayetler, bunlarla ilgili kayıt ve raporlar)</td>
              </tr>
              <tr>
                <td>Görsel ve İşitsel Veri</td>
                <td>Kişisel veri sahibiyle ilişkilendirilen görsel ve işitsel kayıtlar (örn. fotoğraflar, kamera kayıtları ve ses kayıtları)</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>

        <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Kişisel Verilerin Aktarılması</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin Yurt İçine Aktarılması</h4>
          <p style={{ color: 'white' }}>
            Kanun’un 8. maddesi uyarınca Kişisel Veriler kural olarak, Veri Sahibinin açık rızası olmaksızın üçüncü kişilere aktarılamaz. Ancak Politikanın 5. Maddesinde belirtilen veri işleme şartlarından en az birinin varlığı halinde ve veri işleme şartlarına ilişkin temel ilkelere uymak şartıyla kişisel verileriniz Şirketimiz tarafından aktarılabilmektedir.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin Yurt Dışına Aktarılması</h4>
          <p style={{ color: 'white' }}>
            Kanun’un 9. maddesi uyarınca Kişisel Veriler kural olarak, Veri Sahibinin açık rızası olmaksızın yurt dışına aktarılamaz. Ancak aşağıda belirtilen hallerden birinin mevcut olması halinde Kişisel Verilerin, Veri Sahibinin açık rızası aranmaksızın yurt içinde üçüncü kişilere aktarımı mümkündür:
          </p>
          <ul style={{ color: 'white' }}>
            <li>Bu Politika’nın 5.maddesinde belirtilen, Veri Sahibinin rızasının aranmayacağının belirtildiği hallerden birinin mevcut olması.</li>
            <li>Kişisel Verilerin aktarılacağı yabancı ülkede yeterli korumanın bulunması.</li>
            <li>Yeterli korumanın bulunmaması durumunda Türkiye'deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması.</li>
            <li>Yeterli korumanın bulunduğu ülkeler Kurulca belirlenerek ilan edilir.</li>
            <li>Kişisel Veriler, uluslararası sözleşme hükümleri saklı kalmak üzere, Türkiye'nin veya Veri Sahibinin menfaatinin ciddi bir şekilde zarar göreceği durumlarda, ancak ilgili kamu kurum veya kuruluşunun görüşü alınarak Kurulun izniyle yurt dışına aktarılabilir.</li>
          </ul>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Kişisel Verilerin Aktarılabileceği 3. Kişiler</h4>
          <p style={{ color: 'white' }}>
            Şirketimiz bu Politika’nın 8. maddesinde belirtilen amaçlarını gerçekleştirmek için, Kanun’un 8. ve 9. maddelerine uygun olarak, yurt içinde veya yurtdışındaki, gerçek veya tüzel kişi olabilecek, aşağıda belirtilen üçüncü kişilere veri aktarabilmektedir:
          </p>
          <ul style={{ color: 'white' }}>
            <li>•Danışmanlar</li>
            <li>•Denetim Firmaları</li>
            <li>•Hizmet Alınan Firmalar</li>
            <li>•İş birliği Yapılan Firmalar</li>
            <li>•Müşteriler</li>
            <li>•Pay Sahipleri</li>
            <li>•Tedarikçiler</li>
            <li>•Bankalar ve Finans Kuruluşları</li>
            <li>•Yargısal Merciler ve Kamu Otoriterleri</li>
          </ul>
        </Col>
      </Row>
       <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Kişisel Verilerin Toplanması</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <p style={{ color: 'white' }}>
            Şirketimiz Kanun’un ve ilgili sair mevzuatın düzenlemelerine uygun olarak, aşağıda belirtilen yöntemlerle veri toplamaktadır.
          </p>
          <ul style={{ color: 'white' }}>
            <li>
              • Hizmetlerimizi kullandığınızda veya hesap oluşturduğunuzda bize sağladığınız kişisel veriler:
            </li>
            <li>
              • Hesap oluşturduğunuzda; adınız, telefon numaranız ve e-posta adresiniz gibi tanımlayıcı bilgileriniz.
            </li>
            <li>
              • Bir işlemin gerçekleştirilmesi sırasında alım veya satımla ilgili olarak paylaştığınız bilgiler ile işlemle ilgili olarak ürettiğiniz diğer içerikler veya işleme taraf olmanız nedeniyle hesabınızla bağlantılı olarak ortaya çıkan içerikler. Ürettiğiniz veya hesabınız ile bağlantılı, ürünlerin alışveriş sepetinize veya favorilerime eklenmesi, satıcı takip edilmesi gibi diğer içerikler.
            </li>
            <li>
              • İşlemleriniz ile bağlantılı, banka hesap numaraları gibi finansal bilgiler.
            </li>
            <li>
              • Ürünlerin satın alınması veya listelenmesi dâhil ürünün satılması amacıyla kullanılan e-posta ve telefon bilgileri, vergi kimlik numarası ve diğer kimlik numaraları dâhil fatura bilgileri, kargo/posta işlemleri için gerekli olan takip numaraları ve güncel takip bilgileri gibi bilgiler ile ilgili diğer bilgiler.
            </li>
            <li>
              • Bazı hallerde, kullanıcı hesabı oluştururken veya Hizmetlerimizi kullanırken bize sağladığınız yaş, cinsiyet, ilgi alanlarınız ve beğendikleriniz ile ilgili bilgiler (bunlara Facebook hesabınız ile kaydolmuşsanız, Facebook'un bizimle paylaşmasına izin verdiğiniz profil bilgileriniz, arkadaş listeniz, doğum gününüz, beğenileriniz ve e-posta adresiniz de dahildir).
            </li>
            <li>
              • indirimlikargo.com’da yer alan formlar aracılığıyla, Site üyeliği için oluşturduğunuz kullanıcı hesabınıza ilişkin bilgilerinizi güncelleyerek veya bilgi ekleyerek, uyuşmazlık çözümüne katılarak veya ürün ve/veya hizmet satın aldığınızda veya sattığınızda veya Hizmetlerimiz ile ilgili olarak bizimle başka şekilde iletişime geçerek sunmuş olduğunuz diğer bilgiler ve bu kapsamda oluşan kullanıcılar dâhil yapılan her türlü görüşme/yazışma içerikleri ile bunlara ilişkin kayıtlar.
            </li>
            <li>
              • Kimliğinizi doğrulayabilmek veya tespit edebilmek için yürürlükteki kanunlar uyarınca toplamamız ve işlememiz gereken veya buna izin verilen diğer bilgiler.
            </li>
            <li>
              • Bazı hallerde, hukuka aykırı, tehditkâr, hakaret içeren ve sair istenmeyen mesajlar alındığına ilişkin kullanıcılarımızdan veya üçüncü kişilerden gelen şikâyetleri değerlendirebilmek amacıyla incelediğimiz mesajlaşma ve diğer yazışma içerikleri.
            </li>
            <li>
              • Hizmetlerimizi kullandığınızda veya hesap oluşturduğunuzda otomatik olarak topladığımız kişisel veriler:
            </li>
            <li>
              • Hizmetlerimizi kullanımınıza ve reklam tercihlerinize ilişkin konular ile bizimle iletişim kurduğunuz konularda bilgi toplamaktayız. Bu bilgiler, mobil cihazlar da dâhil olmak üzere Hizmetlerimize ulaşmak amacıyla kullandığınız cihazlardan elde ettiğimiz bilgilerdir. Bu bilgiler, cihaz kimliği veya benzersiz tanıtıcı, cihaz türü, reklam tanımlayıcı ve benzersiz cihaz simgesinden oluşmaktadır.
            </li>
            <li>
              • Mobil cihazınızdan elde edilen lokasyon bilgileri dâhil olmak üzere coğrafi konum bilgileri. Mobil cihazların çoğunun, mobil cihazın cihaz ayarlar menüsündeki bulunan bir uygulama ile lokasyon hizmetlerini kontrol etmenize veya kullanımını devre dışı bırakmanıza olanak sağladığını hatırlatmak isteriz.
            </li>
            <li>
              • Sayfa görüntüleme istatistikleri, sayfa gelen ve giden trafik bilgileri, yönlendiren URL, reklam verileri, IP adresiniz, göz atma geçmişiniz ve web log bilgileriniz gibi bilgisayar ve bağlantı bilgileri.
            </li>
            <li>
              • Çerezler, web işaretçilerini kullanarak topladığımız kişisel bilgiler:
            </li>
            <li>
              • Hizmetlerimizi kullanımınız kapsamında ve reklam ve e-posta içeriğimiz aracılığıyla, görüntülediğiniz sayfalar, tıkladığınız bağlantılar ve diğer işlemleriniz hakkında bilgi toplamak için çerez, web işaretçileri (beacons), benzersiz tanımlayıcı ve benzer teknolojiler kullanmaktayız.
            </li>
            <li>
              • Bu teknolojileri kullanımımız ve bunları nasıl kontrol edebileceğiniz hakkında daha ayrıntılı bilgi için Çerezler Politikamızı inceleyiniz.
            </li>
            <li>
              • Diğer kaynaklardan topladığımız kişisel veriler:
            </li>
            <li>
              • Topladığımız verileri, üçüncü kişilerden aldığımız veriler ile birleştirebilir ve hesap bilgilerinize ekleyebiliriz. Örneğin, herkese açık olan demografik ve diğer bilgileri, ek iletişim bilgilerini, kredi bilgilerini ve kredi kayıt bürosu ve benzeri kuruluşlardan alınan bilgileri, yürürlükteki kanunların müsaade ettiği şekilde toplayabilir ve kullanabiliriz.
            </li>
            <li>
              • Sosyal medya siteleri ile bilgi paylaşımınıza veya hesabınızı oluşturmak veya ilgili sosyal medya sitelerine hesabınızı bağlamak için sosyal medya sitelerini kullanmanıza izin verebiliriz. Bu sosyal medya siteleri, bize (görüntülediğiniz içerik, beğendiğiniz içerik ve size gösterilen veya tıklamış olabileceğiniz reklamlar hakkında bilgiler gibi) ellerinde bulundurdukları birtakım kişisel verilerinize otomatik erişim sağlayabilir.
            </li>
            <li>
              • İlgili sosyal medya sitesinde yer alan gizlilik ayarları aracılığıyla erişim sağlayabileceğimiz kişisel verilerinize ilişkin değişiklik yapma ve ilgili sosyal medya sitesinde yer alan kişisel verilerinize erişim sağlamamız için bize verdiğiniz izinlerde değişiklik yapma hakkına sahip olmakla, kişisel verilerinizin kontrolünü elinizde bulundurmaktasınız.
            </li>
            <li>
              • Sosyal medya sitesi tarafından yönetilen bir hesabı, indirimlikargo.com’daki hesabınız ile ilişkilendirerek ve bu bilgilere erişimimiz için izin vererek, bu sosyal medya siteleri tarafından sunulan bilgileri, bu Gizlilik Bildirimi uyarınca toplayabileceğimizi, kullanabileceğimizi ve muhafaza edebileceğimizi kabul edersiniz.
            </li>
            <li>
              • Çeşitli sosyal medya siteleri üzerinden eklentiler (social media plug-in) veya başka teknolojiler kullanabiliriz. Sosyal medya eklentileri aracılığıyla sunulan bir linke tıkladığınızda kendi iradenizle ilgili sosyal medya sitesi ile bağlantı kurulmasını sağlamış olursunuz.
            </li>
            <li>
              • Başkalarına ait kişisel verileri (başka bir kişiye ait olan teslim adresi, işlemler için kullandığınız başka bir kartın hamiline ilişkin bilgiler ve benzeri bilgiler), yalnızca söz konusu kişilerden önceden alınmış açık rızaları varsa bizimle paylaşabilirsiniz. Bu kişileri aynı zamanda, kendilerinin kişisel verilerini Gizlilik Bildirimimiz uyarınca nasıl topladığımız, kullandığımız, paylaştığımız ve muhafaza ettiğimiz konularında da bilgilendirmelisiniz.
            </li>
          </ul>
        </Col>
      </Row>
        <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Veri Sahibinin Hakları</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <ul style={{ color: 'white' }}>
            <li>• Kişisel verilerinin işlenip işlenmediğini öğrenme.</li>
            <li>• Kişisel verileri işlenmişse buna ilişkin bilgi talep etme.</li>
            <li>• Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme.</li>
            <li>• Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme.</li>
            <li>• Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
            <li>• KVK Kanunu ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme.</li>
            <li>• İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkması halinde bu sonuca itiraz etme.</li>
            <li>• Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
          </ul>
          <p style={{ color: 'white' }}>
            Not: Veri sahibi olarak buradan ulaşabileceğiniz Sanatkardan Kişisel Verileri Koruma ve İşleme Politikası'nın 10.C maddesinde belirtildiği şekilde hak başvurusunda bulunabilirsiniz. Başvurularınız şirketimiz tarafından otuz gün içerisinde sonuçlandırılacaktır. Sanatkardan.com’un taleplere ilişkin olarak Kişisel Verileri Koruma Kurulu tarafından belirlenen (varsa) ücret tarifesi üzerinden ücret talep etme hakkı saklıdır.
          </p>
        </Col>
      </Row>
       <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Çerez (Cookie) Politikamız</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Çerez Nedir?</h4>
          <p style={{ color: 'white' }}>
            Çerezler, bilgisayarınız ya da mobil cihazınız üzerinden ziyaret ettiğiniz internet siteleri tarafından cihazınıza veya ağ sunucusuna depolanan küçük metin dosyalarıdır.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Çerezlerde Hangi Tür Veriler İşlenir?</h4>
          <p style={{ color: 'white' }}>
            İnternet sitelerinde yer alan çerezlerde, türüne bağlı olarak, siteyi ziyaret ettiğiniz cihazdaki tarama ve kullanım tercihlerinize ilişkin veriler toplanmaktadır. Bu veriler, eriştiğiniz sayfalar, incelediğiniz hizmet ve ürünler, tercih ettiğiniz dil seçeneği ve diğer tercihlerinize dair bilgileri kapsamaktadır.
          </p>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Çerezler Hangi Amaçlarla Kullanılmaktadır?</h4>
          <p style={{ color: 'white' }}>
            Sitede tercih ettiğiniz dil ve diğer ayarları içeren bu küçük metin dosyaları, siteye bir sonraki ziyaretinizde tercihlerinizin hatırlanmasına ve sitedeki deneyiminizi iyileştirmek için hizmetlerimizde geliştirmeler yapmamıza yardımcı olur. Böylece bir sonraki ziyaretinizde daha iyi ve kişiselleştirilmiş bir kullanım deneyimi yaşayabilirsiniz.
          </p>
          <p style={{ color: 'white' }}>
            İnternet sitemizde çerez kullanımının başlıca amaçları ise şöyledir:
          </p>
          <ul style={{ color: 'white' }}>
            <li>• İnternet sitesinin performansını arttırmak</li>
            <li>• Site üzerinden sizlere sunulan hizmetleri geliştirmek ve kolaylaştırmak</li>
            <li>• Site üzerinden yeni özellikler sunmak ve özellikleri tercihlerinize göre kişiselleştirmek</li>
            <li>• Sizin ve şirketimizin hukuki ve ticari güvenliğini sağlamak</li>
            <li>• Site üzerinden sahte işlemlerin gerçekleştirilmesini önlemek</li>
            <li>• 5651 sayılı Internet Ortamında Yapılan Yayınların Düzenlenmesi ve Bu Yayınlar Yoluyla İşlenen Suçlarla Mücadele Edilmesi Hakkında Kanun ve Internet Ortamında Yapılan Yayınların Düzenlenmesine Dair Usul ve Esaslar Hakkında Yönetmelik'ten kaynaklananlar başta olmak üzere, kanuni ve sözleşmesel yükümlülüklerini yerine getirmek</li>
          </ul>
        </Col>
      </Row>
       <Row>
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Çerez (Cookie) Politikamız</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Çerez Türleri</h4>
          
          <h5 style={{ color: '#EFB02F' }}>Saklandığı Süre Bakımından Çerez Türleri:</h5>
          
          <ul style={{ color: 'white' }}>
            <li><strong style={{ color: '#EFB02F' }}>Oturum Çerezleri:</strong> Oturum çerezleri, ziyaretiniz süresince internet sitesinin düzgün bir şekilde çalışmasını sağlamaktadır. Sitelerimizin ve sizin, ziyaretinizde güvenliğini, sürekliliğini sağlamak gibi amaçlarla kullanılır. Oturum çerezleri geçici çerezlerdir, tarayıcınızı kapatıp sitemize tekrar geldiğinizde silinir, kalıcı değillerdir.</li>
            <li><strong style={{ color: '#EFB02F' }}>Kalıcı Çerezler:</strong> Bu çerezler, bilgilerinizin ve seçimlerinizin bir sonraki ziyaretinizde internet sitemiz tarafından hatırlanmasına yardımcı olur. Kalıcı çerezler, tarayıcınızı kapattıktan veya bilgisayarınızı yeniden başlattıktan sonra bile saklı kalır. Tarayıcınızın ayarlarından silinene kadar tarayıcınızın alt klasörlerinde tutulur.</li>
          </ul>

          <h5 style={{ color: '#EFB02F' }}>Kullanım Bakımından Çerez Türleri:</h5>

          <ul style={{ color: 'white' }}>
            <li><strong style={{ color: '#EFB02F' }}>Birinci ve Üçüncü Kişi Çerezler:</strong> Birinci kişi çerezler, sitemiz tarafından kullanılan çerezlerdir. Üçüncü kişi çerezler ise sitemiz haricinde bilgisayarınıza kurulan çerezlerdir. İnternet sitemizde hem birinci hem de üçüncü taraf çerezler kullanılmaktadır. İnternet sitemizi ziyaret etmeniz dolayısıyla elde edilen verileriniz, iş ortaklarımıza, tedarikçilerimize, kanunen yetkili kamu kurumlarına ve özel kişilere KVK Kanunu’nun 8. ve 9. maddeleri kapsamında paylaşılabilir.</li>
            <li><strong style={{ color: '#EFB02F' }}>Zorunlu Çerezler:</strong> İnternet sitesinin düzgün çalışması için gerekli olan çerezlerdir. Sistemin düzgün bir şekilde yönetilebilmesi, kullanıcı hesaplarının oluşturulması ve giriş yapılabilmesi, sahte işlemlerin önlenmesi gibi amaçlarla zorunlu çerezler kullanılır. Bu çerezlerin olmaması durumunda internet sitesi düzgün çalışmaz.</li>
            <li><strong style={{ color: '#EFB02F' }}>İşlev Çerezleri:</strong> İşlev çerezleri, internet sitesindeki ziyaretinizi kolaylaştırmak ve deneyiminizi geliştirmek için kullanılan çerezlerdir. İnternet sitesine yaptığınız bir önceki ziyareti hatırlayarak içeriklere rahatça erişmenizi sağlar.</li>
            <li><strong style={{ color: '#EFB02F' }}>Analitik Çerezler:</strong> Analitik çerezler, sitelerimizdeki trafiği görerek bu trafiğe uygun hizmet sağlamamızı sağlayan veriler içerir. Hangi sayfalarımızın daha fazla ilgi çektiğini, hangi kaynakların daha çok görüntülendiğini görmemize yardımcı olur. Bu çerezler bilgiyi anonim olarak depolar.</li>
            <li><strong style={{ color: '#EFB02F' }}>Reklam Çerezleri:</strong> Reklam veya diğer adıyla hedefleme çerezleri, ilgi alanınıza yakın içerikleri tespit etmemizi ve sunmamızı sağlar. Sizi tanıyabilmemiz ve size özel reklamlar sunabilmek adına web sitemize ve mobil sitemize, reklam verdiğimiz diğer web sitelerine üçüncü taraf reklam çerezleri yerleştirilebilir. Bu çerezler, reklamların verimliliğini ölçmek için de kullanılır.</li> 
            <li><strong style={{ color: '#EFB02F' }}>Google Analytics::</strong> Google Analytics kullanıcıların internet sitesini nasıl kullandığını analiz eden bir web analiz aracıdır. Anonim olan bilgilerin yanı sıra internet sitesi kullanımınızla ilgili kişisel veri niteliğindeki bilgiler (İsim, adres, telefon numarası, e-mail adresi, IP adresi) tarayıcınız tarafından Google’a gönderilir ve Google tarafından kaydedilir.</li>
          </ul>
        </Col>
      </Row>
       <Row>
        <Col>
          <h2 className="text-center " style={{ color: '#EFB02F' }}>Çerez (Cookie) Politikamız</h2>
        </Col>
      </Row>

      {/* Çerezlerin Kullanılmasını Nasıl Engelleyebilirsiniz? */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Çerezlerin Kullanılmasını Nasıl Engelleyebilirsiniz?</h4>
          <p style={{ color: 'white' }}>
            Çoğu tarayıcı çerezleri otomatik olarak kabul eder. Ancak dilerseniz çerezleri tarayıcınızın ayarlarını değiştirerek reddedebilirsiniz. Çerezleri reddettiğiniz takdirde sitemizdeki bazı özelliklerin ve hizmetlerin düzgün çalışamayabileceğini, sitemizin kişiselleştirilemeyebileceğini ve sizin deneyiminize göre özelleştirilemeyeceğini lütfen unutmayınız. Tarayıcınızın ayarlarını değiştirerek çerezlere ilişkin tercihlerinizi kişiselleştirme imkanına sahipsiniz. Tarayıcı üreticileri, kendi ürünlerinde çerezlerin yönetimi ile ilgili yardım sayfaları sunmaktadır:
          </p>
          <ul style={{ color: 'white' }}>
            <li>Google Chrome</li>
            <li>Mozilla Firefox</li>
            <li>Internet Explorer</li>
            <li>Opera Desktop</li>
            <li>Opera Mobil</li>
            <li>Safari MacOS</li>
            <li>Safari iOS</li>
          </ul>
        </Col>
      </Row>

      {/* Bizimle İletişime Geçin */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Bizimle İletişime Geçin</h4>
          <p style={{ color: 'white' }}>
            Çerez Politikası ile ilgili tüm soru ve görüşlerinizi iletmek için bize ulaşın!
          </p>
          <p style={{ color: 'white', fontWeight: 'bold' }}>destek@sanatkardan.com</p>
        </Col>
      </Row>
      <Footer/>
    </Container>
  );
};

export default CookiePolicy;
