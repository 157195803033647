import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button, Row, Col } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axios from 'axios';

const InvoiceForm = ({ show, handleClose, payeeAddress }) => {
  const [formData, setFormData] = useState({
    firstName: payeeAddress?.firstname || '',
    lastName: payeeAddress?.lastname || '',
    email: payeeAddress?.email || '',
    phone: payeeAddress?.phone || '',
    address: payeeAddress?.fullAddress || '',
    postalCode: payeeAddress?.postalCode || '',
    corporateTitle: '',
    taxNumber: '',
    taxOffice: '',
    isEInvoice: false,
    city: '',
    district: '',
  });

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    // İl listesini çekme
    axios
      .get('https://turkiyeapi.dev/api/v1/provinces')
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error('İl listesi alınamadı:', error);
      });
  }, []);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    const selectedCity = cities.find((city) => city.id === parseInt(cityId));
    setFormData({ ...formData, city: selectedCity.name, district: '' }); // İl seçildiğinde ilçeyi sıfırla

    // İlçeleri çekme
    axios
      .get(`https://turkiyeapi.dev/api/v1/provinces/${cityId}`)
      .then((response) => {
        setDistricts(response.data.data.districts);
      })
      .catch((error) => {
        console.error('İlçe listesi alınamadı:', error);
      });
  };

  const handleDistrictChange = (e) => {
    const districtId = e.target.value;
    const selectedDistrict = districts.find((district) => district.id === parseInt(districtId));
    setFormData({ ...formData, district: selectedDistrict.name });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // E-fatura işlemleri için gerekli verileri gönderme
    console.log('E-Fatura Verisi:', formData);
    handleClose(); // Form gönderildikten sonra offcanvas'ı kapat
  };

  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ backgroundColor: '#f0f0f0' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="text-center w-100" style={{ color: 'black' }}>
          E-FATURA DÜZENLE
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
        <Form onSubmit={handleSubmit} className="text-center">
          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="firstName">
                <Form.Label>* AD</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
            <Col md={5} className="mb-3">
              <Form.Group controlId="lastName">
                <Form.Label>* SOYAD</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label>* TELEFON NO</Form.Label>
                <PhoneInput
                  country={'tr'}
                  value={formData.phone}
                  onChange={(phone) => setFormData({ ...formData, phone })}
                  inputStyle={{
                    width: '165px',
                    padding: '8px',
                    textAlign: 'center',
                    height: '43px',
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col md={5} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label>E-POSTA</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="address">
                <Form.Label>* TAM ADRES</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="city">
                <Form.Label>* İL</Form.Label>
                <Form.Select
                  name="city"
                  value={formData.city}
                  onChange={handleCityChange}
                  required
                  className="p-2"
                >
                  <option>İl Seçin</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col md={5} className="mb-3">
              <Form.Group controlId="district">
                <Form.Label>* İLÇE</Form.Label>
                <Form.Select
                  name="district"
                  value={formData.district}
                  onChange={handleDistrictChange}
                  required
                  className="p-2"
                >
                  <option>İlçe Seçin</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="postalCode">
                <Form.Label>* POSTA KODU</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="corporateTitle">
                <Form.Label>UNVAN</Form.Label>
                <Form.Control
                  type="text"
                  name="corporateTitle"
                  value={formData.corporateTitle}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
            <Col md={5} className="mb-3">
              <Form.Group controlId="taxNumber">
                <Form.Label>VERGİ veya TC. NO</Form.Label>
                <Form.Control
                  type="text"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col md={5} className="mb-3">
              <Form.Group controlId="taxOffice">
                <Form.Label>VERGİ DAİRESİ</Form.Label>
                <Form.Control
                  type="text"
                  name="taxOffice"
                  value={formData.taxOffice}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Form.Group className="mb-3 d-flex justify-content-center align-items-center">
            <Form.Check
              type="checkbox"
              name="isEInvoice"
              checked={formData.isEInvoice}
              onChange={handleInputChange}
              label="E-Fatura Mükellefi"
            />
          </Form.Group>

          <Button
            variant="warning"
            type="submit"
            className="mt-3"
            style={{
              width: '50%',
              margin: '0 auto',
              display: 'block',
              fontSize: '16px',
              padding: '10px 0',
            }}
          >
            KAYDET
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InvoiceForm;
