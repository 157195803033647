import { createSlice } from '@reduxjs/toolkit';

// Initial State
const initialState = {
  userId: null,  // Başlangıçta null
  storeId: null,  // Başlangıçta storeId de null olacak
};

// User Reducer using createSlice
const userSlice = createSlice({
  name: 'user',  // Slice ismi
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload.userId;  // Redux store'a userId kaydedilir
      state.storeId = action.payload.storeId;  // Redux store'a storeId kaydedilir
    },
  },
});

// Export the action
export const { setUser } = userSlice.actions;

// Export the reducer
export default userSlice.reducer;
