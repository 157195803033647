import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from '../Footer';

const TaxExemption = () => {
  return (
    <Container fluid className="px-4 py-3 mt-5">
      <Row>
        <Col>
          <h2 className="text-center" s style={{ color: '#EFB02F', marginTop: '40px' }}>Esnaf Vergi Muafiyeti</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <p style={{ color: 'white' }}>
            Gerçek kişiler ticari faaliyetlerinden ötürü elde ettikleri gelirler üzerinden işin mahiyetine göre “Gerçek Usulde” ya da “Basit Usulde” vergiye tabi tutulurlar. Eğer ticari faaliyetlerinin kapsamı Gelir Vergisi Kanununun 9. maddesinde belirtilen Esnaf Muaflığı şartları ile bağdaşırsa, gerçek kişiler vergiden muaf esnaf olarak elde ettiği kazançlar üzerinden vergilendirilmezler.
          </p>
        </Col>
      </Row>
       <Row className="mt-5">
        <Col>
          <h2 className="text-center" style={{ color: '#EFB02F' }}>Esnaf Muaflığı Tanımına Uygun Kişiler</h2>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col>
          <p style={{ color: 'white' }}>
            Esnaf muaflığından faydalanabilmek için en temel koşul bir işyeri açmaksızın ticari faaliyette bulunulmasıdır. Gelir Vergisi Kanununa göre aşağıdaki faaliyetleri gerçekleştiren kişiler, esnaf muaflığı kapsamındadır:
          </p>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>• Motorlu nakil vasıtaları kullanmamak şartı ile gezici olarak veya bir işyeri açmaksızın perakende ticaret ile iştigal edenler,</li>
            <li>• Bir işyeri açmaksızın gezici olarak ve doğrudan doğruya müstehlike iş yapan hallaç, kalaycı, lehimci, musluk tamircisi, çilingir, ayakkabı tamircisi, kundura boyacısı, berber, nalbant, fotoğrafçı, odun ve kömür kırıcısı, çamaşır yıkayıcısı ve hamallar gibi küçük sanat erbabı,</li>
            <li>• Köylerde gezici olarak her türlü sanat işleri ile uğraşanlar ile aynı yerlerde aynı işleri bir işyeri açmak suretiyle yapanlar,</li>
            <li>• Nehir, göl ve denizlerde ve su geçitlerinde toplamı 50 rüsum tonilatoya kadar makinesiz veya motorsuz nakil vasıtaları işletenler; hayvanla veya bir adet hayvan arabası ile nakliyecilik yapanlar,</li>
            <li>• Ziraat işlerinde kullandıkları hayvan, araba, motor, traktör gibi vasıtalar veya sandallarla nakliyeciliği mutat hale getirmeksizin ara sıra ücret karşılığında eşya ve insan taşıyan çiftçiler,</li>
            <li>• Evlerde kullanılan dikiş, nakış, mutfak robotu, ütü ve benzeri makine ve aletler hariç olmak üzere, muharrik kuvvet kullanmamak ve dışarıdan işçi almamak şartıyla; oturdukları evlerde imal ettikleri havlu, örtü, çarşaf, çorap, halı, kilim, dokuma mamûlleri, kırpıntı deriden üretilen mamûller, örgü, dantel, her nevi nakış işleri ve turistik eşya, hasır, sepet, süpürge, paspas, fırça, yapma çiçek, pul, payet, boncuk işleme, tığ örgü işleri, ip ve urganları, tarhana, erişte, mantı gibi ürünleri işyeri açmaksızın satanlar,</li>
            <li>• Ticari işletmelere ait atıkları mutat olarak veya belli aralıklarla satın alanlar hariç olmak üzere, bir işyeri açmaksızın kendi nam ve hesabına münhasıran kapı kapı dolaşmak suretiyle her türlü hurda maddeyi toplayarak veya satın alarak bu malların ticaretini yapanlara veya tekrar işleyenlere satanlar,</li>
            <li>• Kendi ürettikleri ürünleri satanlara münhasır olmak üzere el dokuma işleri, bakır işlemeciliği, çini ve çömlek yapımı, sedef kakma ve ahşap oyma işleri, kaşıkçılık, bastonculuk, semercilik, yazmacılık, yorgancılık, keçecilik, lüle ve oltu taşı işçiliği, çarıkçılık, yemenicilik, oyacılık ve bunlar gibi geleneksel, kültürel, sanatsal değeri olan ve kaybolmaya yüz tutan meslek kollarında faaliyette bulunanlar,</li>
            <li>• Yukarıdaki maddelerde sözü edilen işlere benzerlik gösterdikleri Maliye Bakanlığınca kabul edilen ticaret ve sanat işleri ile iştigal edenler,</li>
            <li>• Bir işyeri açmaksızın münhasıran gezici olarak, milli piyango bileti satanlar ile 4077 sayılı Tüketicinin Korunması Hakkındaki Kanuna göre gerçek ve tüzel kişilerin mallarını iş akdi ile bağlı olmaksızın bunlar adına kapı kapı dolaşmak suretiyle tüketiciye satanlar yukarıdaki şartlarla sınırlı olmaksızın gelir vergisinden muaftır.</li>
          </ul>
        </Col>
      </Row>
  

      {/* Esnaf Muaflığından Kimler Faydalanamaz? */}
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Esnaf Muaflığından Kimler Faydalanamaz?</h4>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>• Ticari, zirai veya mesleki kazancı dolayısı ile gerçek usulde Gelir Vergisine tâbi olanlar ile yukarıda sayılan işleri Gelir ve Kurumlar Vergisi mükelleflerine bağlılık arz edecek şekilde yapanlar esnaf muaflığından faydalanamazlar.</li>
            <li>• Esnaf muaflığından faydalanan gerçek kişilerin ürettikleri ürünleri kermes ve panayır gibi yerlerde satmaları söz konusu ise, bu kermes ve panayırların Gelir ve Kurumlar Vergisi mükelleflerince düzenlenmemesi gerekir.</li>
            <li>• Dernek, vakıf, sendika, meslek odası tarafından düzenlenen kermes yerlerinde satış yapılabilir. Kamu kurum ve kuruluşlarınca geçici olarak belirlenen yerlerde satış yapılması da esnaf muaflığından yararlanmaya engel değildir. Ancak bu yerlerde sürekli satış yapanlar ile ürünlerini pazar takibi suretiyle satanlar esnaf muaflığından yararlanamayacaklardır.</li>
            <li>• Aynı şekilde ürünlerin internet üzerinden satışı da esnaf muaflığından faydalanmaya engeldir. İnternet satışları için ufak bir istisna söz konusudur. 30 Ocak 2019 yılında yayınlanan düzenlemeye göre internetten elde edilen gelir yıllık 12 brüt asgari ücret tutarını geçmiyorsa kişi esnaf muaflığından faydalanabilir.</li>
          </ul>
        </Col>
      </Row>

       <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Esnaf Muaflığı Belgesi Nasıl Alınır?</h4>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>• Esnaf muaflığına ilişkin şartları yerine getirenler, ikametgâhlarının bulunduğu yerdeki vergi dairelerine dilekçe vererek esnaf vergi muafiyeti belgesi olarak da bilinen belgeyi alabilirler.</li>
            <li>• Bu belgenin alınması isteğe bağlı olup belgenin alımı sırasında bir ücret ödenmemektedir.</li>
            <li>• Alınan bu belgenin geçerlilik süresi 3 yıldır ve süre bitiminde yenilenmesi mümkündür. Üç yıllık süre içinde esnaf muaflığı şartlarını kaybedenlerin, işi bırakanların veya kendi isteği ile muaflıktan çıkmak isteyenlerin vergi dairesine başvurarak muafiyet belgelerini iptal ettirmeleri gerekir.</li>
            <li>• Esnaf muaflığından faydalananlar, alış ve satışları ile ilgili gelir ve kurumlar mükelleflerinden aldıkları belgeleri saklamak zorundadırlar. Sorulduğunda (yoklama memuru ya da zabıta tarafından) bu belgeleri gösteremeyenlerin “Esnaf Muaflığı Belgesi” iptal edilir.</li>
          </ul>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <h4 className="mb-3 px-2" style={{ color: '#EFB02F' }}>Esnaf Muaflığında Belge Düzeni</h4>
          <ul style={{ color: 'white', listStyleType: 'disc', paddingLeft: '20px' }}>
            <li>• Esnaf muaflığından faydalananlar fatura düzenleyemezler.</li>
            <li>• Vergiden muaf esnaftan alınan mal ve hizmetler için, malı ve hizmeti alan tarafından gider pusulası düzenlenir. Bu gider pusulası Vergi Usul Kanununun 234. Maddesine göre fatura yerine geçer.</li>
            <li>• Gider pusulası; işin mahiyeti, emtianın cins ve çeşidi ile miktar ve bedelini, iş ücretini, işi yaptıran ile yapanın veya emtiayı satın alan ile satanın adlarıyla soyadlarını (tüzel kişilerde şirket unvanlarını), adreslerini ve tarih gibi bilgileri içermelidir.</li>
            <li>• Gider pusulası iki nüsha olarak düzenlenir ve bir nüshası işi yapana veya malı satana verilir.</li>
          </ul>
        </Col>
      </Row>
      <p style={{ color: 'white', marginTop: '20px' }}>
            Esnaf Vergi Muafiyet Belgesine başvurmak için{' '}
            <a 
              href="https://www.gib.gov.tr/dilekce/Esn_Vergi_Muaf_Bel_Alm_Dil.html" 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ color: '#EFB02F', textDecoration: 'underline' }}
            >
              buraya tıklayın
            </a>.
          </p>
          <Footer/>
    </Container>
  );
};

export default TaxExemption;
