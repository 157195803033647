import { configureStore } from '@reduxjs/toolkit';
import userReducer from './Components/reducers/userReducer';  // userReducer'ı import et

const store = configureStore({
  reducer: {
    user: userReducer,  // user reducer'ını store'a ekle
  },
});

export default store;
