import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useSelector } from 'react-redux';

const AddCustomerAddress = ({ show, handleClose, editAddress, setUpdateCustomerTrigger }) => {
  const userId = useSelector((state) => state.user.userId);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    fullAddress: '',
    postalCode: '',
    corporateTitle: '',
    taxNumber: '',
    taxOffice: '',
    cityId: '',
    districtId: '',
    cityName: '',
    districtName: '',
  });

  const [cities, setCities] = useState([]);
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    if (editAddress) {
      setFormData({
        firstName: editAddress.firstName || '',
        lastName: editAddress.lastName || '',
        phone: editAddress.phone || '',
        email: editAddress.email || '',
        fullAddress: editAddress.fullAddress || '',
        postalCode: editAddress.postalCode || '',
        corporateTitle: editAddress.corporateTitle || '',
        taxNumber: editAddress.taxNumber || '',
        taxOffice: editAddress.taxOffice || '',
        cityName: editAddress.cityName || '',
        districtName: editAddress.districtName || '',
      });
    } else {
      setFormData({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        fullAddress: '',
        postalCode: '',
        corporateTitle: '',
        taxNumber: '',
        taxOffice: '',
        cityName: '',
        districtName: '',
      });
    }
  }, [editAddress]);

  useEffect(() => {
    // Şehirleri almak için API isteği
    axios
      .get('https://turkiyeapi.dev/api/v1/provinces')
      .then((response) => {
        setCities(response.data.data);
      })
      .catch((error) => {
        console.error('İl listesi alınamadı:', error);
      });
  }, []);

  const handleCityChange = (e) => {
    const cityId = e.target.value;
    const selectedCity = cities.find((city) => city.id === parseInt(cityId));
    setFormData({ ...formData, cityId: selectedCity.id, cityName: selectedCity.name });

    axios
      .get(`https://turkiyeapi.dev/api/v1/provinces/${cityId}`)
      .then((response) => {
        setDistricts(response.data.data.districts);
      })
      .catch((error) => {
        console.error('İlçe listesi alınamadı:', error);
      });
  };

  const handleDistrictChange = (e) => {
    const districtId = e.target.value;
    const selectedDistrict = districts.find((district) => district.id === parseInt(districtId));
    setFormData({ ...formData, districtId: selectedDistrict.id, districtName: selectedDistrict.name });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };
const onSubmit = async (e) => {
  e.preventDefault();

  try {
    let response;

    if (editAddress) {
      response = await axios.put(
        `${process.env.REACT_APP_API_URL}/customerAddress/${editAddress._id}`,
        { ...formData, userId },
        { withCredentials: true }
      );
      console.log('Response:', response); // Kullanım için ekledik
    } else {
      response = await axios.post(
        `${process.env.REACT_APP_API_URL}/customerAddress`,
        { ...formData, userId },
        { withCredentials: true }
      );
      console.log('Response:', response); // Kullanım için ekledik
    }

    alert(editAddress ? 'Adres başarıyla güncellendi!' : 'Adres başarıyla eklendi!');
    setUpdateCustomerTrigger((prev) => !prev);
    handleClose();
  } catch (error) {
    console.error('Adres işlemi sırasında hata oluştu:', error);
    alert('Adres eklenirken hata oluştu. Lütfen tekrar deneyin.');
  }
};


  return (
    <Offcanvas show={show} onHide={handleClose} placement="end" style={{ backgroundColor: '#f0f0f0' }}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title stclassName="text-center w-100" style={{ color: 'white' }}>
          {editAddress ? 'Alıcı Adresini Düzenle' : 'Alıcı Adresi Ekle'}
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body style={{ backgroundColor: '#f0f0f0', color: 'black' }}>
        <Form onSubmit={onSubmit} className="text-center">
          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} md={5} lg={5} className="mb-3">
              <Form.Group controlId="firstName">
                <Form.Label>* Ad</Form.Label>
                <Form.Control
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} md={5} lg={5} className="mb-3">
              <Form.Group controlId="lastName">
                <Form.Label>* Soyad</Form.Label>
                <Form.Control
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={6} md={6} lg={6} className="mb-3">
              <Form.Group controlId="phone">
                <Form.Label>* Telefon No</Form.Label>
                <PhoneInput
                  country={'tr'}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    width: '100%',
                    padding: '8px',
                    textAlign: 'center',
                    height: '43px',
                  }}
                  required
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} md={5} lg={5} className="mb-3">
              <Form.Group controlId="email">
                <Form.Label>* E-posta</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={5} md={5} lg={5} className="mb-3">
              <Form.Group controlId="city">
                <Form.Label>* İl</Form.Label>
                <Form.Select
                  name="city"
                  value={formData.cityId}
                  onChange={handleCityChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                >
                  <option>İl Seçin</option>
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={10} sm={5} md={5} lg={5} className="mb-3">
              <Form.Group controlId="district">
                <Form.Label>* İlçe</Form.Label>
                <Form.Select
                  name="district"
                  value={formData.districtId}
                  onChange={handleDistrictChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center' }}
                >
                  <option>İlçe Seçin</option>
                  {districts.map((district) => (
                    <option key={district.id} value={district.id}>
                      {district.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={10} md={10} lg={10} className="mb-3">
              <Form.Group controlId="fullAddress" className="mt-3">
                <Form.Label>* Tam Adres</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="fullAddress"
                  value={formData.fullAddress}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={10} md={10} lg={10} className="mb-3">
              <Form.Group controlId="postalCode" className="mt-3">
                <Form.Label>* Posta Kodu</Form.Label>
                <Form.Control
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <h5 className="mt-4">Kurumsal Bilgiler</h5>
          <Row className="justify-content-center mt-3">
            <Col xs={10} sm={10} md={10} lg={10} className="mb-3">
              <Form.Group controlId="corporateTitle">
                <Form.Label>Ünvan</Form.Label>
                <Form.Control
                  type="text"
                  name="corporateTitle"
                  value={formData.corporateTitle}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
            <Col xs={10} sm={10} md={10} lg={10} className="mb-3">
              <Form.Group controlId="taxNumber">
                <Form.Label>* Vergi veya TC. No</Form.Label>
                <Form.Control
                  type="text"
                  name="taxNumber"
                  value={formData.taxNumber}
                  onChange={handleInputChange}
                  required
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="justify-content-center mb-3">
            <Col xs={10} sm={10} md={10} lg={10} className="mb-3">
              <Form.Group controlId="taxOffice" className="mt-3">
                <Form.Label>Vergi Dairesi</Form.Label>
                <Form.Control
                  type="text"
                  name="taxOffice"
                  value={formData.taxOffice}
                  onChange={handleInputChange}
                  className="p-2"
                  style={{ textAlign: 'center', width: '100%' }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button
            variant="warning"
            type="submit"
            className="mt-3"
            style={{
              width: '50%',
              margin: '0 auto',
              display: 'block',
              fontSize: '16px',
              padding: '10px 0',
            }}
          >
            {editAddress ? 'GÜNCELLE' : 'KAYDET'}
          </Button>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default AddCustomerAddress;
